import React, {
  useState, useEffect, useMemo, useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { io } from 'socket.io-client';
import {
  Modal, InputGroup, FormControl, Button, 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {
  getUserLeverageOrders, updateLeverageOrder, stopLeverageOrder, updateSocketOrder, clearLeverageOrder,
} from '../../../redux/leverageOrder/leverageOrderActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../../redux/crmUser/crmUserActions';
import { FutureOrdersSchema, spotOrdersSchema } from '../../../DataTableSchemas/ActiveOrderSchema/ActiveOrderSchema';
import { getUserSpotOrders, stopSpotOrder, updateSpotSocketOrder } from '../../../redux/spotOrder/spotOrderActions';
import { userSpotOrdersColumns, userFutureOrdersColumns } from '../../../columnsDefaultConfig';
import { DatatableColumns, DatatableFilters, FutureOrderEditModal } from '../../../components';
import { addShiftToPrice, toFixed } from '../../../helpers/helper';
import { TradeType } from '../../../types/order';
import { calculateUnrealizedPnL, calculatePositionMargin } from '../../../helpers/leverageOrder';

let localOrderBook = [];
let futures = [];
let futuresCounter = 0;

function ActiveOrder() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState('');
  const [futureStartPrice, setFutureStartPrice] = useState(0);
  const [showFutureStartPrice, setShowFutureStartPrice] = useState(false);
  const [coinAmount, setCoinAmount] = useState(0);
  const [orderRate, setOrderRate] = useState(0);
  const [stopRate, setStopRate] = useState(0);
  const [, setOrderId] = useState('');
  const [futureOrderId, setFutureOrderId] = useState('');
  const [avbl] = useState(0);
  const [TP, setTP] = useState(0);
  const [SL, setSL] = useState(0);
  const [trailingPrice, setTrailingPrice] = useState(0);
  const [rate, setRate] = useState(0);
  const [, setSpotOrderId] = useState('');

  /**
    * socket states
    */
  const [, setOrderBookAPI] = useState([]);
  const [, setFutureTradesAPI] = useState([]);
  const [pairName] = useState('ETHUSDT');
  const [socket, setSocket] = useState(null);
  const [currentMarketPrice, setcurrentMarketPrice] = useState(0);
  const [startSocket, setStartSocket] = useState(true);
  const [rates, setRates] = useState('');
  const [showTPSL, setShowTPSL] = useState(false);
  const [showTrailing, setShowTrailing] = useState(false);
  const [showMarket, setShowMarket] = useState(false);
  const [showFuture, setShowFuture] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [percentage, setPercentage] = useState(`${0}%`);
  const [, setLoader] = useState(false);

  const spotColumnsStorageName = 'DataTable_user/spot-orders_columns';
  const spotColumnsJSON = localStorage.getItem(spotColumnsStorageName);
  const [spotColumnConfig, setSpotColumnConfig] = useState(userSpotOrdersColumns);

  const futureColumnsStorageName = 'DataTable_user/future-orders_columns';
  const futureColumnsJSON = localStorage.getItem(futureColumnsStorageName);
  const [futureColumnConfig, setFutureColumnConfig] = useState(userFutureOrdersColumns);

  const futureFiltersStorageName = 'DataTable_user/future-orders_filters';
  const futureFilterIdStorageName = 'DataTable_user/future-orders_filter_id'; 
  const futureFiltersJSON = localStorage.getItem(futureFiltersStorageName);
  const [futureFilteredResult, setFutureFilteredResult] = useState([]);
  const [futureCoinPairsFilter, setFutureCoinPairsFilter] = useState([]);
  const [futureAmountRangeFilter, setFutureAmountRangeFilter] = useState([]);
  const [futureFilters, setFutureFilters] = useState();

  const futureDatatableFiltersRef = useRef(null);

  const spotFiltersStorageName = 'DataTable_user/spot-orders_filters';
  const spotFilterIdStorageName = 'DataTable_user/spot-orders_filter_id'; 
  const spotFiltersJSON = localStorage.getItem(spotFiltersStorageName);
  const [spotFilteredResult, setSpotFilteredResult] = useState([]);
  const [spotCoinPairsFilter, setSpotCoinPairsFilter] = useState([]);
  const [spotAmountRangeFilter, setSpotAmountRangeFilter] = useState([]);
  const [spotDirectionFilter, setSpotDirectionFilter] = useState([]);
  const [spotFilters, setSpotFilters] = useState();
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [editModalData, setEditModalData] = useState();

  const spotDatatableFiltersRef = useRef(null);

  const handleShowFutureStartPrice = () => setShowFutureStartPrice(true);
  const handleShowTPSL = () => setShowTPSL(true);
  const handleCloseTPSL = () => setShowTPSL(false);
  const handleShowTrailing = () => setShowTrailing(true);
  const handleCloseTrailing = () => setShowTrailing(false);
  const handleShowMarket = () => setShowMarket(true);
  const handleShowFuture = () => setShowFuture(true);
  const handleShowLimit = () => setShowLimit(true);
  const handleCloseLimit = () => setShowLimit(false);
  const handleCloseFutureStartPrice = () => setShowFutureStartPrice(false);
  const handleCloseMarket = () => setShowMarket(false);
  const handleCloseFuture = () => setShowFuture(false);

  const userOrders = useSelector((state) => state.LeverageOrders?.userOrders);
  const started = useSelector((state) => state.LeverageOrders.started);
  const userSpotOrders = useSelector((state) => state.spotOrder.userSpotOrders);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const socketURL = process.env.REACT_APP_SOCKET_URL;

  const getOrders = async () => {
    await dispatch(getUserLeverageOrders(id));
    await dispatch(getUserSpotOrders(id));
  };

  useEffect(() => {
    if (started) {
      dispatch(getUserLeverageOrders(id));
      dispatch(clearLeverageOrder());
    }
  }, [started]);

  useEffect(async () => {
    getOrders();
  }, [id]);

  const handleUpdateLeverageOrderRes = (data) => {
    dispatch(updateSocketOrder(data));
  };

  const openEditModal = (row) => {
    setEditModalData(row);
    setIsEditModalShown(true);
  };

  const closeEditModal = () => {
    setEditModalData();
    setIsEditModalShown(false);
  };

  const handleUpdateSpotOrderRes = (data) => {
    dispatch(updateSpotSocketOrder(data));
  };

  useEffect(() => {
    if (socket && id) {
      socket.on(`onUpdateSpotOrderRes${id}`, handleUpdateSpotOrderRes);
      socket.on(`onUpdateLeverageOrderRes${id}`, handleUpdateLeverageOrderRes);
    }
    return () => {
      socket?.off(`onUpdateSpotOrderRes${id}`);
      socket?.off(`onUpdateLeverageOrderRes${id}`);
    };
  }, [socket, id]);

  useEffect(() => {
    let newSocket = null;
    
    if (pairName) {
      newSocket = io(socketURL, { transports: ['websocket'] });
      setSocket(newSocket);
      setStartSocket(true);
      setFutureTradesAPI([]);
      setOrderBookAPI([]);
      setcurrentMarketPrice(0);
      localOrderBook = [];
      futures = [];
      futuresCounter = 0;
    }

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      } 
    };
  }, [pairName]);

  useEffect(() => {
    if (pairName && startSocket && socket) {
      let letCurrentMarketPrice = 0;
      const getBinanceFutureTradesResponse = (response) => {
        futuresCounter += 1;
        if (futuresCounter % 20 === 0) {
          futures.unshift(response);
          if (futures.length > 20) futures.splice(20);
          setFutureTradesAPI([...futures]);
          futuresCounter = 0;
        }
      };

      const getCurrentMarketPriceResponse = (resCurrentMarketPrice) => {
        letCurrentMarketPrice = resCurrentMarketPrice;
        setcurrentMarketPrice(resCurrentMarketPrice);
      };

      const getBinanceMarketDepthResponse = (response) => {
        const filteredA = response.a?.filter((row) => parseFloat(row['1']) > 0).slice(0, 15) || [];
        const filteredB = response.b?.filter((row) => parseFloat(row['1']) > 0).slice(0, 15) || [];

        const data = {
          a: localOrderBook.a ? [...filteredA, ...localOrderBook.a] : filteredA,
          b: localOrderBook.b ? [...filteredB, ...localOrderBook.b] : filteredB,
        };

        localOrderBook = data;
        setOrderBookAPI(data);
        setRates(data.b && data.b.length ? letCurrentMarketPrice : 0);
      };

      socket.emit('getBinanceFutureTradesRequest', { pairName });
      socket.on(`getBinanceFutureTradesRequestResponse${pairName}`, getBinanceFutureTradesResponse);

      socket.emit('getCurrentMarketPriceRequest', { pairName });
      socket.on(`getCurrentMarketPriceResponse${pairName}`, getCurrentMarketPriceResponse);

      socket.emit('getBinanceMarketDepthRequest', { pairName });
      socket.on(`getBinanceMarketDepthRequest${pairName}`, getBinanceMarketDepthResponse);
    }
    return () => {
      socket?.off(`getBinanceFutureTradesRequestResponse${pairName}`);
      socket?.off(`getCurrentMarketPriceResponse${pairName}`);
      socket?.off(`getBinanceMarketDepthRequest${pairName}`);
    };
  }, [socket, pairName, startSocket]);

  const getLiquidationPrice = (data) => {
    let side = 0;
    if (data.tradeType === 0) { side = -1; } else { side = 1; }
    let wb = 0;
    let tmm = 0;
    let UNPL = 0;
    if (data.marginType === 0) { // cross
      wb = (parseFloat(avbl) + parseFloat(data.userInvestedAmount));
      // const otherOrders = await LeverageOrder.find({ userId: data.userId, status: "1" });
      userOrders.filter((row) => (row.futuresOrder === 1 && row._id !== data._id)).filter((row) => row.status === 1).forEach((ordr) => {
        // tmm
        if (ordr.maintenanceMargin) tmm += ((parseFloat(ordr.qty) * parseFloat(currentMarketPrice ? currentMarketPrice?.find((line) => line.symbol === ordr.pairName).markPrice : 0)) * (parseFloat(ordr.maintenanceMargin) / 100) - parseFloat(ordr.maintenanceAmount));
        // UNPL
        UNPL += parseFloat(document.getElementById(`${ordr?._id}pnl`)?.innerText);
      });
    } else { // isolated
      wb = parseFloat(data.userInvestedAmount);
    }
    const liqPrice = (
      wb - tmm + UNPL + data.maintenanceAmount
                - side
                * data.qty
                * data.tradeStartPrice
    )
            / (
              data.qty
                * (data.maintenanceMargin / 100)
                - side
                * data.qty
            );
    return liqPrice || 0;
  };

  const getPositionMargin = (row) => calculatePositionMargin(row);

  const getUnrealizedPnL = (row) => {
    const pnLResult = {
      value: 0,
      percentage: 0,
      roe: 0,
    };

    if (!row) return pnLResult;

    const marketPrice = currentMarketPrice ? currentMarketPrice.find((line) => line.symbol === row.pairName)?.markPrice : 0;
    if (!marketPrice) return pnLResult;

    const rowPnLRate = row.setting ? addShiftToPrice(marketPrice, row.setting) : parseFloat(marketPrice || 0);

    return calculateUnrealizedPnL(row, rowPnLRate);
  };

  const updateFutureTradeStartprice = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeStartPrice) !== parseFloat(futureStartPrice)) {
      data.tradeStartPrice = parseFloat(futureStartPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }

    handleCloseFutureStartPrice();
  };

  const updateTPSL = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.takeProfitPrice) !== parseFloat(TP) || parseFloat(data.stopLossPrice) !== parseFloat(SL)) {
      data.tpsl = true;
      data.takeProfitPrice = parseFloat(TP);
      data.stopLossPrice = parseFloat(SL);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }
    handleCloseTPSL();
  };

  const updateTrailing = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeTrailingPrice) !== parseFloat(trailingPrice)) {
      data.tradeTrailingPrice = parseFloat(trailingPrice);
      data.tradeTrailingDifference = parseFloat(trailingPrice) > parseFloat(data.tradeStartPrice) ? parseFloat(trailingPrice) - parseFloat(data.tradeStartPrice) : parseFloat(data.tradeStartPrice) - parseFloat(trailingPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }

    handleCloseTrailing();
  };

  const handleLimit = (val, update = 0) => {
    if (avbl) {
      const validNumber = (/^\d*\.?\d*$/);
      if (
        !val.toString().match(validNumber)
                || parseFloat(val) > (parseFloat(avbl) + update)
      ) {
        Swal.fire({
          text: 'Invalid number entered. Please enter a valid number',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
        setCoinAmount(0);
      }
    }
  };

  const updateMarket = () => {
    const data = { ...selectedRow };
    data.tpsl = true;
    data.tradeStartPrice = parseFloat(orderRate);
    data.userInvestedAmount = data.tradeType === 1 ? parseFloat(coinAmount) * parseFloat(orderRate) : parseFloat(coinAmount);
    data.qty = parseFloat(coinAmount) * parseFloat(data.leverage);
    data.fromCurrency = data.fromCurrency._id;
    data.toCurrency = data.toCurrency._id;
    dispatch(updateLeverageOrder(data, id));
    handleCloseMarket();
    handleCloseLimit();
    // setLoader(true);
  };

  function rangeValue(value) {
    const val = value;
    setPercentage(`${val}%`);
    if (avbl) {
      setCoinAmount(avbl * (val / 100));
    }
  }

  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
  };

  function wait(ms) {
    const start = new Date().getTime();
    let end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  const handleFutureStop = () => {
    dispatch(stopLeverageOrder(futureOrderId, parseFloat(stopRate), false));
    setShowFuture(false);
    setFutureOrderId('');
    setStopRate(0);
  };

  const handleCancelOrder = async (orderId) => {
    // e.preventDefault();
    Swal.fire({
      title: 'Are you sure you want to Cancel the Order?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(stopSpotOrder(orderId, id));
        setSpotOrderId('');
      }
    });
  };

  useEffect(() => {
    let PnL = 0;
    userOrders?.filter((row) => (row.futuresOrder === 1 && row.marginType === 0 && row.status === 1)).forEach((ordr) => {
      // PnL
      PnL += parseFloat(document.getElementById(`${ordr?._id}pnl`)?.innerText);
    });

    if (PnL) {
    //   const vall = avbl + PnL;
      // updateAvbl(vall > 0 ? vall : 0)
    }
  }, [currentMarketPrice]);

  const setStoredColumnsData = (columnsJSON, setColumnConfig, columnsStorageName, columnConfig) => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const storeSpotColumnConfig = (config) => {
    setSpotColumnConfig(config);
    localStorage.setItem(spotColumnsStorageName, JSON.stringify(config));
  };

  const storeFutureColumnConfig = (config) => {
    setFutureColumnConfig(config);
    localStorage.setItem(futureColumnsStorageName, JSON.stringify(config));
  };

  useEffect(() => {
    setStoredColumnsData(spotColumnsJSON, setSpotColumnConfig, spotColumnsStorageName, spotColumnConfig);
    setStoredColumnsData(futureColumnsJSON, setFutureColumnConfig, futureColumnsStorageName, futureColumnConfig);
  }, []);

  const spotOrders = useMemo(() => (userSpotOrders ? userSpotOrders.filter((row) => row.status === 1) : []), [userSpotOrders]);

  const spotCoinPairs = useMemo(() => {
    const pairs = [];
    const uniquePairs = [];

    if (!spotOrders || !spotOrders.length) return pairs;

    spotOrders.forEach(({ spotPair }) => {
      if (!uniquePairs.includes(spotPair)) {
        uniquePairs.push(spotPair);
        pairs.push({
          name: spotPair,
          _id: spotPair,
        });
      } 
    });

    return pairs;
  }, [spotOrders]);

  const futureOrders = useMemo(() => (userOrders ? userOrders.filter((row) => row.futuresOrder === 1).filter((row) => row.status === 1) : []), [userOrders]);

  const futureCoinPairs = useMemo(() => {
    const pairs = [];
    const uniquePairs = [];

    if (!futureOrders || !futureOrders.length) return pairs;

    futureOrders.forEach(({ pairName }) => {
      if (!uniquePairs.includes(pairName)) {
        uniquePairs.push(pairName);
        pairs.push({
          name: pairName,
          _id: pairName,
        });
      } 
    });

    return pairs;
  }, [futureOrders]);

  function setMaxFutureAmountRangeValue() {
    const values = futureOrders.map((item) => parseFloat(parseFloat(item?.qty) * parseFloat(item?.tradeStartPrice)).toFixed(2) || 0);

    return Math.max(...values);
  }

  function setMaxSpotAmountRangeValue() {
    const values = spotOrders.map((item) => parseFloat(parseFloat(item?.investedQty) * parseFloat(item?.tradeStartPrice)).toFixed(3) || 0);

    return Math.max(...values);
  }

  const spotSearch = () => {
    const filteredResults = spotOrders.filter(
      (item) => (!spotCoinPairsFilter.length || spotCoinPairsFilter.includes(String(item.spotPair)))
        && (Number(parseFloat(parseFloat(item.investedQty) * parseFloat(item.tradeStartPrice)).toFixed(3)) >= spotAmountRangeFilter[0] && Number(parseFloat(parseFloat(item.investedQty) * parseFloat(item.tradeStartPrice)).toFixed(3)) <= spotAmountRangeFilter[1])
        && (!spotDirectionFilter.length || spotDirectionFilter.includes(String(item.tradeType))),
    );

    setSpotFilteredResult(filteredResults);
  };

  const futureSearch = () => {
    const filteredResults = futureOrders.filter(
      (item) => (!futureCoinPairsFilter.length || futureCoinPairsFilter.includes(String(item.pairName)))
        && (Number(parseFloat(parseFloat(item.qty) * parseFloat(item.tradeStartPrice)).toFixed(2)) >= futureAmountRangeFilter[0] && Number(parseFloat(parseFloat(item.qty) * parseFloat(item.tradeStartPrice)).toFixed(2)) <= futureAmountRangeFilter[1]),
    );

    setFutureFilteredResult(filteredResults);
  };

  useEffect(() => {
    if (spotOrders.length) spotSearch();
    if (spotFilters) localStorage.setItem(spotFiltersStorageName, JSON.stringify(spotFilters));
  }, [spotCoinPairsFilter, spotAmountRangeFilter, spotDirectionFilter]);

  useEffect(() => {
    if (futureOrders.length) futureSearch();
    if (futureFilters) localStorage.setItem(futureFiltersStorageName, JSON.stringify(futureFilters));
  }, [futureCoinPairsFilter, futureAmountRangeFilter]);

  const handleFutureClear = () => {
    setFutureCoinPairsFilter([]);
    setFutureAmountRangeFilter([0, setMaxFutureAmountRangeValue()]);
    setFutureFilters({});
    localStorage.removeItem(futureFiltersStorageName);
    localStorage.removeItem(futureFilterIdStorageName);
    futureDatatableFiltersRef.current.clearDrodownName();
  };

  const handleSpotClear = () => {
    setSpotCoinPairsFilter([]);
    setSpotAmountRangeFilter([0, setMaxSpotAmountRangeValue()]);
    setSpotFilters({});
    localStorage.removeItem(spotFiltersStorageName);
    localStorage.removeItem(spotFilterIdStorageName);
    spotDatatableFiltersRef.current.clearDrodownName();
  };

  const setStoredFutureFilterData = () => {
    if (futureFiltersJSON) {
      const filters = JSON.parse(futureFiltersJSON);
      setFutureFilters(filters);

      setFutureCoinPairsFilter(filters.contracts || []);
      setFutureAmountRangeFilter(filters.amountRange || [0, setMaxFutureAmountRangeValue()]);
    }
  };

  const setStoredSpotFilterData = () => {
    if (spotFiltersJSON) {
      const filters = JSON.parse(spotFiltersJSON);
      setSpotFilters(filters);

      setSpotCoinPairsFilter(filters['spot pairs'] || []);
      setSpotDirectionFilter(filters.direction || []);
      setSpotAmountRangeFilter(filters.amountRange || [0, setMaxSpotAmountRangeValue()]);
    }
  };

  useEffect(() => {
    setSpotAmountRangeFilter([0, setMaxSpotAmountRangeValue()]);
    setSpotFilteredResult(spotOrders);
    setStoredSpotFilterData();
  }, [spotOrders]);

  useEffect(() => {
    setFutureAmountRangeFilter([0, setMaxFutureAmountRangeValue()]);
    setFutureFilteredResult(futureOrders);
    setStoredFutureFilterData();
  }, [futureOrders]);

  const setFutureCRMFilters = (filter) => {
    const { currency, amountRange } = filter;

    setFutureCoinPairsFilter(currency);
    setFutureAmountRangeFilter(amountRange);
    setFutureFilters({
      contrats: currency,
      amountRange,
    });
    localStorage.setItem(futureFilterIdStorageName, JSON.stringify(filter._id));
  };

  const setSpotCRMFilters = (filter) => {
    const { currency, amountRange, status } = filter;

    setSpotCoinPairsFilter(currency);
    setSpotAmountRangeFilter(amountRange);
    setSpotDirectionFilter(status);
    setSpotFilters({
      'spot pair': currency,
      direction: status,
      amountRange,
    });
    localStorage.setItem(spotFilterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserFutureCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(futureFiltersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}/future-orders`,
      currency: filters.contracts || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, setMaxFutureAmountRangeValue()],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(futureFilterIdStorageName, JSON.stringify(res.data.filter._id));
      futureDatatableFiltersRef.current.handleAfterCreate();
    }
  };

  const createUserSpotCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(spotFiltersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}/spot-orders`,
      currency: filters['spot pairs'] || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, setMaxFutureAmountRangeValue()],
      status: filters.direction,
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(spotFilterIdStorageName, JSON.stringify(res.data.filter._id));
      spotDatatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserFutureCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(futureFilterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleFutureClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const deleteUserSpotCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(spotFilterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleSpotClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserFutureCRMFilter = () => {
    const storageFilterId = localStorage.getItem(futureFilterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(futureFiltersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters.contracts || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, setMaxFutureAmountRangeValue()],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserSpotCRMFilter = () => {
    const storageFilterId = localStorage.getItem(spotFilterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(spotFiltersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters['spot pairs'] || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, setMaxSpotAmountRangeValue()],
        status: filters.direction || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const futureColumns = FutureOrdersSchema(
    setSelectedRow,
    setFutureStartPrice,
    handleShowFutureStartPrice,
    getLiquidationPrice,
    getPositionMargin,
    getUnrealizedPnL,
    handleShowTPSL,
    setTP,
    setSL,
    handleShowTrailing,
    setTrailingPrice,
    setCoinAmount,
    setStopRate,
    rates,
    setOrderRate,
    handleShowLimit,
    handleShowMarket,
    setOrderId,
    setFutureOrderId,
    handleShowFuture,
    futureColumnConfig,
    futureCoinPairs,
    futureCoinPairsFilter,
    setFutureCoinPairsFilter,
    futureAmountRangeFilter,
    setFutureAmountRangeFilter,
    futureFilters,
    setFutureFilters,
    setMaxFutureAmountRangeValue,
    openEditModal,
  );

  const spotColumns = spotOrdersSchema(
    setSpotOrderId, 
    handleCancelOrder, 
    spotColumnConfig,
    spotCoinPairs,
    spotCoinPairsFilter,
    setSpotCoinPairsFilter,
    spotDirectionFilter,
    setSpotDirectionFilter,
    spotAmountRangeFilter,
    setSpotAmountRangeFilter,
    setMaxSpotAmountRangeValue,
    spotFilters,
    setSpotFilters,
  );

  return (
    <>
      <div>
        <div>
          <div className="action__btn-row">
            {
              crmFilters ? (
                <DatatableFilters 
                  ref={spotDatatableFiltersRef}
                  filters={crmFilters} 
                  setFilters={setSpotCRMFilters}
                  createFilter={createUserSpotCRMFilter}
                  deleteFilter={deleteUserSpotCRMFilter}
                  updateFilter={updateUserSpotCRMFilter}
                  storageKey={spotFilterIdStorageName}
                  pathname={`${pathname}/spot-orders`}
                /> 
              ) : null
            }
            <DatatableColumns setColumns={storeSpotColumnConfig} columnConfig={spotColumnConfig} />
            <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleSpotClear}>
              <FontAwesomeIcon icon={faTimes} size="sm" />
              Clear
            </button>
            <button type="button" className="btn btn-default icon-btn ms-1" onClick={getOrders}>
              <FontAwesomeIcon icon={faRefresh} size="sm" />
              Refresh
            </button>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
            {spotOrders && spotOrders.length
              ? (
                <DataTable
                  title="Spot Orders"
                  columns={spotColumns}
                  data={spotFilteredResult}
                  pagination
                  fixedHeader
                  persistTableHead
                  theme="solarizedd"
                />
              )
              : (
                <div className="no-tbl-data">No Spot Orders Found!</div>
              )}
          </div>
        </div>
        <br />
        <div>
          <div className="action__btn-row">
            {
              crmFilters ? (
                <DatatableFilters 
                  ref={futureDatatableFiltersRef}
                  filters={crmFilters} 
                  setFilters={setFutureCRMFilters}
                  createFilter={createUserFutureCRMFilter}
                  deleteFilter={deleteUserFutureCRMFilter}
                  updateFilter={updateUserFutureCRMFilter}
                  storageKey={futureFilterIdStorageName}
                  pathname={`${pathname}/future-orders`}
                /> 
              ) : null
            }
            <DatatableColumns setColumns={storeFutureColumnConfig} columnConfig={futureColumnConfig} />
            <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleFutureClear}>
              <FontAwesomeIcon icon={faTimes} size="sm" />
              Clear
            </button>
            <button type="button" className="btn btn-default icon-btn ms-1" onClick={getOrders}>
              <FontAwesomeIcon icon={faRefresh} size="sm" />
              Refresh
            </button>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
            {futureOrders && futureOrders.length ? (
              <DataTable
                title="Future Orders"
                columns={futureColumns}
                data={futureFilteredResult}
                pagination
                fixedHeader
                persistTableHead
                theme="solarizedd"
              />
            ) : (
              <div className="no-tbl-data">No Future Orders Found!</div>
            ) }
          </div>
        </div>
      </div>

      {/* entry price model */}
      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showFutureStartPrice} centered onHide={handleCloseFutureStartPrice}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4">
              <span>
                Start Trading Price
                {selectedRow?.fromCurrency?.symbol}
              </span>
            </div>
            <div className="stop-loss-usdt">
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder="Start Trading Price"
                  min="0"
                  value={futureStartPrice}
                  onChange={(e) => setFutureStartPrice(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateFutureTradeStartprice()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseFutureStartPrice}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* entry price model close */}

      {/* TP/SL model */}
      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTPSL} centered onHide={handleCloseTPSL}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>TP/SL</span></div>

            <div className="d-flex justify-content-between mb-2">
              <span>Entry Price</span>
              <span>{selectedRow.tradeStartPrice}</span>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <span>Liq. Price</span>
              <span className="text-green">{selectedRow.tradeEndPrice}</span>
            </div>

            <div className="take-profit-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span>Take Profit USDT</span>
                <span>Last Traded Price</span>
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  min="0"
                  value={TP}
                  onChange={(e) => setTP(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Take Profit</span>
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>

            <div className="stop-loss-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span>
                  Stop Loss
                  {selectedRow?.fromCurrency?.symbol}
                </span>
                <span>Last Traded Price</span>
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  min="0"
                  value={SL}
                  onChange={(e) => setSL(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Stop Loss</span>
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateTPSL()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseTPSL}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* TP/SL model close */}

      {/* Trailing Stop model  */}
      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTrailing} centered onHide={handleCloseTrailing}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>Trailing Stop</span></div>
            <div className="d-flex justify-content-between mb-2">
              <span>Entry Price</span>
              <span>{selectedRow.tradeStartPrice}</span>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <span>Liq. Price</span>
              <span className="text-green">{selectedRow.tradeEndPrice}</span>
            </div>

            <div className="stop-loss-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span>
                  Trailing Stop
                  {selectedRow?.fromCurrency?.symbol}
                </span>
                {/* <span className="text-white">Last Traded Price</span> */}
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder="Trailing Stop"
                  min="0"
                  value={trailingPrice}
                  onChange={(e) => setTrailingPrice(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateTrailing()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseTrailing}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Trailing Stop model close */}

      {/* Limit Close model */}
      <Modal className="withdrawal-modal limit-modal" show={showLimit} centered onHide={handleCloseLimit} backdrop="static">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>Limit Close</span></div>
            <span>
              Entry Price
              {selectedRow?.toCurrency?.symbol}
            </span>
            <InputGroup className="mb-4">
              <FormControl
                placeholder="Rate"
                aria-label=""
                aria-describedby=""
                value={orderRate}
                onChange={(e) => { setOrderRate(e.target.value); }}
              />
              <InputGroup.Text
                className="point"
                onClick={() => {
                  setRate(!rate);
                }}
              >
                +/-
              </InputGroup.Text>
            </InputGroup>
            <span>
              Order by Qty
              {selectedRow?.toCurrency?.symbol}
            </span>
            <InputGroup className="mb-3">
              <FormControl
                type="number"
                step="0.1"
                placeholder="Price"
                min="0.0"
                max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
                value={coinAmount}
                onChange={(e) => {
                  const val = e.target.value;
                  setCoinAmount(val);
                }}
                onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
              />
              <InputGroup.Text id="basic-addon2">
                {selectedRow?.toCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>

            <Slider
              min={0}
              step={0.1}
              marks={marks}
              defaultValue={[0, 25, 50, 75, 100]}
              value={percentage.replace('%', '')}
              onChange={() => rangeValue}
              className="mb-4 range-slider"
            /> 
            <p style={{ fontSize: '12px', padding: '0 12px' }} className="">4.70 contract(s) will be closed at 1,216.65 price, and your expected points will be 9.7290USDT (includng of EST, closing tees 3.4309USDT)</p>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateMarket()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseLimit}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Limit Close model close */}

      {/* Market model */}
      <Modal className="withdrawal-modal limit-modal market-modal" show={showMarket} centered onHide={handleCloseMarket}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>Market Close</span></div>

            <span>
              Order by Qty
              {selectedRow?.toCurrency?.symbol}
            </span>
            <InputGroup className="mb-3">

              <FormControl
                type="number"
                step="0.1"
                placeholder="Price"
                min="0.0"
                max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
                value={coinAmount}
                onChange={(e) => {
                  const val = e.target.value;
                  setCoinAmount(val);
                }}
                onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
              />
              <InputGroup.Text id="basic-addon2">
                {selectedRow?.toCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>

            <p style={{ fontSize: '12px', padding: '0 12px' }} className="mb-4 mt-2">4.70 contract(s) will be closed at 1,216.65 price, and your expected points will be 9.7290USDT (includng of EST, closing tees 3.4309USDT)</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateMarket()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseMarket}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Market model close */}

      {/* Stop trade model */}
      <Modal className="withdraw-details two-factor-auth text-center" centered backdrop="static" show={showFuture} onHide={handleCloseFuture}>
        <Modal.Header className="modal-main-heading" closeButton> </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <span>
              Are you sure want to stop it at
              {stopRate}
              rate?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseFuture}>No</Button>
          <Button variant="danger" onClick={() => { handleFutureStop(); }}>Yes</Button>
        </Modal.Footer>
      </Modal>
      {/* Stop trade model close */}

      <FutureOrderEditModal 
        isShown={isEditModalShown} 
        hideModal={closeEditModal}
        data={editModalData}
        currentPrice={currentMarketPrice}
      />
    </>
  );
}

export default ActiveOrder;
