import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  deleteAdminAddress,
  setDefaultNetworkAdminAddress,
} from '../redux/adminAddress/adminAddressActions';
import { ModalFilters } from '../components';
import { showAllNetworks } from '../redux/network/networkActions';
import { showAllCurrencies } from '../redux/currency/currencyActions';

export const AdminAddressSchema = (editWith, getAdminAddressesRequest) => {
  const dispatch = useDispatch();

  const networks = useSelector((state) => state.network?.networks);

  const [filter, setFilter] = useState(() => {
    const savedFilter = localStorage.getItem('DataTable_psp/admin_wallets_columns');
    return savedFilter ? JSON.parse(savedFilter) : { network: '', currency: '' };
  });
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);

  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );
  const canSetDefaultAdminAddress = permissions && permissions.length > 0 && permissions.includes('set_default_admin_address');
  const canEditAdminAddress = permissions && permissions.length > 0 && permissions.includes('edit_admin_address');
  const canDeleteAdminAddress = permissions && permissions.length > 0 && permissions.includes('delete_admin_address');

  useEffect(async () => {
    await getAdminAddressesRequest(filter);
  }, [filter, dispatch]);
  
  useEffect(() => {
    if (!networks || networks.length === 0) dispatch(showAllNetworks());
    if (!currencies || currencies.length === 0) {
      dispatch(showAllCurrencies());
    } else {
      setAvailableCurrencies(currencies);
    }
  }, [networks, currencies]);

  useEffect(() => {
    if (filter.network?.length) {
      const selectedNetworkIds = filter.network;
      const currentNetworks = networks.filter((net) => selectedNetworkIds.includes(net._id));

      const availableCurrenciesByNetworks = [...new Map(currentNetworks?.map((net) => net?.currencies).flat().map((currency) => [currency._id, currency])).values()];

      if (availableCurrenciesByNetworks?.length > 0) setAvailableCurrencies(availableCurrenciesByNetworks);
    }
  }, [filter.network]);

  const handleNetworkChange = (e) => {
    const newFilter = { ...filter, currency: '', network: e.target.value };
    setFilter(newFilter);
    localStorage.setItem('DataTable_psp/admin_wallets_columns', JSON.stringify(newFilter));
  };

  const handleCurrencyChange = (e) => {
    const newFilter = { ...filter, currency: e.target.value };
    setFilter(newFilter);
    localStorage.setItem('DataTable_psp/admin_wallets_columns', JSON.stringify(newFilter));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteAdminAddress(id));
        await getAdminAddressesRequest(filter);
      }
    });
  };

  const handleSetDefault = (id) => {
    Swal.fire({
      title: 'Are you sure you want to set it as default Address for selected Network?',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          isDefault: true,
        };
        await dispatch(setDefaultNetworkAdminAddress(id, data));
        await getAdminAddressesRequest(filter);
      }
    });
  };

  const columns = [
    {
      name: 'Address',
      cell: (row) => row.address,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: (<ModalFilters
        data={networks}
        filters={filter.network}
        setFilters={(value) => handleNetworkChange({ target: { value } })}
        tabName="Network"
        searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
        usersFilters={filter}
        setUserFilters={setFilter}
      />),
      cell: (row) => row.network.map((net) => net.name).join(', '),
      selector: (row) => row.network.map((net) => net.name).join(', '),
      sortable: true,
      minWidth: '350px',
      left: true,
    },
    {
      name: (
        filter?.network?.length ? (
          <ModalFilters
            data={availableCurrencies}
            filters={filter.currency}
            setFilters={(value) => handleCurrencyChange({ target: { value } })}
            tabName="Currency"
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={filter}
            setUserFilters={setFilter}
          />
        ) : 'Currency'
      ),
      cell: (row) => row.currencies.map((cur) => cur.name).join(', '),
      selector: (row) => row.currencies.map((cur) => cur.name).join(', '),
      sortable: true,
      minWidth: '350px',
      left: true,
    },
    {
      name: 'Actions',
      minWidth: '350px',
      cell: (row) => (
        <div className="d-flex flex-column gap-1 m-2">
          <div>
            {canSetDefaultAdminAddress && (
            <button disabled={row.isDefault} type="button" onClick={() => handleSetDefault(row._id)} className="btn btn-success me-2">
              {row.isDefault ? (
                <>
                  <FontAwesomeIcon icon={faStar} />
                  {' '}
                  Default
                </>
              ) : 'Set Default'}
            </button>
            )}
            {canEditAdminAddress && (
            <Link to={`/edit-admin-address/${row._id}`} className="btn btn-primary me-2 text-decoration-none text-light">Edit</Link>
            )}
            {canDeleteAdminAddress && (
            <button type="button" onClick={() => handleDelete(row._id)} className="btn btn-danger me-2">
              <FontAwesomeIcon icon={faTrash} />
              {' '}
              Delete
            </button>
            )}
          </div>
          <div>          
            {' '}
            {canEditAdminAddress && row.address.startsWith('xpub') && (
            <button type="button" className="btn btn-warning" onClick={() => editWith(row._id)}>
              Generated Wallets
            </button>
            )}
          </div>
        </div>
      ),
    },
  ];

  return columns;
};
