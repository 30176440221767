import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  GET_OTHER_PSP, ADD_OTHER_PSP, DELETE_OTHER_PSP, UPDATE_OTHER_PSP, 
} from './pspOthersTypes';

export const getOthersPsp = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/other-psp');
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_OTHER_PSP,
        payload: res.data.othersPsp,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const addOtherPsp = (formData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/other-psp', formData);
    if (res && res.data && res.data.success) {
      dispatch({
        type: ADD_OTHER_PSP,
        payload: res.data.psp,
      });
      toast.success(res.data.message, {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteOtherPsp = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/other-psp/${id}`);
    if (res && res.data && res.data.success) {
      dispatch({
        type: DELETE_OTHER_PSP,
        payload: id,
      });
      toast.success(res.data.message, {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateOtherPsp = (id, formData) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/other-psp/${id}`, formData);
    if (res && res.data && res.data.success) {
      dispatch({
        type: UPDATE_OTHER_PSP,
        payload: res.data.otherPsp,
      });

      toast.success(res.data.message, {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};


export const exportOthersPspToFile = async (fileType) => {
  try {
    const res = await apiHelper('get', `/api/other-psp/export/${fileType}`);
    if (res?.data && res?.data?.message) {
      const { data } = res;
      if (data.fileUrl) {
        window.open(data?.fileUrl);
      } else {
        toast.error('Something went wrong.');
      }
      toast.success(data?.message);
    } else {
      toast.error('Something went wrong.');
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const importPspOthersFile = async (data) => {
  const res = await apiHelper('post', '/api/other-psp/import', data);

  if (res?.data) {
    const { data } = res;
    toast.success(data?.message);
  } else {
    toast.error('Something went wrong.');
  }

  return res?.data;
};

