import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Swal from 'sweetalert2';

function UploadFile({ afterUpload }) {
  const handleCSVChange = (e, fileType) => {
    const file = e.target.files[0];
    let typeCheck = ['csv'];

    if (fileType !== 'csv') {
      typeCheck = ['xlsx'];
    }

    if (file) {
      const reader = new window.FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (typeCheck.indexOf(fileExtension) > -1) {
          const formData = new FormData();

          formData.append('file', file);
          formData.append('fileExtension', fileExtension);

          await afterUpload(formData);
        } else {
          Swal.fire({
            text: `The file must be a file of type: ${fileType}`,
            icon: 'error',
            customClass: 'custom-alt',
          });
        }
      };
    }
  };

  return (
    <Dropdown className="leads-download-dropdown ms-1">
      <Dropdown.Toggle
        variant=""
        className="btn-default"
        id="dropdown-basic"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          backgroundColor: 'green',
        }}
      >
        <FontAwesomeIcon icon={faUpload} size="xs" />
        Upload
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="import-csv-btn text-center mb-1">
          <label htmlFor="file-import-xlsx" className="btn btn-default">
            Excel
          </label>
          <input
            style={{ display: 'none' }}
            id="file-import-xlsx"
            type="file"
            name="csvFile"
            accept=".xlsx"
            onChange={(e) => handleCSVChange(e, 'xlsx')}
          />
        </div>
        <div className="import-csv-btn text-center">
          <label htmlFor="file-import-csv" className="btn btn-default">
            CSV
          </label>
          <input
            style={{ display: 'none' }}
            id="file-import-csv"
            type="file"
            name="csvFile"
            accept=".csv"
            onChange={(e) => handleCSVChange(e, 'csv')}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UploadFile;
