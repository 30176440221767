import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ModalFilters } from '../../components';
import { RangeModalFilter } from '../../pages/Transactions/modalFilters';
import { omitColumn, formatDate } from '../helper';
import styles from './styles.module.css';
import { toFixed } from '../../helpers/helper';
import { TradeType } from '../../types/order';

export const OpenOrdersSchema = (
  setSelectedRow,
  setFutureStartPrice,
  handleShowFutureStartPrice,
  getLiquidationPrice,
  getPositionMargin,
  getUnrealizedPnL,
  handleShowTPSL,
  setTP,
  setSL,
  handleShowTrailing,
  setTrailingPrice,
  setCoinAmount,
  setOrderRate,
  handleShowLimit,
  handleShowMarket,
  setStopRate,
  rates,
  setOrderId,
  setFutureOrderId,
  handleShowFuture,
  columnConfig,
  pairNames,
  setCoinPairsFilter,
  amountRangeFilter,
  setAmountRangeFilter,
  setDebounceAmountRange,
  userNameFilter,
  setUserNameFilter,
  openOredersFilters,
  setOpenOredsFilters,
  permissionName,
  openEditModal,
  orderIdFilter, 
  setOrderIdFilter,
  coinPairsFilter,
  inputFieldError, 
  setInputFieldError,
) => {
  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    if (value.length < 3 && value !== '') setInputFieldError((prev) => ({ ...prev, errorMessage: 'Must be more than 3 symbols', [nameField]: true }));
    else setInputFieldError((prev) => ({ ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'orderIdIsNotValid') setOrderIdFilter(value);
    if (nameField === 'userNameIsNotValid') setUserNameFilter(value);
  };

  const isUserCanSeeClientCard = permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName.includes('leads');

  const futureOrders = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.inputsWrapper}`}>
          Order Id
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.orderIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            name="orderId"
            autoComplete="off"
            value={orderIdFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'orderIdIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.orderIdIsNotValid && (
              <p className="errorInput">
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>
      ),
      selector: (row) => (
        <CopyToClipboard text={row?.customId ?? row?._id}>
          <span>
            {row?.customId ?? `.${row?._id?.slice(0, 4)}...${row?._id?.slice((row?._id.length || 0) - 4, row?._id.length)}`}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      omit: omitColumn(columnConfig, 'Order ID'),
      width: '200px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.inputsWrapper}`}>
          User Name
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.userNameIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            name="userName"
            autoComplete="off"
            value={userNameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'userNameIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.userNameIsNotValid && (
              <p className="errorInput">
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>
      ),
      selector: (row) => (
        (row.user.clientType === 1 && isUserCanSeeLeadsCard) || (row.user.clientType === 2 && isUserCanSeeClientCard) ? (
          <Link to={`/user-detail/${row.user._id}`} className="text-decoration-none">
            {`${row.user.firstName} ${row.user.lastName}`}
          </Link>
        ) : `${row.user.firstName} ${row.user.lastName}`
      ),
      omit: omitColumn(columnConfig, 'User Name'),
      width: '200px',
    },
    {
      name: (
        <ModalFilters
          data={pairNames}
          filters={coinPairsFilter}
          setFilters={setCoinPairsFilter}
          tabName="Contracts" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={openOredersFilters}
          setUserFilters={setOpenOredsFilters}
        />
      ),
      selector: (row) => (
        <>
          <b>
            {row.pairName}
          </b>
          <div style={{ marginTop: '5px' }}>
            {row.marginType === 0 ? 'Cross' : 'Isolated'}
            <span style={row.tradeType === TradeType.LONG ? { color: 'green' } : { color: 'red' }}>
              {row.leverage}
              x
            </span>
          </div>
        </>
      ),
      omit: omitColumn(columnConfig, 'Contracts'),
      width: '200px',
    },
    {
      name: 'Qty',
      selector: (row) => `${parseFloat(row.qty).toFixed(2)} ${row?.toCurrency?.symbol}`,
      omit: omitColumn(columnConfig, 'QTY'),
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Value" 
          value={amountRangeFilter ?? [0, 1000000]} 
          setValue={setAmountRangeFilter}
          filters={{}}
          setFilters={setDebounceAmountRange}
          filedName="amountRange"
          debounceAmountRange
          minValue={0}
        />
      ),
      selector: (row) => `${parseFloat(parseFloat(row.qty) * parseFloat(row.tradeStartPrice)).toFixed(2)
      } ${
        row?.fromCurrency?.symbol}`,
      omit: omitColumn(columnConfig, 'Value'),
      width: '200px',
    },
    {
      name: 'Entry Price',
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Entry Price'),
      selector: (row) => (
        <span>
          { row?.tradeStartPrice ? `${parseFloat(row.tradeStartPrice).toFixed(2)} ${row?.fromCurrency?.symbol}` : '-'}
        </span>
      ),
    },
    {
      name: 'Liq. Price',
      omit: omitColumn(columnConfig, 'Liq Price'),
      selector: (row) => (
        <>
          <span id={`${row?._id}liq`}>
            {toFixed(row.tradeEndPrice, 2)}
          </span>
          <span>
            {` ${row?.fromCurrency?.symbol}`}
          </span>
        </>
      ),
    },
    {
      name: 'Position Margin',
      omit: omitColumn(columnConfig, 'Position Margin'),
      width: '200px',
      selector: (row) => getPositionMargin(row),
    },
    {
      name: 'Unrealized P&L (%)',
      omit: omitColumn(columnConfig, 'Unrealized P&L'),
      width: '200px',
      selector: (row) => {
        const pnLResult = getUnrealizedPnL(row);
        return (
          <span className={`${pnLResult.value >= 0 ? 'text-green' : 'text-red'} d-flex flex-column`}>
            <span id={`${row?._id}pnl`}>
              {toFixed(pnLResult.value, 2)}
            </span>
            <span>
              {row?.fromCurrency?.symbol}
            </span>
            <span>
              {toFixed(pnLResult.percentage, 2)}
              %
            </span>
            <span>
              {`${toFixed(pnLResult.value, 2)} USD`}
            </span>
          </span>
        );
      },
    },
    {
      name: 'Time Opened',
      omit: omitColumn(columnConfig, 'Time Opened'),
      minWidth: '200px',
      cell: ({ createdAt }) => (<span>{formatDate(new Date(createdAt))}</span>),
    },
    {
      name: 'Commission %',
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Commission'),
      cell: ({ commission }) => `${toFixed(commission, 2) ?? 0} %`,
    },
    {
      name: 'Swap',
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Swap'),
      cell: ({ endSwap }) => toFixed(endSwap, 2),
    },
    {
      name: 'Actions',
      omit: omitColumn(columnConfig, 'Actions'),
      minWidth: '230px',
      selector: (row) => (
        <>
          {row?.status === 0 ? (
            <>
              <button
                type="button"
                onClick={() => {
                  setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage));
                  setSelectedRow(row);
                  setOrderRate(row.tradeStartPrice);
                  handleShowLimit();
                }}
                className="btn btn-success btn-sm me-1 p-1"
              >
                Limit
              </button>
              <button
                type="button"
                onClick={() => {
                  setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage));
                  setSelectedRow(row);
                  setOrderRate(row.tradeStartPrice);
                  handleShowMarket();
                }}
                className="btn btn-success btn-sm me-1 p-1"
              >
                Market
              </button>
            </>
          ) : null}
          <button 
            type="button" 
            className="btn btn-warning btn-sm me-1 p-1" 
            onClick={() => openEditModal(row)}
          >
            Edit
          </button>
          <button
            type="button"
            onClick={() => {
              setStopRate(rates ? rates?.find((line) => line.symbol === row.pairName).markPrice : 0);
              setOrderId(row?._id);
              setFutureOrderId(row?._id);
              handleShowFuture();
            }}
            className="btn btn-danger btn-sm me-1 p-1"
          >
            Stop
          </button>
        </>
      ),
    },
  ];
  return futureOrders;
};
