import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { editLeverageHistoryOrder } from '../../redux/leverageOrder/leverageOrderActions';
import TradeTypeSelect from '../../components/TradeTypeSelect';
import { getTradeType } from '../../helpers/tradeType';
import { fetchOrdersHistory } from '../../redux/ordershistory/ordersHistoryActions';

export function EditOrderModal({
  order = {}, hideModal, filters, page = 1, limit = 25,
}) {
  const dispatch = useDispatch();
  const [updatedOrder, setUpdatedOrder] = useState({});

  const tradeType = getTradeType(updatedOrder?.tradeType);

  const tradeTypeChange = (selectedOption) => {
    if (selectedOption) {
      setUpdatedOrder((prev) => ({ ...prev, tradeType: selectedOption.value }));
    }
  };

  useEffect(() => {
    const {
      tradeStartPrice, 
      exitPrice, 
      qty, 
      tradeProfitOrLoss, 
      initiatedAt, 
      createdAt, 
      closedAt, 
      updatedAt,
      tradeType,
    } = order;

    setUpdatedOrder({
      tradeType,
      tradeStartPrice,
      exitPrice,
      qty,
      tradeProfitOrLoss,
      initiatedAt: moment(initiatedAt || createdAt).format('YYYY-MM-DD HH:mm:ss'),
      closedAt: moment(closedAt || updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    });
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUpdatedOrder((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setUpdatedOrder({});
    hideModal();
  };

  const updateOrder = async () => {
    const data = { ...order };

    data.initiatedAt = new Date(updatedOrder.initiatedAt);
    data.closedAt = new Date(updatedOrder.closedAt);
    data.tradeStartPrice = parseFloat(updatedOrder.tradeStartPrice);
    data.exitPrice = parseFloat(updatedOrder.exitPrice);
    data.qty = parseFloat(updatedOrder.qty);
    data.diffInProfitOrLoss = parseFloat(data.tradeProfitOrLoss - updatedOrder.tradeProfitOrLoss);
    data.tradeProfitOrLoss = parseFloat(updatedOrder.tradeProfitOrLoss);
    data.fromCurrency = data.fromCurrency._id;
    data.toCurrency = data.toCurrency._id;
    data.currentUserId = data.user._id;
    data.tradeType = updatedOrder.tradeType;

    await dispatch(editLeverageHistoryOrder(data._id, data));
    if (filters) {
      await dispatch(fetchOrdersHistory({ page, limit, ...filters }));
    }
    handleClose();
  };

  return (
    !!Object.keys(updatedOrder).length && (
    <>
      <div className="row mb-3">
        <div>
          <div className="form-group mt-2">
            <label className="control-label">Type</label>
            <TradeTypeSelect tradeType={tradeType} onTradeTypeChange={tradeTypeChange} />
          </div>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Open</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Open"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.tradeStartPrice}
            name="tradeStartPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Close</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Closed"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.exitPrice}
            name="exitPrice"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Volume</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Volume"
            aria-label=""
            aria-describedby=""
            name="qty"
            value={updatedOrder.qty}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Profit</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Profit"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.tradeProfitOrLoss}
            name="tradeProfitOrLoss"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Opened</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Time Opened"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.initiatedAt}
            name="initiatedAt"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Time Closed</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Time Closed"
            aria-label=""
            aria-describedby=""
            value={updatedOrder.closedAt}
            name="closedAt"
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={updateOrder}>Save</button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
      </div>
    </>
    )
  );
}
