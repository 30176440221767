import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { exportOthersPspToFile, getOthersPsp, importPspOthersFile } from '../../redux/pspOthers/pspOthersActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { ModalContext } from '../../context';
import { OtherPspModal } from './modals';
import { OtherPspSchema } from '../../DataTableSchemas/otherPspSchema';
import { DownloadFile } from '../../components/DownloadFile';
import UploadFile from '../../components/UploadFile';

function PspOthers() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const [loader, setLoader] = useState(true);

  const othersPsp = useSelector((state) => state.othersPsp);

  useEffect(async () => {
    await dispatch(getOthersPsp());
    setLoader(false);
  }, []);

  const columns = OtherPspSchema(OtherPspModal, showModal);


  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleExportTransactions = async (fileType) => {
    if (othersPsp.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await exportOthersPspToFile(fileType);
    } else {
      toastError('There is nothing to download.');
    }
  };

  const handleCSVUpload = async (formData) => {
    await importPspOthersFile(formData);
    await dispatch(getOthersPsp());
  };

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h3>PSP Others</h3>
            <div className="action__btn-row">
              <button 
                type="button"
                className="btn btn-default"
                onClick={() => showModal({
                  bodyContent: <OtherPspModal row={{ label: '', status: true, url: '' }} />,
                  headerContent: <h3>Add Other PSP</h3>, 
                })}
              >
                Add Other PSP
              </button>
              <UploadFile afterUpload={handleCSVUpload} />
              <DownloadFile handleExport={handleExportTransactions} />
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
              <DataTable
                columns={columns}
                data={othersPsp}
                pagination
                fixedHeader
                persistTableHead
                highlightOnHover
                defaultSortFieldId={1}
                theme="solarizedd"
              />
            </div>
          </div>
        </div>
      )
  );
}

export default PspOthers;
