import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import LogModal from '../../Modals/LogModal/LogModal';
import './InformationCell.css';

function InformationCell({ log }) {
  const [showModal, setShowModal] = useState(false);
  const maxLength = 100; // Set a threshold for when to show the "more" button
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="information-cell">
      {log.length > maxLength ? (
        <>
          <span>
            {log.substring(0, maxLength)}
            ...
            {' '}
          </span>
          <Button
            variant="warning"
            size="sm"
            className="information-btn"
            onClick={handleShowModal}
          >
            Log too large... More
          </Button>
        </>
      ) : (
        <span>{log}</span>
      )}
      {showModal && <LogModal log={log} onHide={handleCloseModal} />}
    </div>
  );
}

export default InformationCell;
