export const pendingOrdersColumnsConfig = [
  {
    name: 'Order ID',
    field: '_id',
    selected: true,
  },
  {
    name: 'User',
    field: 'user.firstName,user.lastName',
    selected: true,
  },
  {
    name: 'Trading Type',
    field: 'tradingType',
    selected: true,
  },
  {
    name: 'Trading Pair',
    field: 'tradingPair',
    selected: true,
  },
  {
    name: 'Direction',
    field: 'direction',
    selected: true,
  },
  {
    name: 'Swap',
    field: 'swap',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
  },
  {
    name: 'Leverage',
    field: 'leverage',
    selected: true,
  },
  {
    name: 'Order Qty',
    field: 'orderQty',
    selected: true,
  },
  {
    name: 'Entry Price',
    field: 'openPrice',
    selected: true,
  },
  {
    name: 'Order Volume',
    field: 'orderVolume',
    selected: true,
  },
  {
    name: 'Liquidation Price',
    field: 'liquidationPrice',
    selected: true,
  },
  {
    name: 'Position Margin',
    field: 'positionMargin',
    selected: true,
  },
  {
    name: 'Created Time',
    field: 'startTime',
    selected: true,
  },
  {
    name: 'TP/SL',
    field: 'tpSl',
    selected: true,
  },
  {
    name: 'Trailing Stop',
    field: 'trailingStop',
    selected: true,
  },
  {
    name: 'Close By',
    field: 'actions',
    selected: true,
  },
];
