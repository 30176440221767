import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  faClone, faUndo, faCircleInfo, faTimes, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { RangeModalFilter } from '../pages/PendingWithdraws/modalFilters';
import { ModalFilters, TimeInitiatedFilter } from '../components';
import { omitColumn, setTransactionActionColumn } from './helper';

const currencyFormatter = require('currency-formatter');

export const withdrawColumnsSchema = (
  amountRange,
  transactionIdFilter,
  searchByTransactionId,
  setAmountRange,
  currencyFilter,
  setCurrencyFilter,
  currencies,
  inUSDRange,
  setInUSDRange,
  getRateInUsdt,
  setTransactionFilter,
  transactionFilter,
  statusFilter,
  setStatusFilter,
  activeInfo,
  setActiveInfo,
  permissionName,
  resolveCurrentWithDrawTransaction,
  handleRevertTransaction,
  resolveCurrentBankWithDrawTransaction,
  resolveCurrentFiatWithDrawTransaction,
  onIsRealChange,
  columnConfig,
  isRealFilter,
  setIsRealFilter,
  setTimeInitiatedFilter,
  timeFilterRef,
  filtersStorageName,
  filters,
  setFilters,
  sortedData,
  updateIsReal,
  handleDeleteTransaction,
) => {
  const dispatch = useDispatch();

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleTransactionInfoModalToggle = (id) => {
    if (activeInfo === id) setActiveInfo(null);
    else setActiveInfo(id);
  };

  const setTransactionInfo = (row) => {
    if (Object.hasOwnProperty.call(row, 'toAddress')) {
      return (
        <>
          <h6>To Address</h6>
          <p>{row.toAddress}</p>
        </>
      );
    }
    if (Object.hasOwnProperty.call(row, 'toCard')) {
      return (
        <>
          <h6>To Card</h6>
          <p>{row.toCard}</p>
        </>
      );
    }
    if (Object.hasOwnProperty.call(row, 'toIban')) {
      return (
        <>
          <h6>To Iban</h6>
          <p>{row.toIban}</p>

          <h6>Account Number</h6>
          <p>{row.toAccountNumber}</p>

          <h6>Swift Code</h6>
          <p>{row.toSwiftCode}</p>
        </>
      );
    }
    return <h6>No Info</h6>;
  };

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Transaction ID
        </div>),
      selector: (row) => (
        <CopyToClipboard text={row?.customId ?? row?._id}>
          <span>
            {row?.customId ?? `${row?._id?.slice(0, 4)}...${row?._id?.slice((row?._id.length || 0) - 4, row?._id.length)}`}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      omit: omitColumn(columnConfig, 'Transaction ID'),
      width: '200px',
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Amount" 
          value={amountRange} 
          setValue={setAmountRange}
          filters={filters}
          setFilters={setFilters}
          filedName="amountRange"
        />
      ),
      minWidth: '160px',
      selector: (row) => parseFloat(row.amount).toFixed(2),
      omit: omitColumn(columnConfig, 'Amount'),
    },
    {
      name: (
        <ModalFilters
          data={currencies}
          filters={currencyFilter}
          setFilters={setCurrencyFilter}
          tabName="Currency" 
          searchParams={{ id: 'symbol', params: ['symbol'], optionsName: ['symbol'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      minWidth: '200px',
      selector: (row) => row?.currency,
      omit: omitColumn(columnConfig, 'Currency'),
    },
    {
      name: (
        <RangeModalFilter 
          columnName="In USD" 
          value={inUSDRange} 
          setValue={setInUSDRange}
          filters={filters}
          setFilters={setFilters}
          filedName="isUSDRange"
        />
      ),
      selector: (row) => parseFloat(row?.amount).toFixed(2),
      omit: omitColumn(columnConfig, 'In USD'),
      cell: (row) => {
        const usdtValue = getRateInUsdt(row?.currency, row?.amount);
        if (usdtValue !== undefined && usdtValue !== null) {
          return currencyFormatter.format(usdtValue, { code: 'USD' });
        }
        return '-';
      },
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Crypto', _id: 'Crypto' }, { name: 'Card', _id: 'Card' }, { name: 'Bank', _id: 'Bank' }, { name: 'Admin Transaction', _id: 'Admin Transaction' }]}
          filters={transactionFilter}
          setFilters={setTransactionFilter}
          tabName="Transaction Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      minWidth: '230px',
      omit: omitColumn(columnConfig, 'Transaction Type'),
      selector: (row) => {
        if (Object.hasOwnProperty.call(row, 'toAddress')) {
          return 'Crypto';
        }
        if (Object.hasOwnProperty.call(row, 'toCard')) {
          return 'Card';
        }
        if (Object.hasOwnProperty.call(row, 'toIban')) {
          return 'Bank';
        }
        return 'Admin Withdrawal';
      },
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
          filters={statusFilter}
          setFilters={setStatusFilter}
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      omit: omitColumn(columnConfig, 'Status'),
      selector: (row) => (
        <div 
          onMouseLeave={() => {
            setActiveInfo(null);
          }}
        >
          {row?.isResolved === 0 ? (
            <span className="badge rounded-pill bg-warning">Pending</span>
          ) : row?.isResolved === 1 ? (
            <span className="badge rounded-pill bg-success">Completed</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Declined</span>
          )}
          <span>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color={(Object.hasOwnProperty.call(row, 'toIban') 
              || Object.hasOwnProperty.call(row, 'toCard') 
              || Object.hasOwnProperty.call(row, 'toAddress')) 
                ? '#6E7F95' : '#4a5668'}
              style={{ cursor: 'pointer' }}
              onMouseEnter={() => {
                handleTransactionInfoModalToggle(row._id);
              }}
            />

            {activeInfo === row._id 
            && (Object.hasOwnProperty.call(row, 'toIban') || Object.hasOwnProperty.call(row, 'toCard') || Object.hasOwnProperty.call(row, 'toAddress')) ? (
              <div className="withdrawsInfoModal">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  style={{
                    cursor: 'pointer', position: 'absolute', right: '10px', top: '10px',
                  }}
                  onClick={() => {
                    setActiveInfo(null);
                  }}
                />
                {setTransactionInfo(row)}
              </div>
              ) : (
                null
              )}
          </span>
        </div>
      ),
      minWidth: '200px',
    },
    {
      name: (
        <TimeInitiatedFilter 
          data={sortedData} 
          setFilter={setTimeInitiatedFilter} 
          setToAllFilters={setFilters} 
          allFilters={filters} 
          storageName={filtersStorageName}
          ref={timeFilterRef}
        />
      ),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      selector: (row) => (row?.createdAt ? moment(row?.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''),
      minWidth: '230px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Real', _id: 'true' }, { name: 'Fake', _id: 'false' }]}
          filters={isRealFilter}
          setFilters={setIsRealFilter}
          tabName="Real/Fake" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Real/Fake'),
      selector: (row) => (row.isReal !== undefined ? (row.isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),     
    },
    {
      name: 'Action(s)',
      omit: omitColumn(columnConfig, 'Action'),
      minWidth: '300px',
      cell: (row) => {
        let resolveMethod = resolveCurrentWithDrawTransaction;

        if (Object.hasOwnProperty.call(row, 'toIban')) {
          resolveMethod = resolveCurrentBankWithDrawTransaction;
        } else if (Object.hasOwnProperty.call(row, 'toCard')) {
          resolveMethod = resolveCurrentFiatWithDrawTransaction;
        }

        return setTransactionActionColumn(
          row, 
          permissionName,
          resolveMethod,
          updateIsReal,
          handleRevertTransaction,
          handleDeleteTransaction,
          false,
          dispatch,
        );
      },
    },
  ];

  return columns;
};
