import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import {
  Modal, InputGroup, FormControl, Button,
} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { PendingOrdersSchema } from '../../DataTableSchemas/PendingOrdersSchema/PendingOrdersSchema';
import { pendingOrdersColumnsConfig } from '../../columnsDefaultConfig/pendingOrdersColumns';
import { DatatableColumns, DatatableFilters } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { updateLeverageOrder, stopLeverageOrder } from '../../redux/leverageOrder/leverageOrderActions';
import {
  fetchOrdersHistory,
  fetchPairNames,
} from '../../redux/ordershistory/ordersHistoryActions';
import { stopSpotOrder } from '../../redux/spotOrder/spotOrderActions';
import { columnPendingOrdersSortMap, orderStatusType } from '../../types/ordersHistory';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

import './PendingOrders.css';

function ComposedOrdersHistory() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const innerPathname = `${pathname}/pending-orders`;
  const defaultFilters = { statusType: orderStatusType.PENDING };

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [columnConfig, setColumnConfig] = useState(pendingOrdersColumnsConfig);
  const [sortItemAndDirection, setSortItemAndDirection] = useState({});

  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [limit, setLimit] = useState(25);
  const [filters, setFilters] = useState(defaultFilters);
  const [page, setPage] = useState(1);

  const [tradingTypeFilter, setTradingTypeFilter] = useState([]);
  const [tradingPairFilter, setTradingPairFilter] = useState([]);
  const [directionFilter, setDirectionFilter] = useState([]);

  const permissionNames = useSelector((state) => state.crmUser?.currentUserPermissions || []);

  const {
    ordersHistory, totalCount, pairNames,
  } = useSelector((state) => state?.ordersHistoryReducer);
  
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters?.filter((filter) => filter.pathname === innerPathname));

  const datatableFiltersRef = useRef(null);

  const [selectedRow, setSelectedRow] = useState('');
  const [futureStartPrice, setFutureStartPrice] = useState(0);
  const [showFutureStartPrice, setShowFutureStartPrice] = useState(false);
  const [coinAmount, setCoinAmount] = useState(0);
  const [orderRate, setOrderRate] = useState(0);
  const [stopRate, setStopRate] = useState(0);
  const [futureOrderId, setFutureOrderId] = useState('');
  const [avbl] = useState(0);
  const [TP, setTP] = useState(0);
  const [SL, setSL] = useState(0);
  const [trailingPrice, setTrailingPrice] = useState(0);
  const [rate, setRate] = useState(0);

  const [rates, setRates] = useState('');
  const [showTPSL, setShowTPSL] = useState(false);
  const [showTrailing, setShowTrailing] = useState(false);
  const [showMarket, setShowMarket] = useState(false);
  const [showFuture, setShowFuture] = useState(false);
  const [showLimit, setShowLimit] = useState(false);


  const columnsStorageName = 'DataTable_pending_orders_columns';
  const filtersStorageName = 'DataTable_pending_orders_filters';
  const filterIdStorageName = 'DataTable_pending_orders_filter_id';
  const paginationStorageName = 'DataTable_pending_orders_pagination';

  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const paginationJSON = localStorage.getItem(paginationStorageName);

  const handleShowFutureStartPrice = () => setShowFutureStartPrice(true);
  const handleShowTPSL = () => setShowTPSL(true);
  const handleCloseTPSL = () => setShowTPSL(false);
  const handleShowTrailing = () => setShowTrailing(true);
  const handleCloseTrailing = () => setShowTrailing(false);
  const handleShowMarket = () => setShowMarket(true);
  const handleShowFuture = () => setShowFuture(true);
  const handleShowLimit = () => setShowLimit(true);
  const handleCloseLimit = () => setShowLimit(false);
  const handleCloseFutureStartPrice = () => setShowFutureStartPrice(false);
  const handleCloseMarket = () => setShowMarket(false);
  const handleCloseFuture = () => setShowFuture(false);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleClear = () => {
    setTradingTypeFilter([]);
    setTradingPairFilter([]);
    setDirectionFilter([]);
    setFilters(defaultFilters);
    setPage(1);
    setLimit(25);
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(columnsStorageName);
    localStorage.removeItem(filterIdStorageName);
    localStorage.removeItem(paginationStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters || defaultFilters);

      setTradingTypeFilter(filters['trading type'] || []);
      setTradingPairFilter(filters['trading pair'] || []);
      setDirectionFilter(filters.direction || []);
    } 
  };

  const setStoredPagination = () => {
    if (paginationJSON) {
      const filterRows = paginationJSON ? JSON.parse(paginationJSON) : {};

      setLimit(filterRows.limit ?? 25);
      setPage(filterRows.page ?? 1);
    } else {
      localStorage.setItem(paginationStorageName, JSON.stringify({ page, limit }));
    }
    setIsPaginationDT(true);
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit, page: currentPage }));
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setLimit(currentRowsPerPage);
    setPage(currentPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page: currentPage }));
  };

  const handleSort = (column, sortDirection) => {
    const direction = sortDirection === 'asc' ? 1 : -1;
    const sortKey = columnPendingOrdersSortMap[column.name];

    setSortItemAndDirection({
      [sortKey]: direction,
    });
  };

  const performActionAndRefresh = async (action) => {
    setTableLoading(true);
    try {
      await action();
      await dispatch(fetchOrdersHistory({ 
        page, 
        limit,
        ...filters,
        ...sortItemAndDirection,
      }));
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setTableLoading(false);
    }
  };

  const setCRMFilters = (filter) => {
    const {
      currency,
      isUSDRange,
      assignedTo,
      convertedCurrency,
      isReal,
    } = filter;

    const newFilters = {
      'trading pair': currency,
      status: isUSDRange,
      direction: assignedTo,
      'trading type': convertedCurrency,
      'opened by': isReal,
      ...defaultFilters,
    };  

    setFilters(newFilters);
    setTradingTypeFilter(convertedCurrency || []);
    setTradingPairFilter(currency || []);
    setDirectionFilter(assignedTo || []);

    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
 
    const data = {
      name,
      crmUserId,
      pathname: innerPathname,
      currency: filters['trading pair'] || [],
      isUSDRange: filters.status || [],
      assignedTo: filters.direction || [],
      convertedCurrency: filters['trading type'] || [],
      isReal: filters['opened by'] || [],
    };
    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters['trading pair'] || [],
        isUSDRange: filters.status || [],
        assignedTo: filters.direction || [],
        convertedCurrency: filters['trading type'] || [],
        isReal: filters['opened by'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const handleFutureStop = () => {
    dispatch(stopLeverageOrder(futureOrderId, parseFloat(stopRate), false));
    setShowFuture(false);
    setFutureOrderId('');
    setStopRate(0);
  };

  const updateFutureTradeStartprice = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeStartPrice) !== parseFloat(futureStartPrice)) {
      data.tradeStartPrice = parseFloat(futureStartPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      performActionAndRefresh(async () => {
        await dispatch(updateLeverageOrder(data, selectedRow._id));
      });
      // setLoader(true);
    }

    handleCloseFutureStartPrice();
  };

  const updateTPSL = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.takeProfitPrice) !== parseFloat(TP) || parseFloat(data.stopLossPrice) !== parseFloat(SL)) {
      data.tpsl = true;
      data.takeProfitPrice = parseFloat(TP);
      data.stopLossPrice = parseFloat(SL);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      performActionAndRefresh(async () => {
        await dispatch(updateLeverageOrder(data, selectedRow._id));
      });
      // setLoader(true);
    }
    handleCloseTPSL();
  };

  const updateTrailing = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeTrailingPrice) !== parseFloat(trailingPrice)) {
      data.tradeTrailingPrice = parseFloat(trailingPrice);
      data.tradeTrailingDifference = parseFloat(trailingPrice) > parseFloat(data.tradeStartPrice) ? parseFloat(trailingPrice) - parseFloat(data.tradeStartPrice) : parseFloat(data.tradeStartPrice) - parseFloat(trailingPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      performActionAndRefresh(async () => {
        await dispatch(updateLeverageOrder(data, selectedRow._id));
      });
      // setLoader(true);
    }

    handleCloseTrailing();
  };

  const handleLimit = (val, update = 0) => {
    if (avbl) {
      const validNumber = (/^\d*\.?\d*$/);
      if (
        !val.toString().match(validNumber)
        || parseFloat(val) > (parseFloat(avbl) + update)
      ) {
        Swal.fire({
          text: 'Invalid number entered. Please enter a valid number',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
        setCoinAmount(0);
      }
    }
  };

  const updateMarket = async () => {
    const data = { ...selectedRow };
    data.tpsl = true;
    data.tradeStartPrice = parseFloat(orderRate);
    data.userInvestedAmount = data.tradeType === 1 ? parseFloat(coinAmount) * parseFloat(orderRate) : parseFloat(coinAmount);
    data.qty = parseFloat(coinAmount) * parseFloat(data.leverage);
    data.fromCurrency = data.fromCurrency._id;
    data.toCurrency = data.toCurrency._id;

    await performActionAndRefresh(async () => {
      await dispatch(updateLeverageOrder(data, selectedRow._id));
    });

    handleCloseMarket();
    handleCloseLimit();
  };

  const handleCancelSpotOrder = async (order) => {
    Swal.fire({
      title: 'Are you sure you want to Cancel the Order?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await performActionAndRefresh(async () => {
          await dispatch(stopSpotOrder(order._id, order.userId));
        });
      }
    });
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
    dispatch(fetchPairNames());
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (!isPaginationDT) return;
      localStorage.setItem(filtersStorageName, JSON.stringify(filters));
  
      setTableLoading(true);

      await dispatch(fetchOrdersHistory({ 
        page, 
        limit,
        ...filters,
        ...sortItemAndDirection,
      }));

      setTableLoading(false);
    }

    fetchData();
  }, [isPaginationDT, page, limit, filters, sortItemAndDirection]);
  

  const refresh = async () => {
    setTableLoading(true);
    await dispatch(fetchOrdersHistory({ 
      page, 
      limit, 
      ...filters,
      ...sortItemAndDirection,
    }));

    setTableLoading(false);
  };

  const columns = PendingOrdersSchema(
    columnConfig,
    filters,
    setFilters,
    permissionNames,
    tradingTypeFilter,
    setTradingTypeFilter,
    tradingPairFilter,
    setTradingPairFilter,
    directionFilter,
    setDirectionFilter,
    pairNames,
    setSelectedRow,
    handleShowTPSL,
    setTP,
    setSL,
    setCoinAmount,
    setOrderRate,
    handleShowLimit,
    handleShowMarket,
    handleShowTrailing,
    setTrailingPrice,
    setStopRate,
    rates,
    setFutureOrderId,
    handleShowFuture,
    handleCancelSpotOrder,
  );

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <>
        <div className="content-wrapper right-content-wrapper pending-orders">
          <div className="content-box">
            <h4>Pending Orders</h4>
            <div className="action__btn-row">
              {crmFilters && (
                <DatatableFilters
                  ref={datatableFiltersRef}
                  filters={crmFilters}
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={innerPathname}
                />
              )}
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
              <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
                <FontAwesomeIcon icon={faTimes} size="sm" />
                Clear
              </button>
              <button type="button" className="btn btn-default icon-btn ms-1" onClick={refresh}>
                <FontAwesomeIcon icon={faRefresh} size="sm" />
                Refresh
              </button>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
              <DataTable
                columns={columns}
                data={ordersHistory}
                fixedHeader
                sortServer
                pagination
                paginationServer
                onSort={handleSort}
                paginationPerPage={limit}
                paginationTotalRows={totalCount}
                paginationRowsPerPageOptions={[25, 50, 100, 500]}
                paginationDefaultPage={page}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                persistTableHead
                highlightOnHover
                theme="solarizedd"
                progressPending={tableLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
            </div>
          </div>
        </div>
        {/* entry price model */}
        <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showFutureStartPrice} centered onHide={handleCloseFutureStartPrice}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="buy-tabs">
              <div className="mb-4">
                <span>
                  Start Trading Price
                  {selectedRow?.fromCurrency?.symbol}
                </span>
              </div>
              <div className="stop-loss-usdt">
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    placeholder="Start Trading Price"
                    min="0"
                    value={futureStartPrice}
                    onChange={(e) => setFutureStartPrice(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">
                    {selectedRow?.fromCurrency?.symbol}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => updateFutureTradeStartprice()}>
              Confirm
            </Button>
            <Button variant="danger" onClick={handleCloseFutureStartPrice}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* entry price model close */}

        {/* TP/SL model */}
        <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTPSL} centered onHide={handleCloseTPSL}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="buy-tabs">
              <div className="mb-4"><span>TP/SL</span></div>

              <div className="d-flex justify-content-between mb-2">
                <span>Entry Price</span>
                <span>{selectedRow.tradeStartPrice}</span>
              </div>
              <div className="d-flex justify-content-between mb-4">
                <span>Liq. Price</span>
                <span className="text-green">{selectedRow.tradeEndPrice}</span>
              </div>

              <div className="take-profit-usdt">
                <div className="d-flex justify-content-between mb-1">
                  <span>Take Profit USDT</span>
                  <span>Last Traded Price</span>
                </div>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    min="0"
                    value={TP}
                    onChange={(e) => setTP(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Take Profit</span>
                    {selectedRow?.fromCurrency?.symbol}
                  </InputGroup.Text>
                </InputGroup>
              </div>

              <div className="stop-loss-usdt">
                <div className="d-flex justify-content-between mb-1">
                  <span>
                    Stop Loss
                    {selectedRow?.fromCurrency?.symbol}
                  </span>
                  <span>Last Traded Price</span>
                </div>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    min="0"
                    value={SL}
                    onChange={(e) => setSL(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Stop Loss</span>
                    {selectedRow?.fromCurrency?.symbol}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => updateTPSL()}>
              Confirm
            </Button>
            <Button variant="danger" onClick={handleCloseTPSL}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* TP/SL model close */}

        {/* Trailing Stop model  */}
        <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTrailing} centered onHide={handleCloseTrailing}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="buy-tabs">
              <div className="mb-4"><span>Trailing Stop</span></div>
              <div className="d-flex justify-content-between mb-2">
                <span>Entry Price</span>
                <span>{selectedRow.tradeStartPrice}</span>
              </div>
              <div className="d-flex justify-content-between mb-4">
                <span>Liq. Price</span>
                <span className="text-green">{selectedRow.tradeEndPrice}</span>
              </div>

              <div className="stop-loss-usdt">
                <div className="d-flex justify-content-between mb-1">
                  <span>
                    Trailing Stop
                    {selectedRow?.fromCurrency?.symbol}
                  </span>
                  {/* <span className="text-white">Last Traded Price</span> */}
                </div>
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    placeholder="Trailing Stop"
                    min="0"
                    value={trailingPrice}
                    onChange={(e) => setTrailingPrice(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">
                    {selectedRow?.fromCurrency?.symbol}
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => updateTrailing()}>
              Confirm
            </Button>
            <Button variant="danger" onClick={handleCloseTrailing}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  Trailing Stop model close */}

        {/* Limit Close model */}
        <Modal className="withdrawal-modal limit-modal" show={showLimit} centered onHide={handleCloseLimit} backdrop="static">
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="buy-tabs">
              <div className="mb-4"><span>Limit Close</span></div>
              <span>
                Entry Price
                {selectedRow?.toCurrency?.symbol}
              </span>
              <InputGroup className="mb-4">
                <FormControl
                  placeholder="Rate"
                  aria-label=""
                  aria-describedby=""
                  value={orderRate}
                  onChange={(e) => { setOrderRate(e.target.value); }}
                />
                <InputGroup.Text
                  className="point"
                  onClick={() => {
                    setRate(!rate);
                  }}
                >
                  +/-
                </InputGroup.Text>
              </InputGroup>
              <span>
                Order by Qty
                {selectedRow?.toCurrency?.symbol}
              </span>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  step="0.1"
                  placeholder="Price"
                  min="0.0"
                  max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
                  value={coinAmount}
                  onChange={(e) => {
                    const val = e.target.value;
                    setCoinAmount(val);
                  }}
                  onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
                />
                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.toCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => updateMarket()}>
              Confirm
            </Button>
            <Button variant="danger" onClick={handleCloseLimit}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Limit Close model close */}

        {/* Market model */}
        <Modal className="withdrawal-modal limit-modal market-modal" show={showMarket} centered onHide={handleCloseMarket}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="buy-tabs">
              <div className="mb-4"><span>Market Close</span></div>

              <span>
                Order by Qty
                {selectedRow?.toCurrency?.symbol}
              </span>
              <InputGroup className="mb-3">

                <FormControl
                  type="number"
                  step="0.1"
                  placeholder="Price"
                  min="0.0"
                  max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
                  value={coinAmount}
                  onChange={(e) => {
                    const val = e.target.value;
                    setCoinAmount(val);
                  }}
                  onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
                />
                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.toCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => updateMarket()}>
              Confirm
            </Button>
            <Button variant="danger" onClick={handleCloseMarket}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Market model close */}

        {/* Stop trade model */}
        <Modal className="withdraw-details two-factor-auth text-center" centered backdrop="static" show={showFuture} onHide={handleCloseFuture}>
          <Modal.Header className="modal-main-heading" closeButton> </Modal.Header>
          <Modal.Body>
            <div className="mb-5">
              <span>
                Are you sure want to stop it at
                {stopRate}
                {' '}
                rate?
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleCloseFuture}>No</Button>
            <Button variant="danger" onClick={() => { handleFutureStop(); }}>Yes</Button>
          </Modal.Footer>
        </Modal>
        {/* Stop trade model close */ }
      </>
    )
  );
}

export default ComposedOrdersHistory;
