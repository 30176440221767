/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faLink, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@mui/material';
import { getCryptoPairTableData, getCryptoPairs, updateCryptoPair } from '../../redux/cryptoPair/cryptoPairActions';
import { ModalContext } from '../../context';
import { ModalFilters } from '../../components';
import AdditionalEditModal from './AdditionalEditModal';
import { toFixed } from '../../helpers/helper';
import AdditionalMassEditModal from './AdditionalMassEditModal';
import { updateSystemSettings, getSystemSettings } from '../../redux/systemSettings/systemSettingsActions';
import { SystemSettingsTypes } from '../../types/systemSettings';


export default function Additional() {
  const dispatch = useDispatch();
  
  const { showModal, hideModal } = useContext(ModalContext);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [nameFilters, setNameFilters] = useState([]);
  const [selectedPairsIds, setSelectedPairsIds] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [isLeverageChangeEnabled, setIsLeverageChangeEnabled] = useState(false);

  const { pairs, totalCount } = useSelector((state) => state.cryptoPairs.tableData);
  const pairNames = useSelector((state) => state.cryptoPairs.pairNames);
  const { systemSettings } = useSelector((state) => state.systemSettingsReducer);

  const paitNamesConf = pairNames.map((name, idx) => ({ name, _id: idx }));

  useEffect(() => {
    dispatch(getCryptoPairs());
    dispatch(getSystemSettings());

    if (!systemSettings) {
      return;
    }

    setIsLeverageChangeEnabled(systemSettings?.find((setting) => setting.name === SystemSettingsTypes.IS_LEVERAGE_CHANGE_ENABLED)?.value);
  }, []);
  
  useEffect(() => { 
    if (!systemSettings) {
      return;
    }
  
    setIsLeverageChangeEnabled(systemSettings?.find((setting) => setting.name === SystemSettingsTypes.IS_LEVERAGE_CHANGE_ENABLED)?.value);
  }, [systemSettings]);

  useEffect(() => {
    dispatch(getCryptoPairTableData({ page, limit: perPage, name: nameFilters }));
  }, [nameFilters, perPage, page]);

  const columns = [
    {
      name: (
        <ModalFilters
          data={paitNamesConf} 
          filters={nameFilters} 
          setFilters={setNameFilters} 
          tabName="Symbol" 
          searchParams={{ id: 'name', params: ['name'], optionsName: ['name'] }}
          usersFilters={[]}
          setUserFilters={() => {}}
        />
      ),
      cell: ({ name }) => name,
    },
    {
      name: 'Alias',
      cell: ({ alias }) => alias,
    },
    {
      name: 'Base Currency',
      cell: ({ slicedValue }) => (slicedValue ? slicedValue.split('/')[1] : '-'),
    },
    {
      name: 'Commission %',
      cell: ({ commission }) => commission,
    },
    {
      name: 'Swap Long %',
      cell: ({ swapLong }) => toFixed(swapLong, 2),
    },
    {
      name: 'Swap Short %',
      cell: ({ swapShort }) => toFixed(swapShort, 2),
    },
    {
      name: 'Leverage',
      cell: ({ leverage }) => leverage,
      omit: !isLeverageChangeEnabled,
    },
    {
      name: 'Min Volume',
      cell: ({ minVolume }) => minVolume,
    },
    {
      name: 'Max Volume',
      cell: ({ maxVolume }) => maxVolume,
    },
    {
      name: 'Enabled',
      cell: ({ isEnabled, _id }) => (
        <Switch checked={isEnabled} onChange={() => dispatch(updateCryptoPair(_id, { isEnabled: !isEnabled }))} />
      ),
    },
    {
      name: 'Actions',
      cell: (pair) => (
        <button 
          type="button" 
          className="btn btn-warning btn-sm" 
          onClick={() => showModal({
            headerContent: <h3>EDIT PAIR SETTINGS</h3>,
            bodyContent: <AdditionalEditModal
              pair={pair}
              hideModal={hideModal}
            />,
          })}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      ),
    },
  ];

  const handlePairSelectedChange = ({ selectedRows }) => {
    const ids = selectedRows.map((row) => row._id);
    setSelectedPairsIds(ids);
  };


  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleLeverageChange = async () => {
    const leverageSetting = systemSettings?.find((setting) => setting.name === SystemSettingsTypes.IS_LEVERAGE_CHANGE_ENABLED);

    if (!leverageSetting) {
      toast.error('Leverage setting not found');
      return;
    }

    const newLeverage = {
      name: leverageSetting.name,
      value: !leverageSetting.value,
    };

    await dispatch(updateSystemSettings(newLeverage));
  };

  const handleLeverageChangeConfirmation = () => {
    const action = isLeverageChangeEnabled ? 'Disable' : 'Enable';
    
    Swal.fire({
      title: 'Update leverage changing settings',
      showCancelButton: true,
      confirmButtonText: `${action} Changing Leverage`,
      confirmButtonColor: '#dc3545', 
    }).then((result) => {
      if (result.isConfirmed) {
        handleLeverageChange();
        Swal.fire(`${action}d`, '', 'success');
      } else if (result.isDenied) {
        Swal.fire(`Leverage change remains ${isLeverageChangeEnabled ? 'enabled' : 'disabled'}`, '', 'info');
      }
    });
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h4 className="mb-3 text-capilatize">Additional Settings</h4>
        <div className="additionalPageBtnContainer">
          <p className="additionalPageSelectedRows">{`Selected ${selectedPairsIds.length} rows`}</p>
          <button 
            type="button" 
            disabled={selectedPairsIds.length === 0}
            className="btn btn-default additionalPageBtn"
            onClick={() => showModal({
              headerContent: <h3>MASS EDIT SETTINGS</h3>,
              bodyContent: <AdditionalMassEditModal
                selectedPairsIds={selectedPairsIds}
                hideModal={hideModal}
                setSelectedPairsIds={setSelectedPairsIds}
                setClearRows={setClearRows}
              />,
            })}
          >
            <FontAwesomeIcon
              icon={faLink}
              size="xs"
            />
            Mass Edit Settings
          </button>
          <button 
            type="button" 
            className="btn btn-default additionalPageBtn"
            onClick={handleLeverageChangeConfirmation}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="xs"
            />
            {isLeverageChangeEnabled ? 'Disable ' : 'Enable '}
            Changing Leverage
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          <DataTable
            rows={pairs?._id}
            columns={columns}
            data={pairs}
            theme="solarizedd"
            pagination
            paginationServer
            selectableRows
            onSelectedRowsChange={handlePairSelectedChange}
            selectableRowSelected={pairs?._id}
            selectableRowsHighlight
            selectableRowsVisibleOnly
            clearSelectedRows={clearRows}
            paginationTotalRows={totalCount}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={[10, 20, 50]}
            progressComponent={<div className="datatable-loader__background" />}
          />
        </div>

      </div>
    </div>

  );
}
