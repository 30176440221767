import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faClone } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import moment from 'moment';
import { omitColumn, formatDate } from '../helper';
import { ModalFilters } from '../../components';
import { RangeModalFilter } from '../../pages/PendingWithdraws/modalFilters';
import { toFixed } from '../../helpers/helper';
import { TradeType } from '../../types/order';

import './ActiveOrderSchema.css';

export const FutureOrdersSchema = (
  setSelectedRow,
  setFutureStartPrice,
  handleShowFutureStartPrice,
  getLiquidationPrice,
  getPositionMargin,
  getUnrealizedPnL,
  handleShowTPSL,
  setTP,
  setSL,
  handleShowTrailing,
  setTrailingPrice,
  setCoinAmount,
  setStopRate,
  rates,
  setOrderRate,
  handleShowLimit,
  handleShowMarket,
  setOrderId,
  setFutureOrderId,
  handleShowFuture,
  columnConfig,
  coinsPairs,
  coinPairsFilter,
  setCoinPairsFilter,
  amountRangeFilter,
  setAmountRangeFilter,
  filters,
  setFilters,
  maxValue,
  showEditModal,
) => {
  const handleStopButtonClick = (row) => {
    if (!row) { 
      toast.error('Something went wrong. Please, contact with our support team.', {
        autoClose: 1000,
      });

      return; 
    }

    const foundRate = rates.find((line) => line.symbol === row.pairName);

    setStopRate(foundRate ? foundRate.markPrice : 0);
    setOrderId(row._id);
    setFutureOrderId(row._id);
    handleShowFuture();
  };

  const futureOrders = [
    {
      name: (
        <ModalFilters
          data={coinsPairs}
          filters={coinPairsFilter}
          setFilters={setCoinPairsFilter}
          tabName="Contracts" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (
        <>
          <b>
            {row.pairName}
          </b>
          <div>
            {row.marginType === 0 ? 'Cross' : 'Isolated'}
            <span className={row.tradeType === TradeType.LONG ? 'text-green' : 'text-red'}>
              {row.leverage}
              x
            </span>
          </div>
        </>
      ),
      omit: omitColumn(columnConfig, 'Contracts'),
    },
    {
      name: 'Qty',
      selector: (row) => `${parseFloat(row.qty).toFixed(2)} ${row?.toCurrency?.symbol}`,
      sortable: true,
      omit: omitColumn(columnConfig, 'QTY'),
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Value" 
          value={amountRangeFilter} 
          setValue={setAmountRangeFilter}
          filters={filters}
          setFilters={setFilters}
          filedName="amountRange"
          maxValue={maxValue()}
        />
      ),
      selector: (row) => `${parseFloat(parseFloat(row.qty) * parseFloat(row.tradeStartPrice)).toFixed(2)} ${row?.fromCurrency?.symbol}`,
      omit: omitColumn(columnConfig, 'Value'),
    },
    {
      name: 'Entry Price',
      minWidth: '200px',
      selector: (row) => (
        <span>
          {row?.tradeStartPrice
            ? (`${parseFloat(row.tradeStartPrice).toFixed(2)} ${row?.fromCurrency?.symbol}`)
            : '-'}
        </span>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'Entry Price'),
    },
    {
      name: 'Liq. Price',
      selector: (row) => (
        <>
          <span id={`${row?._id}liq`}>
            {toFixed(row.tradeEndPrice, 2)}
          </span>
          <span>
            {` ${row?.fromCurrency?.symbol}`}
          </span>
        </>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'Liq Price'),
    },
    {
      name: 'Position Margin',
      selector: (row) => getPositionMargin(row),
      sortable: true,
      omit: omitColumn(columnConfig, 'Position Margin'),
    },
    {
      name: 'Commission',
      cell: ({ commission }) => `${toFixed(commission, 2) ?? 0} %`,
      omit: omitColumn(columnConfig, 'Commission'),
    },
    {
      name: 'Swap',
      cell: ({ endSwap }) => toFixed(endSwap, 2),
      omit: omitColumn(columnConfig, 'Swap'),
    },
    {
      name: 'Unrealized P&L (%)',
      selector: (row) => {
        const pnLResult = getUnrealizedPnL(row);
        return (
          <span className={`${pnLResult.value >= 0 ? 'text-green' : 'text-red'} d-flex flex-column`}>
            <span id={`${row?._id}pnl`}>
              {toFixed(pnLResult.value, 2)}
            </span>
            <span>
              {row?.fromCurrency?.symbol}
            </span>
            <span>
              {toFixed(pnLResult.percentage, 2)}
              %
            </span>
            <span>
              {`${toFixed(pnLResult.value, 2)} USD`}
            </span>
          </span>
        );
      },
      sortable: true,
      omit: omitColumn(columnConfig, 'Unrealized P&L'),
    },
    {
      name: 'Time Opened',
      omit: omitColumn(columnConfig, 'Time Opened'),
      minWidth: '180px',
      cell: ({ createdAt }) => (<span>{formatDate(new Date(createdAt))}</span>),
    },
    {
      name: 'Actions',
      minWidth: '200px',
      selector: (row) => (
        <>
          {row?.status === 0
            ? (
              <>
                <button type="button" onClick={() => { setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage)); setSelectedRow(row); setOrderRate(row.tradeStartPrice); handleShowLimit(); }} className="btn btn-success btn-sm me-1 p-1">Limit</button>
                <button type="button" onClick={() => { setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage)); setSelectedRow(row); setOrderRate(row.tradeStartPrice); handleShowMarket(); }} className="btn btn-success btn-sm me-1 p-1">Market</button>
              </>
            )
            : null}
          <button 
            type="button" 
            className="btn btn-warning btn-sm me-1 p-1" 
            onClick={() => showEditModal(row)}
          >
            Edit
          </button>
          <button
            type="button"
            onClick={() => handleStopButtonClick(row)}
            className="btn btn-danger btn-sm me-1 p-1"
            disabled={!rates?.length}
          >
            Stop
          </button>
        </>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'Actions'),
    },
  ];
  return futureOrders;
};

export const spotOrdersSchema = (
  setSpotOrderId, 
  handleCancelOrder, 
  columnConfig,
  coinsPairs,
  coinPairsFilter,
  setCoinPairsFilter,
  spotDirectionFilter,
  setSpotDirectionFilter,
  amountRange,
  setAmountRange,
  maxValue,
  filters,
  setFilters,
) => {
  const spotOrders = [
    {
      name: (
        <ModalFilters
          data={coinsPairs}
          filters={coinPairsFilter}
          setFilters={setCoinPairsFilter}
          tabName="Spot Pairs" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row?.spotPair,
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Spot Pairs'),
    },
    {
      name: 'Order Type',
      selector: (row) => (row?.marketOrder === '1' ? 'Market' : 'Limit'),
      sortable: true,
      omit: omitColumn(columnConfig, 'Order Type'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Buy', _id: '1' }, { name: 'Sell', _id: '0' }]}
          filters={spotDirectionFilter}
          setFilters={setSpotDirectionFilter}
          tabName="Direction" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (row?.tradeType ? 'Buy' : 'Sell'),
      omit: omitColumn(columnConfig, 'Direction'),
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Order Value" 
          value={amountRange} 
          setValue={setAmountRange}
          filters={filters}
          setFilters={setFilters}
          filedName="amountRange"
          maxValue={maxValue()}
        />
      ),
      selector: (row) => `${parseFloat(parseFloat(row?.investedQty) * parseFloat(row?.tradeStartPrice)).toFixed(3)} USDT`,
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Order Value'),
    },
    {
      name: 'Order Qty',
      selector: (row) => `${parseFloat(row?.investedQty).toFixed(3)} ${row?.spotPair?.replace('USDT', '')}`,
      sortable: true,
      omit: omitColumn(columnConfig, 'Order QTY'),
    },
    {
      name: 'Order Price',
      selector: (row) => `${row?.tradeStartPrice} USDT`,
      sortable: true,
      omit: omitColumn(columnConfig, 'Order Price'),
    },
    {
      name: 'Commission %',
      selector: ({ commission }) => `${toFixed(commission, 2) ?? 0} %`,
      sortable: true,
      omit: omitColumn(columnConfig, 'Commission'),
    },
    {
      name: 'Swap',
      selector: ({ endSwap }) => toFixed(endSwap, 2),
      sortable: true,
      omit: omitColumn(columnConfig, 'Swap'),
    },
    {
      name: 'Unfilled Qty',
      selector: (row) => `${parseFloat(row?.investedQty).toFixed(3)} ${row?.spotPair?.replace('USDT', '')}`,
      sortable: true,
      omit: omitColumn(columnConfig, 'Unfilled QTY'),
    },
    {
      name: 'Order Time',
      selector: (row) => moment(row.initiatedAt || row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sortable: true,
      omit: omitColumn(columnConfig, 'Order Time'),
    },
    {
      name: 'Order ID',
      selector: (row) => (
        <CopyToClipboard text={row?._id}>
          <span>
            {row?._id?.slice(0, 4)}
            ...
            {row?._id?.slice((row?._id?.length || 0) - 4, row?._id?.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => toast.success('Successfully copied!')} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'Order ID'),
    },
    {
      name: 'Action',
      selector: (row) => (
        <span>
          <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => { setSpotOrderId(row?._id); handleCancelOrder(row?._id); }}>Cancel</button>
        </span>
      ),
      omit: omitColumn(columnConfig, 'Action'),
    },
  ];
  return spotOrders;
};
