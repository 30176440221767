import { toast } from 'react-toastify';
import {
  GET_USER_PSP, GET_PSP_OPTIONS, CREATE_USER_PSP, DELETE_USER_PSP, UPDATE_USER_PSP, SET_IS_DATA_LOADING,
} from './usersPSPsTypes';
import { apiHelper } from '../apiHelper';

const setUsersPspsIsLoadingCreator = (status) => ({
  type: SET_IS_DATA_LOADING,
  payload: status,
});

export const getPSPOptions = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/user-psp/options');

    if (res && res.data && res.data.success) {
      const { options } = res.data;

      dispatch({
        type: GET_PSP_OPTIONS,
        payload: options,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getUserPsps = (userId) => async (dispatch) => {
  try {
    dispatch(setUsersPspsIsLoadingCreator(true));
    const res = await apiHelper('get', `/api/user-psp/query?userId=${userId}`);

    if (res && res.data && res.data.success) {
      const { psps } = res.data;

      dispatch({
        type: GET_USER_PSP,
        payload: psps,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch(setUsersPspsIsLoadingCreator(false));
  }
};

export const createUserPsp = (data) => async (dispatch) => {
  try {
    dispatch(setUsersPspsIsLoadingCreator(true));
    const res = await apiHelper('post', '/api/user-psp', data);

    if (res && res.data && res.data.success) {
      const { psp } = res.data;

      dispatch({
        type: CREATE_USER_PSP,
        payload: psp,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch(setUsersPspsIsLoadingCreator(false));
  }
};

export const updateUserPsp = (id, data) => async (dispatch) => {
  const res = await apiHelper('patch', `/api/user-psp/${id}`, data);

  if (res && res.data && res.data.success) {
    const { psp } = res.data;

    dispatch({
      type: UPDATE_USER_PSP,
      payload: psp,
    });
  }
};

export const deleteUserPsp = (id) => async (dispatch) => {
  try {
    dispatch(setUsersPspsIsLoadingCreator(true));
    const res = await apiHelper('delete', `/api/user-psp/${id}`);

    if (res && res.data && res.data.success) {
      const { psp } = res.data;

      dispatch({
        type: DELETE_USER_PSP,
        payload: psp,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch(setUsersPspsIsLoadingCreator(false));
  }
};
