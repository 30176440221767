import React, {
  useState, useEffect, useRef,
} from 'react';
import DataTable from 'react-data-table-component';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { io } from 'socket.io-client';
import {
  getOpenOrdersByQuery,
  updateLeverageOrder,
  stopLeverageOrder,
  updateOrderState,
  getOpenOrdersPairNames,
  exportFilteredDataToFile,
} from '../../redux/leverageOrder/leverageOrderActions';
import { EntryPriceModal } from './EntryPriceModal';
import { TpSLModal } from './TpSLModal';
import { TrailingStopModal } from './TrailingStopModal';
import { LimitCloseModal } from './LimitCloseModal';
import { MarketModal } from './MarketModal';
import { StopTradeModal } from './StopTradeModal';
import { OpenOrdersSchema } from '../../DataTableSchemas/OpenOrdersSchema/OpenOrdersSchema';
import { openOrdersColumns } from '../../columnsDefaultConfig';
import { DatatableColumns, DatatableFilters, FutureOrderEditModal } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { DownloadFile } from '../../components/DownloadFile';
import { addShiftToPrice, toFixed } from '../../helpers/helper';
import { useDebounce } from '../../hooks/useDebounce';
import { TradeType } from '../../types/order';
import { calculateUnrealizedPnL, calculatePositionMargin } from '../../helpers/leverageOrder';

let localOrderBook = [];
let futures = [];
let futuresCounter = 0;

const stopped = [];
const started = [];

function OpenOrders() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState('');
  const [futureStartPrice, setFutureStartPrice] = useState(0);
  const [showFutureStartPrice, setShowFutureStartPrice] = useState(false);
  const [coinAmount, setCoinAmount] = useState(0);
  const [orderRate, setOrderRate] = useState(0);
  const [stopRate, setStopRate] = useState(0);
  const [, setOrderId] = useState('');
  const [futureOrderId, setFutureOrderId] = useState('');
  const [avbl] = useState(0);
  const [TP, setTP] = useState(0);
  const [SL, setSL] = useState(0);
  const [trailingPrice, setTrailingPrice] = useState(0);
  const [rate, setRate] = useState(0);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [editModalData, setEditModalData] = useState();

  /**
   * socket states
   */
  const [, setOrderBookAPI] = useState([]);
  const [, setFutureTradesAPI] = useState([]);
  const [pairName] = useState('ETHUSDT');
  const [socket, setSocket] = useState(null);
  const [currentMarketPrice, setcurrentMarketPrice] = useState(0);
  const [startSocket, setStartSocket] = useState(true);
  const [rates, setRates] = useState('');
  const [showTPSL, setShowTPSL] = useState(false);
  const [showTrailing, setShowTrailing] = useState(false);
  const [showMarket, setShowMarket] = useState(false);
  const [showFuture, setShowFuture] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [percentage, setPercentage] = useState(`${0}%`);
  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const filtersStorageName = 'DataTable_orders/open-orders_filters';
  const filterIdStorageName = 'DataTable_orders/open-orders_filter_id'; 
  const columnsStorageName = 'DataTable_orders/open-orders_columns';
  const paginationStorageName = 'DataTable_users/open-orders_pagination';
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  
  const datatableFiltersRef = useRef(null);

  const [columnConfig, setColumnConfig] = useState(openOrdersColumns);
  const [openOredersFilters, setOpenOredsFilters] = useState({});
  const [coinPairsFilter, setCoinPairsFilter] = useState([]);
  const [amountRangeFilter, setAmountRangeFilter] = useState(null);
  const [userNameFilter, setUserNameFilter] = useState();
  const [orderIdFilter, setOrderIdFilter] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [inputFieldError, setInputFieldError] = useState({
    orderIdIsNotValid: false,
    userNameIsNotValid: false,
    errorMessage: '',
  });

  const handleShowFutureStartPrice = () => setShowFutureStartPrice(true);
  const handleShowTPSL = () => setShowTPSL(true);
  const handleCloseTPSL = () => setShowTPSL(false);
  const handleShowTrailing = () => setShowTrailing(true);
  const handleCloseTrailing = () => setShowTrailing(false);
  const handleShowMarket = () => setShowMarket(true);
  const handleShowFuture = () => setShowFuture(true);
  const handleShowLimit = () => setShowLimit(true);
  const handleCloseLimit = () => setShowLimit(false);
  const handleCloseFutureStartPrice = () => setShowFutureStartPrice(false);
  const handleCloseMarket = () => setShowMarket(false);
  const handleCloseFuture = () => setShowFuture(false);
  
  const { paginatedData: openOrdersData, totalCount } = useSelector((state) => state.LeverageOrders?.allActiveOrders);
  const { pairNames } = useSelector((state) => state.LeverageOrders?.pairNames);
  const exportFilterdDataToFile = useSelector((state) => state.LeverageOrders.exportFilterdDataToFile);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  
  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && openOredersFilters[key] !== value) {
      setOpenOredsFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(orderIdFilter, 1000, (value) => debounceCallback({ value, key: 'orderId' }));
  useDebounce(userNameFilter, 1000, (value) => debounceCallback({ value, key: 'fullName' }));
  useDebounce(amountRangeFilter, 1000, (value) => debounceCallback({ value, key: 'amountRange' }));

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setStoredPagination = () => {
    const openOrdersRowsJSON = localStorage.getItem(paginationStorageName);
    if (openOrdersRowsJSON) {
      const filterRows = JSON.parse(openOrdersRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const setDebounceAmountRange = (type, value, amountRangeValue) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setAmountRangeFilter([Number(value), maxValue]);
      }
      if (type === 'max') {
        setAmountRangeFilter([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setAmountRangeFilter(value);
      }
    }
  };

  const openEditModal = (row) => {
    setEditModalData(row);
    setIsEditModalShown(true);
  };

  const closeEditModal = () => {
    setEditModalData();
    setIsEditModalShown(false);
  };

  const setStoredFilterData = () => {
    if (filtersJSON) { 
      const filters = JSON.parse(filtersJSON);
      setOpenOredsFilters(filters || {});

      setAmountRangeFilter(filters.amountRange || null);
      setCoinPairsFilter(filters.contracts || []);
      setUserNameFilter(filters.fullName || null);
      setOrderIdFilter(filters.orderId || null);
    } 
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
  };

  const handleClear = () => {
    setOrderIdFilter(null);
    setAmountRangeFilter(null);
    setCoinPairsFilter([]);
    setUserNameFilter(null);
    setOpenOredsFilters({});
    setPage(1);
    setRowsPerPage(25);
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setCRMFilters = (filter) => {
    const {
      currency, amountRange, fullName, uid,
    } = filter;
    const setObject = {
      contracts: currency,
      fullName,
      amountRange,
      orderId: uid,
    };
    
    setOrderIdFilter(uid || null);
    setAmountRangeFilter(amountRange || null);
    setCoinPairsFilter(currency || null);
    setUserNameFilter(fullName || null);
    setOpenOredsFilters(setObject);
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname,
      currency: filters.contracts || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
      fullName: filters.fullName || '',
      uid: filters.orderId || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters.contracts || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
        fullName: filters.fullName || '',
        uid: filters.orderId || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const socketURL = process.env.REACT_APP_SOCKET_URL;

  const getLiquidationPrice = (data) => {
    let side = 0;
    if (data.tradeType === 0) {
      side = -1;
    } else {
      side = 1;
    }
    let wb = 0;
    let tmm = 0;
    let UNPL = 0;
    if (data.marginType === 0 && openOrdersData.length) {
      // cross
      wb = parseFloat(avbl) + parseFloat(data.userInvestedAmount);
      // const otherOrders = await LeverageOrder.find({ userId: data.userId, status: "1" });
      openOrdersData?.filter((row) => row.futuresOrder === 1 && row._id !== data._id)
        ?.filter((row) => row.status === 1)
        ?.forEach((ordr) => {
          // tmm
          if (ordr.maintenanceMargin) {
            tmm += (parseFloat(ordr.qty)
                * parseFloat(
                  currentMarketPrice
                    ? currentMarketPrice?.find((line) => line.symbol === ordr.pairName).markPrice
                    : 0,
                )
                * (parseFloat(ordr.maintenanceMargin) / 100)
                - parseFloat(ordr.maintenanceAmount));
          }
          // UNPL
          UNPL += parseFloat(document.getElementById(`${ordr?._id}pnl`)?.innerText);
        });
    } else {
      // isolated
      wb = parseFloat(data.userInvestedAmount);
    }
    const liqPrice = (wb - tmm + UNPL + data.maintenanceAmount - side * data.qty * data.tradeStartPrice)
      / (data.qty * (data.maintenanceMargin / 100) - side * data.qty);
    return liqPrice || 0;
  };

  const getPositionMargin = (row) => calculatePositionMargin(row);

  const getUnrealizedPnL = (row) => {
    const pnLResult = {
      value: 0,
      percentage: 0,
      roe: 0,
    };

    if (!row) return pnLResult;

    const marketPrice = currentMarketPrice ? currentMarketPrice?.find((line) => line.symbol === row.pairName)?.markPrice : 0;

    if (!marketPrice) return pnLResult;

    let rowPnLRate = 0;

    if (row.user.settings && row.user.settings.length) {
      const setting = row.user.settings.find((s) => s.pairName === row.pairName);
      rowPnLRate = setting ? addShiftToPrice(marketPrice, setting) : parseFloat(marketPrice || 0);
    } else {
      rowPnLRate = parseFloat(marketPrice || 0);
    }

    return calculateUnrealizedPnL(row, rowPnLRate);
  };

  const updateFutureTradeStartprice = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeStartPrice) !== parseFloat(futureStartPrice)) {
      data.tradeStartPrice = parseFloat(futureStartPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, data.userId));
      // setLoader(true);
    }

    handleCloseFutureStartPrice();
  };

  const updateTPSL = () => {
    const data = { ...selectedRow };

    if (
      parseFloat(data.takeProfitPrice) !== parseFloat(TP)
      || parseFloat(data.stopLossPrice) !== parseFloat(SL)
    ) {
      data.tpsl = true;
      data.takeProfitPrice = parseFloat(TP);
      data.stopLossPrice = parseFloat(SL);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }
    handleCloseTPSL();
  };

  const updateTrailing = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeTrailingPrice) !== parseFloat(trailingPrice)) {
      data.tradeTrailingPrice = parseFloat(trailingPrice);
      data.tradeTrailingDifference = parseFloat(trailingPrice) > parseFloat(data.tradeStartPrice)
        ? parseFloat(trailingPrice) - parseFloat(data.tradeStartPrice)
        : parseFloat(data.tradeStartPrice) - parseFloat(trailingPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }

    handleCloseTrailing();
  };

  const handleLimit = (val, update = 0) => {
    if (avbl) {
      const validNumber = (/^\d*\.?\d*$/);
      if (!val.toString().match(validNumber) || parseFloat(val) > parseFloat(avbl) + update) {
        Swal.fire({
          text: 'Invalid number entered. Please enter a valid number',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
        setCoinAmount(0);
      }
    }
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  function rangeValue(value) {
    const val = value;
    setPercentage(`${val}%`);
    if (avbl) {
      setCoinAmount(avbl * (val / 100));
    }
  }

  function wait(ms) {
    const start = new Date().getTime();
    let end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  const handleFutureStop = async () => {
    setLoader(true);
    await dispatch(stopLeverageOrder(futureOrderId, parseFloat(stopRate), false));
    setShowFuture(false);
    setFutureOrderId('');
    setStopRate(0);
    setLoader(false);
  };


  const updateMarket = async () => {
    const data = { ...selectedRow };
    data.tpsl = true;
    data.tradeStartPrice = parseFloat(orderRate);
    data.userInvestedAmount = data.tradeType === 1 ? parseFloat(coinAmount) * parseFloat(orderRate) : parseFloat(coinAmount);
    data.qty = parseFloat(coinAmount) * parseFloat(data.leverage);
    data.fromCurrency = data.fromCurrency._id;
    data.toCurrency = data.toCurrency._id;
    await dispatch(updateLeverageOrder(data, id));
    handleCloseMarket();
    handleCloseLimit();
    // setLoader(true);
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
    dispatch(getOpenOrdersPairNames());
  }, []);

  useEffect(() => {
    if (pairName) {
      if (socket != null) {
        setStartSocket(false);
        socket.disconnect();
        setSocket(io(socketURL, { transports: ['websocket'] }));
        setStartSocket(true);
        localOrderBook = [];
        futures = [];
        futuresCounter = 0;
        setcurrentMarketPrice(0);
        setFutureTradesAPI([]);
        setOrderBookAPI([]);
      }
    }
  }, [pairName]);

  useEffect(() => {
    if (pairName && startSocket) {
      if (socket == null) {
        setSocket(io(socketURL, { transports: ['websocket'] }));
      }
      let letCurrentMarketPrice;
      if (socket) {
        socket.on('connect', () => {
          // console.log('Socket id is -->: ', socket.id);
        });

        socket.emit('getBinanceFutureTradesRequest', { pairName });
        socket.on('getBinanceFutureTradesRequestError', () => {
          // console.log(response)
        });
        socket.on(`getBinanceFutureTradesRequestResponse${pairName}`, (response) => {
          // console.log("futures trade", response)
          futuresCounter += 1;
          if (futuresCounter % 20 === 0) {
            futures.unshift(response);
            if (futures.length > 20) futures = futures.splice(0, 20);
            setFutureTradesAPI(futures);
            futuresCounter = 0;
          }
        });

        socket.emit('getCurrentMarketPriceRequest', { pairName });
        socket.on(`getCurrentMarketPriceResponse${pairName}`, (currentMarketPriceRes) => {
          letCurrentMarketPrice = currentMarketPriceRes;
          setcurrentMarketPrice(currentMarketPriceRes);
        });

        socket.emit('getBinanceMarketDepthRequest', { pairName });
        socket.on('getBinanceMarketDepthRequestError', () => {
          // console.log(response)
        });

        socket.on(`getBinanceMarketDepthRequest${pairName}`, (response) => {
          // if (orderBookCounter % 20 == 0) {
          let data;
          if (Object.keys(localOrderBook).length && localOrderBook.a?.length && localOrderBook.b?.length) {
            data = {
              a: response.a
                ?.filter((row) => parseFloat(row['1']) > 0)
                .concat(localOrderBook?.a)
                .splice(0, 15),
              b: response.b
                ?.filter((row) => parseFloat(row['1']) > 0)
                .concat(localOrderBook?.b)
                .splice(0, 15),
            };
            localOrderBook = data;
            setOrderBookAPI(data);
          } else {
            data = {
              a: [...response.a.filter((row) => parseFloat(row['1']) > 0)],
              b: [...response.b.filter((row) => parseFloat(row['1']) > 0)],
            };
            localOrderBook = data;
            setOrderBookAPI(data);
          }

          // orderBookAPI && orderBookAPI.b && orderBookAPI.b.length ? currentMarketPrice : 0
          setRates(data && data.b && data.b.length ? letCurrentMarketPrice : 0);
          // }
          // orderBookCounter++;
        });
      }
    }

    return () => {
      if (socket) {
        socket.off('getBinanceFutureTradesRequestError');
        socket.off(`getBinanceFutureTradesRequestResponse${pairName}`);
        socket.off(`getCurrentMarketPriceResponse${pairName}`);
        socket.off('getBinanceMarketDepthRequestError');
        socket.off(`getBinanceMarketDepthRequest${pairName}`);
      }
    };
  }, [socket, pairName]);

  useEffect(() => {
    let PnL = 0;

    if (openOrdersData?.length > 0) {
      openOrdersData
        ?.filter((row) => row.futuresOrder === 1 && row.marginType === 0 && row.status === 1)
        .forEach((ordr) => {
        // PnL
          PnL += parseFloat(document.getElementById(`${ordr?._id}pnl`)?.innerText);
        });
    }

    if (PnL) {
      // const vall = avbl + PnL;
      // updateAvbl(vall > 0 ? vall : 0)
    }
  }, [currentMarketPrice]);

  const refresh = async () => {
    setTableLoading(true);
    await getAllStoredData();
    setTableLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(openOredersFilters));
        setTableLoading(true);
  
        await dispatch(getOpenOrdersByQuery({ page, limit: rowsPerPage, query: openOredersFilters }));
  
        setTableLoading(false);
      }
    }
    
    fetchData();
  }, [page, rowsPerPage, isPaginationDT, openOredersFilters]);

  const handleExportOrders = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (openOrdersData.length && columns.length) {
      const temproaryData = structuredClone(openOrdersData);

      // eslint-disable-next-line no-restricted-syntax
      for (const el of temproaryData) {
        const PnL = getUnrealizedPnL(el);

        const firstPnLPart = `${parseFloat(PnL.value).toFixed(4)}${el.fromCurrency?.symbol}`;
        const secondPnLPart = `${parseFloat(PnL.percentage).toFixed(4)}`;
        const thirdPnlPart = `${parseFloat(PnL.value).toFixed(2)}USD`;
        const strPnl = `${firstPnLPart} ${secondPnLPart} ${thirdPnlPart}`;

        el.unrealizedPNL = strPnl;
        el.liqPrice = `${toFixed(el.tradeEndPrice, 2)} ${el.fromCurrency?.symbol}`;
        el.positionMargin = getPositionMargin(el);
      }

      setLoader(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await dispatch(exportFilteredDataToFile(temproaryData, columns, fileType)); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateOrderState()); 
      setLoader(false);
    }
  }, [exportFilterdDataToFile]);

  const columns = OpenOrdersSchema(
    setSelectedRow,
    setFutureStartPrice,
    handleShowFutureStartPrice,
    getLiquidationPrice,
    getPositionMargin,
    getUnrealizedPnL,
    handleShowTPSL,
    setTP,
    setSL,
    handleShowTrailing,
    setTrailingPrice,
    setCoinAmount,
    setOrderRate,
    handleShowLimit,
    handleShowMarket,
    setStopRate,
    rates,
    setOrderId,
    setFutureOrderId,
    handleShowFuture,
    columnConfig,
    pairNames,
    setCoinPairsFilter,
    amountRangeFilter,
    setAmountRangeFilter,
    setDebounceAmountRange,
    userNameFilter,
    setUserNameFilter,
    openOredersFilters,
    setOpenOredsFilters,
    permissionName,
    openEditModal,
    orderIdFilter, 
    setOrderIdFilter,
    coinPairsFilter,
    inputFieldError, 
    setInputFieldError,
  );

  return (
    loader ? <FullPageTransparentLoader /> : (
      <>
        <div className="content-wrapper right-content-wrapper open-orders">
          <div className="content-box">
            <h4>Open Orders</h4>
            <div className="action__btn-row">
              {
                crmFilters && (
                  <DatatableFilters
                    ref={datatableFiltersRef}
                    filters={crmFilters}
                    setFilters={setCRMFilters}
                    createFilter={createUserCRMFilter}
                    deleteFilter={deleteUserCRMFilter}
                    updateFilter={updateUserCRMFilter}
                    storageKey={filterIdStorageName}
                    pathname={pathname}
                  />
                )
              }
              <DownloadFile handleExport={handleExportOrders} />
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
              <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
                <FontAwesomeIcon icon={faTimes} size="sm" />
                Clear
              </button>
              <button type="button" className="btn btn-default icon-btn ms-1" onClick={refresh}>
                <FontAwesomeIcon icon={faRefresh} size="sm" />
                Refresh
              </button>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
              {
                isPaginationDT
                  && (
                    <DataTable
                      columns={columns}
                      data={openOrdersData}
                      fixedHeader
                      pagination
                      paginationServer
                      paginationPerPage={rowsPerPage}
                      paginationTotalRows={totalCount}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      paginationDefaultPage={page}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      persistTableHead
                      highlightOnHover
                      theme="solarizedd"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  ) 
              }
            </div>
          </div>
        </div>

        <FutureOrderEditModal 
          isShown={isEditModalShown} 
          hideModal={closeEditModal}
          data={editModalData}
          currentPrice={currentMarketPrice} 
        />

        <EntryPriceModal
          showFutureStartPrice={showFutureStartPrice}
          handleCloseFutureStartPrice={handleCloseFutureStartPrice}
          selectedRow={selectedRow}
          futureStartPrice={futureStartPrice}
          setFutureStartPrice={setFutureStartPrice}
          updateFutureTradeStartprice={updateFutureTradeStartprice}
        />

        <TpSLModal
          showTPSL={showTPSL}
          handleCloseTPSL={handleCloseTPSL}
          selectedRow={selectedRow}
          TP={TP}
          setTP={setTP}
          SL={SL}
          setSL={setSL}
          updateTPSL={updateTPSL}
        />

        <TrailingStopModal
          showTrailing={showTrailing}
          handleCloseTrailing={handleCloseTrailing}
          selectedRow={selectedRow}
          trailingPrice={trailingPrice}
          setTrailingPrice={setTrailingPrice}
          updateTrailing={updateTrailing}
        />

        <LimitCloseModal
          showLimit={showLimit}
          handleCloseLimit={handleCloseLimit}
          selectedRow={selectedRow}
          orderRate={orderRate}
          setOrderRate={setOrderRate}
          avbl={avbl}
          coinAmount={coinAmount}
          setRate={setRate}
          rate={rate}
          setCoinAmount={setCoinAmount}
          handleLimit={handleLimit}
          percentage={percentage}
          rangeValue={() => rangeValue}
          updateMarket={updateMarket}
        />

        <MarketModal
          showMarket={showMarket}
          handleCloseMarket={handleCloseMarket}
          selectedRow={selectedRow}
          avbl={avbl}
          coinAmount={coinAmount}
          setCoinAmount={setCoinAmount}
          handleLimit={handleLimit}
          updateMarket={updateMarket}
        />

        <StopTradeModal
          showFuture={showFuture}
          handleCloseFuture={handleCloseFuture}
          stopRate={stopRate}
          handleFutureStop={handleFutureStop}
        />
      </>
    ));
}

export default OpenOrders;
