export const depositColumns = [
  {
    name: 'Transaction ID',
    field: '_id',
    selected: true,
  },
  {
    name: 'Client ID',
    field: 'userId',
    selected: true,
  },
  {
    name: 'Client',
    field: 'user.firstName,user.lastName',
    selected: true,
  },
  {
    name: 'Email',
    field: 'user.email',
    selected: true,
  },
  {
    name: 'Assigned To',
    field: 'user.assignedTo.firstName,user.assignedTo.lastName',
    selected: true,
  },
  {
    name: 'Amount',
    field: 'amount',
    selected: true,
  },
  {
    name: 'Currency',
    field: 'currency',
    selected: true,
  },
  {
    name: 'In USD',
    field: 'amountInUSD',
    selected: true,
  },
  {
    name: 'Status',
    field: 'status',
    selected: true,
  },
  {
    name: 'Time Initiated',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Transaction Time',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Real/Fake',
    field: 'isReal',
    selected: true,
  },
  {
    name: 'Deposit Type',
    field: 'balanceType',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
