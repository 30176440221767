import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Switch from '@mui/material/Switch';
import { ModalContext } from '../../../../context';
import { getUserPsps, deleteUserPsp, updateUserPsp } from '../../../../redux/usersPSPs/usersPSPsActions';
import CreatePSPModal from './CreatePSPModal';
import { CopyToClipboardCmp } from '../../../../components';

export function PSP() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const { id } = useParams();
  const { psps: userPsps, isLoading } = useSelector((state) => state.usersPsps);
  
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const deletePSP = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteUserPsp(id));
        }
      });
  };

  useEffect(() => {
    if (id) dispatch(getUserPsps(id));
  }, [id]);

  const setColumnName = (pspId, type) => {
    const name = pspId.name ?? pspId.address ?? pspId.label;

    if (type === 'wallet') {
      const isBitcoinXpubWallet = name.startsWith('xpub');

      return (
        <div>
          {
            !isBitcoinXpubWallet ? (
              <CopyToClipboardCmp label={name} isNeedToCut />
            ) : 'Bitcoin Xpub Wallet'
          }
        </div>
      );
    }

    return name;
  };

  const columns = [
    {
      name: 'Type',
      cell: ({ type }) => String(type ?? '').toUpperCase(),
    },
    {
      name: 'Name/Address',
      cell: ({ pspId = {}, type }) => setColumnName(pspId, type),
    },
    {
      name: 'Enabled',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ isEnabled, _id }) => (
        <div key={_id}>
          <Switch
            checked={isEnabled}
            color="success"
            onChange={() => {
              dispatch(updateUserPsp(_id, { isEnabled: !isEnabled }));
            }}
          />
        </div>
      ),
    },
    {
      name: 'Actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ _id }) => (
        <div>
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deletePSP(_id)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <button
        type="button"
        className="btn btn-default icon-btn btn-bg-green"
        onClick={() => showModal({
          headerContent: <h3>Add PSP</h3>,
          bodyContent: <CreatePSPModal closeModal={hideModal} userId={id} />,
        })}
      >
        <FontAwesomeIcon
          icon={faPlus}
          size="sm"
        />
        Add
      </button>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          columns={columns}
          data={userPsps}
          theme="solarizedd"
          fixedHeader
          pagination
          paginationRowsPerPageOptions={[5, 10, 15]}
          persistTableHead
          highlightOnHover
          progressPending={isLoading}
          progressComponent={<div className="datatable-loader__background" />}
        />
      </div>
    </div>
  );
}
