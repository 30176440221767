import React, { useState } from 'react';
import { columnsDefaultConfig } from '../../columnsDefaultConfig/usersCommissions';
import { UsersCommissionsDataTable } from '../../components/UsersCommissionsDataTable/UsersCommissionsDataTable';
import { getUsersCommissions } from '../../redux/UsersCommissions/usersCommissionsActions';

export function UsersCommissions() {
  const [loader, setLoader] = useState(false);

  const filtersStorageName = 'DataTable_users_commissions/filter';
  const paginationStorageName = 'DataTable_users_commissions/pagination';
  const filterIdStorageName = 'DataTable_users_commissions/filters_id';
  const columnsStorageName = 'DataTable_users_commissions/columns';

  const getDataCallBack = ({
    dispatch, page, limit, filter,
  }) => {
    dispatch(getUsersCommissions({ page, limit, query: filter }));
  };

  return (
    <UsersCommissionsDataTable
      filtersStorageName={filtersStorageName}
      paginationStorageName={paginationStorageName}
      filterIdStorageName={filterIdStorageName}
      columnsStorageName={columnsStorageName}
      setLoader={setLoader}
      loader={loader}
      getDataCallBack={getDataCallBack}
      columnsDefaultConfig={columnsDefaultConfig}
    />
  );
}
