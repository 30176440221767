
import * as actionTypes from './smtpProvidersTypes';

const initialState = {
  smtpProviders: [],
};

const smtpProvidersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SMTP_PROVIDERS:
      return {
        ...state,
        smtpProviders: action.payload,
      };
    default:
      return state;
  }
};

export default smtpProvidersReducer;
