import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { getTransactionsByQuery } from '../../../redux/transactions/transactionActions';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';
import { DepositDetailColumns } from '../../../DataTableSchemas/DepositDetailColumns';

function DepositDetails({ filter }) {
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false);

  const { paginatedData: transactionsData, totalCount } = useSelector((state) => state?.transaction?.allDeposits);

  const paginationStoredge = 'DataTable_dashboard/pagination'; 

  const combinedData = transactionsData !== undefined
    ? [...transactionsData]
    : [];

  const sortedTransactions = combinedData.sort((a, b) => {
    if (a.isResolved === 0 && b.isResolved !== 0) {
      return -1;
    } if (a.isResolved !== 0 && b.isResolved === 0) {
      return 1;
    }
    return (
      new Date(moment(b.createdAt).format('YYYY-MM-DD HH:mm:ss'))
        - new Date(moment(a.createdAt).format('YYYY-MM-DD HH:mm:ss'))
    );
  }).filter((item) => item.amount > 0);

  const subHeaderComponentMemo = React.useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
  }, [filterText, resetPaginationToggle]);

  const setStoredPagination = () => {
    const depositRowsJSON = localStorage.getItem(paginationStoredge);
    if (depositRowsJSON) {
      const filterRows = JSON.parse(depositRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
  };

  useEffect(async () => {
    setTableLoader(true);
    setStoredPagination();
    await dispatch(getTransactionsByQuery({
      page,
      limit: rowsPerPage,
      query: {
        period: filter?.period || [],
      },
      isDeleted: false, 
    }));
    setTableLoader(false);
  }, [page, rowsPerPage]);

  const handlePageChange = (currentPage) => {
    localStorage.setItem(paginationStoredge, JSON.stringify({ limit: rowsPerPage, page: currentPage }));
    setPage(currentPage);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    localStorage.setItem(paginationStoredge, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  useEffect(async () => {
    setTableLoader(true);
    handlePageChange(1);
    await dispatch(getTransactionsByQuery({
      page,
      limit: rowsPerPage,
      query: {
        period: filter?.period || [],
      },
      isDeleted: false,
    }));
    setTableLoader(false);
  }, [filter]);


  const depColumns = DepositDetailColumns(page, rowsPerPage);
  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        {sortedTransactions?.length && (
        <DataTable
          title="Deposits Detail"
          columns={depColumns}
          data={transactionsData}
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[25, 50, 100, 500]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          paginationDefaultPage={page}
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          highlightOnHover
          theme="solarizedd"
          progressPending={tableLoader}
          progressComponent={<div className="datatable-loader__background" />}
        />
        )}
      </div>
    )
  );
}

export default DepositDetails;
