import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import { getRole } from '../../../redux/roles/roleActions';

import './AssetInformation.css';
import { nonNegativeAmount } from '../../../helpers/helper';

const currencyFormatter = require('currency-formatter');

const getCreditPillByAmount = (creditAmount, availableCredits) => {
  if (Number(-creditAmount) > 0) {
    return (
      <div className="credit-pill">
        <p className="credit-label">Credit</p>
        <pre className="credit-details">
          Credit:
          {'\n'}
          {availableCredits.toFixed(2)}
          {' / '}
          {-creditAmount.toFixed(2)}
        </pre>
      </div>
    );
  }

  return null;
};

const getNewCreditPillByAmount = (creditAmount, availableCredits) => {
  if (Number(creditAmount) > 0) {
    return (
      <div className="credit-pill">
        <p className="credit-label">Credit</p>
        <pre className="credit-details">
          Credit:
          {'\n'}
          {availableCredits}
          {' / '}
          {creditAmount}
        </pre>
      </div>
    );
  }

  return null;
};

const getFormattedAmount = (selectedAmount) => {
  const balance = nonNegativeAmount(
    (selectedAmount?.amount ?? 0) + (selectedAmount?.credit_amount ?? 0),
  );
  const creditAmount = -(selectedAmount?.credit_amount ?? 0);
  const availableCreditAmount = (selectedAmount?.amount ?? 0) - balance;

  return {
    amount: balance.toFixed(2),
    creditAmount: creditAmount.toFixed(2),
    availableCreditAmount: availableCreditAmount.toFixed(2),
  };
};

function AssetInformation() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    amounts,
    previousTotalAmount,
    previousTotalCredit,
    totalAvailableCredit,
  } = useSelector((state) => state.users?.user?.account);

  const currencies = useSelector(
    (state) => state.currency?.currencies?.allCurrencies,
  );

  useEffect(() => {
    const fetchData = async () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const roleId = data?.roleId;
      dispatch(getRole(roleId));

      await dispatch(showAllCurrencies());
    };
    fetchData();
  }, [id]);

  return (
    <div>
      <h5> Account Balance </h5>
      <div className="row">
        <div className="form-group col-md-6 position-relative">
          <label className="control-label">Total Balance</label>
          <input
            type="text"
            className="form-control"
            value={currencyFormatter.format(
              nonNegativeAmount(previousTotalAmount),
              { code: 'USD' },
            )}
            disabled
          />
          {getCreditPillByAmount(
            previousTotalCredit ?? 0,
            totalAvailableCredit,
          )}
        </div>
        {amounts && amounts.length
          ? amounts.map((coin) => (currencies?.find((row) => row._id === coin.currencyId)?.name ? (
            <div
              key={coin._id}
              className="form-group col-md-6 position-relative"
            >
              <label className="control-label">
                {
                      currencies?.find((row) => row._id === coin.currencyId)
                        ?.name
                    }
                (
                {
                      currencies?.find((row) => row._id === coin.currencyId)
                        ?.symbol
                    }
                )
              </label>
              <input
                type="text"
                className="form-control"
                value={getFormattedAmount(coin).amount}
                disabled
              />
              {getNewCreditPillByAmount(
                getFormattedAmount(coin).creditAmount,
                getFormattedAmount(coin).availableCreditAmount,
              )}
            </div>
          ) : null))
          : 'Empty Wallet'}
      </div>
      <br />
    </div>
  );
}

export default AssetInformation;
