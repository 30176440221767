export const columnOrdersHistorySortMap = {
  User: 'user',
  Swap: 'swap',
  Commission: 'commission',
  Leverage: 'leverage',
  'Order Qty': 'orderQty',
  'Open Price': 'tradeStartPrice',
  'Order Volume': 'orderVolume',
  'Close Price': 'exitPrice',
  'Profit/Loss': 'profitLoss',
  'Start Time': 'startTime',
  'End Time': 'endTime',
};

export const columnPendingOrdersSortMap = {
  User: 'user',
  Swap: 'swap',
  Commission: 'commission',
  Leverage: 'leverage',
  'Order Qty': 'orderQty',
  'Entry Price': 'openPrice',
  'Order Volume': 'orderVolume',
  'Liquidation Price': 'liquidationPrice',
  'Position Margin': 'positionMargin',
  'Created Time': 'startTime',
};

export const allOrdersHistoryStatusData = [
  { _id: 0, name: 'Pending' },
  { _id: 1, name: 'Processing' },
  { _id: 2, name: 'Completed' },
  { _id: 3, name: 'Cancelled' },
  { _id: 4, name: 'Liquidated' },
];

export const historyStatusData = [
  { _id: 2, name: 'Completed' },
  { _id: 3, name: 'Cancelled' },
  { _id: 4, name: 'Liquidated' },
];

export const openStatusData = [
  { _id: 1, name: 'Processing' },
];

export const pendingStatusData = [
  { _id: 0, name: 'Pending' },
];

export const orderStatusType = {
  PENDING: 'pending',
  OPEN: 'open',
  HISTORY: 'history',
};
