import React, { useRef } from 'react';
import {
  faUndo, faTrash, faThumbsDown, faThumbsUp, faCircleInfo, faTimes, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickOutside } from '../hooks/useClickOutside';
import { BalanceType } from '../types/deposit';

// eslint-disable-next-line default-param-last
export const omitColumn = (columnConfig = [], columnName) => {
  let omit = false;

  columnConfig.forEach((column) => {
    if (column.name === columnName) {
      omit = !column.selected;
    }
  });

  return omit;
};

// eslint-disable-next-line default-param-last
export const omitColumnsNotFromConfig = (columnConfig = [], columnName) => {
  let omit = true;

  columnConfig.forEach((column) => {
    if (column.name === columnName) {
      omit = !column.selected;
    }
  });

  return omit;
};

export const cutId = (id) => (`${id.substring(0, 3)}...${id.substring(id.length - 3, id.length)}`);

const padTo2Digits = (num) => num.toString().padStart(2, '0');

export const formatDate = (date) => (
  `${[
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-')
  } ${
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')}`
);

export const setTransactionActionColumn = (
  row, 
  permissionName,
  resolveCurrentDepositTransaction,
  updateIsReal,
  handleRevertTransaction,
  handleDeleteTransaction,
  isDeleted,
  dispatch,
) => {
  const isUserCanRevertTransaction = permissionName.includes('revert_transaction') && Number(row.balanceType) !== BalanceType.CREDIT;
  const isUserCanDeleteTransaction = permissionName.includes('delete_transaction') && Number(row.balanceType) !== BalanceType.CREDIT;
  const isUserCanChangeTransactionType = permissionName.includes('change_transaction_type') && Number(row.balanceType) !== BalanceType.CREDIT;
  const isUserCanApprovePendingTransaction = permissionName.includes('approve_pending_transactions') && Number(row.balanceType) !== BalanceType.CREDIT;

  if (!row.isResolved) {
    return (
      <>
        {
          isUserCanChangeTransactionType ? (
            <button
              type="button"
              className={`btn ${row.isReal ? 'btn-danger' : 'btn-success'} btn-sm me-1 p-1`}
              onClick={() => dispatch(updateIsReal(row._id, !row.isReal))}
            >
              <FontAwesomeIcon icon={row.isReal ? faThumbsDown : faThumbsUp} className="header-icon" />
            </button>
          ) : null
        }
        {
          isUserCanApprovePendingTransaction ? (
            <>
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => resolveCurrentDepositTransaction(row._id, row.userId, 1)}
              >
                Accept
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => resolveCurrentDepositTransaction(row._id, row.userId, 2)}
              >
                Decline
              </button>
            </>
          ) : '-'
        }
      </>
    );
  }

  return (
    <>
      {
        (!isUserCanRevertTransaction && !isUserCanDeleteTransaction && !isUserCanChangeTransactionType) && (
          <span>-</span>
        )
      }
      {
        isUserCanChangeTransactionType && (
          <button
            type="button"
            className={`btn ${row.isReal ? 'btn-danger' : 'btn-success'} btn-sm me-1 p-1`}
            onClick={() => dispatch(updateIsReal(row._id, !row.isReal))}
          >
            <FontAwesomeIcon icon={row.isReal ? faThumbsDown : faThumbsUp} className="header-icon" />
          </button>
        )
      }
      {
        (isUserCanRevertTransaction && row.isResolved !== 2) && (
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => handleRevertTransaction(row._id, row.userId)}
          >
            <FontAwesomeIcon icon={faUndo} className="header-icon" />
          </button>
        )
      }
      {
        (isUserCanDeleteTransaction && !isDeleted) && (
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => handleDeleteTransaction(row._id)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        )
      }
    </>
  );
};

export const dateOptions = [
  {
    name: 'Today',
    date: new Date(),
    get start() { 
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()).toISOString();
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Yesterday',
    date: new Date(new Date().setDate(new Date().getDate() - 1)),
    get start() { 
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()).toISOString(); 
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'This Week',
    date: new Date(),
    get start() { 
      return new Date(this.date.setDate(this.date.getDate() - this.date.getDay())).toISOString(); 
    },
    get end() {
      return new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6)).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Past Week',
    date: new Date(),
    get start() { 
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7).toISOString(); 
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 1).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'This Month',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Past Month',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth() - 1, 1).toISOString();
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), 0).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Last 7 days',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7).toISOString();
    },
    get end() {
      return this.date;
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Last 30 days',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 30).toISOString();
    },
    get end() {
      return this.date;
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
];

export const timeInitiatedHelper = (item, filter) => {
  const createdTime = new Date(item.createdAt);
  let inDateRange = false;

  filter.forEach((time) => {
    if (createdTime >= new Date(time.split('||')[0]) && createdTime <= new Date(time.split('||')[1])) {
      inDateRange = true;
    }
  });

  return inDateRange;
};

export const checkIsJson = (data) => {
  try {
    JSON.parse(data);

    return true;
  } catch (error) {
    return false;
  }
};


export function AdditionalInfoModal({ setActiveInfo = () => {}, activeInfo = '', row = {} }) {
  const modalRef = useRef(null);

  const handleTransactionInfoModalToggle = (id) => {
    setActiveInfo(activeInfo !== id && activeInfo !== null ? id : '');
  };

  const info = row.additionalInfo && checkIsJson(row.additionalInfo) 
    ? JSON.parse(row.additionalInfo) 
    : { additionalInfo: row.additionalInfo };

  useClickOutside({ onClickOutside: () => setActiveInfo(null), ref: modalRef });

  return (
    <span>
      info 
      <FontAwesomeIcon
        icon={faCircleInfo}
        className="ms-2"
        size="lg"
        color={info ? '#6E7F95' : '#4a5668'}
        style={{ cursor: row.additionalInfo && info ? 'pointer' : 'auto' }}
        onClick={() => {
          if (row.additionalInfo) {
            handleTransactionInfoModalToggle(row._id); 
          }
        }}
      />
      {activeInfo === row._id && info && (
      <div className="withdrawsInfoModal" ref={modalRef}>
        {info && Object.entries(info).map(([key, value]) => (
          <div className="infoModalRecordWrapper">
            <span>{`${key}: `}</span>
            { value.length > 20 && <br /> }
            <b>{value}</b>
          </div>
        ))}
      </div>
      )}
    </span>    
  );
}
