export const selectIsSmtpPresetsLoading = (state) => state.smtpPresets.isLoading;
export const selectTotalCount = (state) => state.smtpPresets.totalCount;
export const selectSmtpPresets = (state) => state.smtpPresets.smtpPresets;
export const selectSmtpUserPresets = (state) => state.smtpPresets.userPresets;
export const selectSmtpSelectedUserPreset = (state) => state.smtpPresets.selectedUserPreset;
export const selectSmtpPresetById = (state, id) => state.smtpPresets.smtpPresets.find((preset) => preset.id === id);

export const selectSmtpNameSearchFilter = (state) => state.smtpPresets.searchFilters.name;
export const selectSmtpAgentSearchFilter = (state) => state.smtpPresets.searchFilters.agent;
export const selectSmtpProviderSearchFilter = (state) => state.smtpPresets.searchFilters.provider;
export const selectSmtpUsernameSearchFilter = (state) => state.smtpPresets.searchFilters.username;
export const selectSmtpPasswordSearchFilter = (state) => state.smtpPresets.searchFilters.password;
export const selectSmtpDescriptionSearchFilter = (state) => state.smtpPresets.searchFilters.description;
