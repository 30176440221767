import { toast } from 'react-toastify';
import {
  GET_USERS_COMMISSIONS,
  ADD_USERS_COMMISSIONS,
  UPDATE_USERS_COMMISSIONS,
  DELETE_USERS_COMMISSIONS,
  SET_IS_DATA_LOADING,
} from './usersCommissionsTypes';
import { apiHelper } from '../apiHelper';

const setUsersCommissionsIsLoadingCreator = (status) => ({
  type: SET_IS_DATA_LOADING,
  payload: status,
});

export const getUsersCommissions = (data) => async (dispatch) => {
  dispatch(setUsersCommissionsIsLoadingCreator(true));
  try {
    const {
      page,
      limit,
      query,
    } = data;

    let url = `/api/user-commissions?page=${page}&limit=${limit}`;

    if (query.pair && query.pair.length) {
      url += `&pairIds=${JSON.stringify(query.pair)}`;
    }

    if (query.fullName) {
      url += `&fullName=${query.fullName}`;
    }

    const response = await apiHelper('get', url);
    const {
      success,
      commissions,
      totalCount,
      message,
    } = response.data;

    if (success) {
      dispatch({
        type: GET_USERS_COMMISSIONS,
        payload: { commissions, totalCount },
      });
    } else {
      throw new Error(message);
    }
  } catch (error) {
    toast.error('Error occurred while fetching users commissions');
  } finally {
    dispatch(setUsersCommissionsIsLoadingCreator(false));
  }
};

export const getUsersCommissionsById = (id, data) => async (dispatch) => {
  dispatch(setUsersCommissionsIsLoadingCreator(true));
  try {
    const {
      page,
      limit,
      query,
    } = data;

    let url = `/api/user-commissions/${id}?page=${page}&limit=${limit}`;

    if (query.pair && query.pair.length) {
      url += `&pairIds=${JSON.stringify(query.pair)}`;
    }

    const response = await apiHelper('get', url);
    const {
      success,
      commissions,
      totalCount,
      message,
    } = response.data;

    if (success) {
      dispatch({
        type: GET_USERS_COMMISSIONS,
        payload: { commissions, totalCount },
      });
    } else {
      throw new Error(message);
    }
  } catch (error) {
    toast.error('Error occurred while fetching users commissions');
  } finally {
    dispatch(setUsersCommissionsIsLoadingCreator(false));
  }
};

export const updateUserCommissionById = (id, data) => async (dispatch) => {
  dispatch(setUsersCommissionsIsLoadingCreator(true));
  try {
    const { userId, commission, pairId } = data;
    if (!Number.isNaN(Number(commission)) && !String(commission).length) return toast.error("Commission can't be empty");

    const response = await apiHelper('patch', `/api/user-commissions/${id}`, { userId, commission, pairId });

    if (response && response.data && response.data.success) {
      const { message, updatedCommission } = response.data;
      dispatch({
        type: UPDATE_USERS_COMMISSIONS,
        payload: updatedCommission,
      });
      toast.success(message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    dispatch(setUsersCommissionsIsLoadingCreator(false));
  }
};

export const createUserCommission = (id, data) => async (dispatch) => {
  dispatch(setUsersCommissionsIsLoadingCreator(true));
  try {
    const { commission, pairId } = data;
    const response = await apiHelper('post', `/api/user-commissions/${id}`, { commission, pairId });

    if (response && response.data && response.data.success) {
      const { message, commission } = response.data;
      dispatch({
        type: ADD_USERS_COMMISSIONS,
        payload: commission,
      });
      toast.success(message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    dispatch(setUsersCommissionsIsLoadingCreator(false));
  }
};

export const deleteUserCommissionById = (id) => async (dispatch) => {
  dispatch(setUsersCommissionsIsLoadingCreator(true));
  try {
    const response = await apiHelper('delete', `/api/user-commissions/${id}`);
    if (response && response.data && response.data.success) {
      const { message, commission } = response.data;

      toast.success(message);
      dispatch({
        type: DELETE_USERS_COMMISSIONS,
        payload: commission._id,
      });
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    dispatch(setUsersCommissionsIsLoadingCreator(false));
  }
};
