import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { ComposedOrdersSchema } from '../../DataTableSchemas/ComposedOrdersSchema/ComposedOrdersSchema';
import { allOrdersHistoryColumnsConfig } from '../../columnsDefaultConfig/allOrdersHistoryColumns';
import { DatatableColumns, DatatableFilters } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { revertLeverageOrder } from '../../redux/leverageOrder/leverageOrderActions';
import {
  fetchOrdersHistory,
  fetchPairNames,
} from '../../redux/ordershistory/ordersHistoryActions';
import { columnOrdersHistorySortMap, historyStatusData, orderStatusType } from '../../types/ordersHistory';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function ComposedOrdersHistory() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const innerPathname = `${pathname}/composed-orders-history`;

  const defaultFilters = { statusType: orderStatusType.HISTORY };

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [columnConfig, setColumnConfig] = useState(allOrdersHistoryColumnsConfig);
  const [sortItemAndDirection, setSortItemAndDirection] = useState({});

  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [limit, setLimit] = useState(25);
  const [filters, setFilters] = useState(defaultFilters);
  const [page, setPage] = useState(1);

  const [tradingTypeFilter, setTradingTypeFilter] = useState([]);
  const [openedByFilter, setOpenedByFilter] = useState([]);
  const [tradingPairFilter, setTradingPairFilter] = useState([]);
  const [directionFilter, setDirectionFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const permissionNames = useSelector((state) => state.crmUser?.currentUserPermissions || []);

  const {
    ordersHistory, totalCount, pairNames,
  } = useSelector((state) => state?.ordersHistoryReducer);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters.filter((filter) => filter.pathname === innerPathname));

  const datatableFiltersRef = useRef(null);

  const columnsStorageName = 'DataTable_all_orders/order-history_columns';
  const filtersStorageName = 'DataTable_all_orders/order-history_filters';
  const filterIdStorageName = 'DataTable_all_orders/order-history_filter_id';
  const paginationStorageName = 'DataTable_all_orders/order-history_pagination';

  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const paginationJSON = localStorage.getItem(paginationStorageName);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleClear = () => {
    setTradingTypeFilter([]);
    setOpenedByFilter([]);
    setTradingPairFilter([]);
    setDirectionFilter([]);
    setStatusFilter([]);
    setFilters(defaultFilters);
    setPage(1);
    setLimit(25);
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(columnsStorageName);
    localStorage.removeItem(filterIdStorageName);
    localStorage.removeItem(paginationStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters || defaultFilters);

      setTradingTypeFilter(filters['trading type'] || []);
      setOpenedByFilter(filters['opened by'] || []);
      setTradingPairFilter(filters['trading pair'] || []);
      setDirectionFilter(filters.direction || []);
      setStatusFilter(filters.status || []);
    } 
  };

  const setStoredPagination = () => {
    if (paginationJSON) {
      const filterRows = paginationJSON ? JSON.parse(paginationJSON) : {};

      setLimit(filterRows.limit ?? 25);
      setPage(filterRows.page ?? 1);
    } else {
      localStorage.setItem(paginationStorageName, JSON.stringify({ page, limit }));
    }
    setIsPaginationDT(true);
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit, page: currentPage }));
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setLimit(currentRowsPerPage);
    setPage(currentPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page: currentPage }));
  };

  const handleSort = (column, sortDirection) => {
    const direction = sortDirection === 'asc' ? 1 : -1;
    const sortKey = columnOrdersHistorySortMap[column.name];

    setSortItemAndDirection({
      [sortKey]: direction,
    });
  };

  const handleRevertTradeHistory = async (e, order) => {
    e.preventDefault();
    if (order.tradingType === 'Futures') {
      Swal.fire({
        title: 'Are you sure you want to Revert the order?',
        html: '',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(revertLeverageOrder(order._id, order.userId));
        }
      });
    }
  };

  const setCRMFilters = (filter) => {
    const {
      currency,
      isUSDRange,
      assignedTo,
      convertedCurrency,
      isReal,
    } = filter;

    const newFilters = {
      'trading pair': currency,
      status: isUSDRange,
      direction: assignedTo,
      'trading type': convertedCurrency,
      'opened by': isReal,
      ...defaultFilters,
    };  

    setFilters(newFilters);
    setTradingTypeFilter(convertedCurrency || []);
    setOpenedByFilter(isReal || []);
    setTradingPairFilter(currency || []);
    setDirectionFilter(assignedTo || []);
    setStatusFilter(isUSDRange || []);

    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
 
    const data = {
      name,
      crmUserId,
      pathname: innerPathname,
      currency: filters['trading pair'] || [],
      isUSDRange: filters.status || [],
      assignedTo: filters.direction || [],
      convertedCurrency: filters['trading type'] || [],
      isReal: filters['opened by'] || [],
    };
    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters['trading pair'] || [],
        isUSDRange: filters.status || [],
        assignedTo: filters.direction || [],
        convertedCurrency: filters['trading type'] || [],
        isReal: filters['opened by'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
    dispatch(fetchPairNames());
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (!isPaginationDT) return;
      localStorage.setItem(filtersStorageName, JSON.stringify(filters));
  
      setTableLoading(true);

      await dispatch(fetchOrdersHistory({ 
        page, 
        limit,
        ...filters,
        ...sortItemAndDirection,
      }));

      setTableLoading(false);
    }

    fetchData();
  }, [isPaginationDT, page, limit, filters, sortItemAndDirection]);
  

  const refresh = async () => {
    setTableLoading(true);
    await dispatch(fetchOrdersHistory({ 
      page, 
      limit, 
      ...filters,
      ...sortItemAndDirection,
    }));

    setTableLoading(false);
  };

  const columns = ComposedOrdersSchema(
    columnConfig,
    filters,
    setFilters,
    permissionNames,
    handleRevertTradeHistory,
    tradingTypeFilter,
    setTradingTypeFilter,
    tradingPairFilter,
    setTradingPairFilter,
    openedByFilter,
    setOpenedByFilter,
    directionFilter,
    setDirectionFilter,
    statusFilter,
    setStatusFilter,
    pairNames,
    historyStatusData,
    page, 
    limit, 
  );

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <>
        <div className="action__btn-row">
          {crmFilters && (
            <DatatableFilters
              ref={datatableFiltersRef}
              filters={crmFilters}
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={innerPathname}
            />
          )}
          <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={refresh}>
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          <DataTable
            columns={columns}
            data={ordersHistory}
            fixedHeader
            sortServer
            pagination
            paginationServer
            onSort={handleSort}
            paginationPerPage={limit}
            paginationTotalRows={totalCount}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            paginationDefaultPage={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            persistTableHead
            highlightOnHover
            theme="solarizedd"
            progressPending={tableLoading}
            progressComponent={<div className="datatable-loader__background" />}
          />
        </div>
      </>
    )
  );
}

export default ComposedOrdersHistory;
