import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  APPLY_FILTERS,
  CHANGE_ITEMS_PER_PAGE,
  CHANGE_PAGE,
  FETCH_ORDERS_HISTORY_SUCCESS,
  FETCH_PAIR_NAMES_SUCCESS,
  SET_ORDER_TYPE,
} from './ordersHistoryTypes';

export const fetchOrdersHistory = (params) => async (dispatch) => {
  try {
    let url = '/api/orders-history?';

    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        const formattedKey = key.replace(/\s+(.)/g, (_, group) => group.toUpperCase());
        
        if (Array.isArray(value)) {
          url += `${formattedKey}=${value.join(',')}&`;
        } else {
          url += `${formattedKey}=${value}&`;
        }
      }
    });

    url = url.endsWith('&') ? url.slice(0, -1) : url;

    const res = await apiHelper('get', url);
    if (res?.data && res?.data?.success) {
      dispatch({
        type: FETCH_ORDERS_HISTORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response?.data?.message || 'An error occurred while fetching orders history');
  }
};

export const setOrderType = (orderType) => ({
  type: SET_ORDER_TYPE,
  payload: orderType,
});

export const applyFilters = (filters) => ({
  type: APPLY_FILTERS,
  payload: filters,
});

export const changePage = (page) => ({
  type: CHANGE_PAGE,
  payload: page,
});

export const changeItemsPerPage = (itemsPerPage) => ({
  type: CHANGE_ITEMS_PER_PAGE,
  payload: itemsPerPage,
});

export const fetchPairNames = () => async (dispatch) => {
  try {
    const url = '/api/orders-history/pair-names';

    const res = await apiHelper('get', url);
    if (res?.data && res?.data?.success) {
      dispatch({
        type: FETCH_PAIR_NAMES_SUCCESS,
        payload: res.data.pairNames,
      });
    }
  } catch (error) {
    toast.error(error.response?.data?.message || 'An error occurred while fetching pair names');
  }
};
