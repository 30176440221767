export const MarginType = {
  CROSS: 0,
  ISOLATED: 1,
};

export const TradeType = {
  LONG: 1,
  SHORT: 0,
};

export const Direction = {
  BUY: 'Buy',
  SELL: 'Sell',
};

export const TradingType = {
  SPOT: 'Spot',
  FUTURES: 'Futures',
};
