import React, { useState, useEffect, useRef } from 'react';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import { useClickOutside } from '../../hooks/useClickOutside';

import './style.css';

export function ModalFilters({
  data = [], filters, setFilters, tabName, searchParams, usersFilters, setUserFilters, isDateOptions = false,
}) {
  const ModalFilterRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filtredItems, setFilteredItems] = useState([]);
  const [datePickerValue, setDatePickerValue] = useState(new DateObject());
  const [prevCustomFilterId, setPrevCustomFilterId] = useState();
  const datePickerRef = useRef();

  useClickOutside({ onClickOutside: () => setShowFilter(false), ref: ModalFilterRef });

  useEffect(() => {
    if (data && data.length) {
      setFilteredItems(data);
    }
  }, [data]);

  const handleFilter = () => {
    const filteredResult = [];

    data?.forEach((item) => {
      searchParams.params.forEach((param) => {
        const contained = filteredResult.filter((res) => res._id === item._id);
        if (item[param] && String(item[param]).toLowerCase().includes(filterText.toLowerCase()) && !contained.length) filteredResult.push(item);
      });
    });

    if (filteredResult.length) {
      setFilteredItems([...filteredResult]);
    }
  };

  const setOptionName = (item) => {
    let optionName = '';

    searchParams.optionsName.forEach((name) => {
      optionName += ` ${item[name]}`; 
    });

    return optionName;
  };

  const handleSelectAll = () => {
    const filters = data.map((item) => item[searchParams.id]);
    setFilters(filters);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filters });
  };

  const handleClearAll = () => {
    setFilters([]);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: [] });
    setDatePickerValue(new DateObject());
    setPrevCustomFilterId();
  };

  const handleClick = (selectedItem) => {
    if (filters.includes(selectedItem[searchParams.id])) {
      const filtersWithRemoved = filters.filter((item) => item !== selectedItem[searchParams.id]);
      setFilters(filtersWithRemoved);
      setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithRemoved });
    } else {
      const filtersWithAdded = [...filters, selectedItem[searchParams.id]];
      setFilters(filtersWithAdded);
      setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithAdded });
    } 
  };

  const handleSetDateRange = (dateRange) => {
    const resultRange = dateRange.map((item) => new Date(item.year, item.month.index, item.day).toISOString());
    setDatePickerValue(dateRange);
    const _id = resultRange.join('||');
    setPrevCustomFilterId(_id);
        
    const filtersWithAdded = prevCustomFilterId ? [...filters.filter((item) => item !== prevCustomFilterId), _id] : [...filters, _id];
    setFilters(filtersWithAdded);
    setUserFilters({ ...usersFilters, [tabName.toLowerCase()]: filtersWithAdded });
    datePickerRef.current.closeCalendar();
  };  

  useEffect(() => {
    handleFilter();
  }, [filterText]);

  return (
    <div className="modal-filter__container" ref={ModalFilterRef}>
      <div
        className="modal-filter__tab-name" 
        onClick={() => setShowFilter(!showFilter)}
      >
        {tabName}
        {!showFilter ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {showFilter ? (
        <div className="leads-modal-filter">
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => { setShowFilter(false); }}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="Search"
            className="leads-search-bar-filter"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button type="button" onClick={() => handleSelectAll()}>Check all</button>
          <button type="button" onClick={() => handleClearAll()}>Uncheck all</button>
          <ul className="modal-filter__list">
            {filtredItems.map((item) => (
              <li
                className="modal-filter__option"
                style={{ backgroundColor: filters.includes(item._id) ? '#061426' : '' }}
                onClick={() => handleClick(item)}
                key={item._id}
              >
                {filters.includes(item[searchParams.id]) ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#6E7F95"
                  />
                ) : null}
                <div className="modal-filter__option-name">{setOptionName(item)}</div>
              </li>
            ))}
            {isDateOptions 
              && (
                <li 
                  className="modal-filter__option"
                  style={{ backgroundColor: filters.filter((item) => !filtredItems.includes(item[searchParams.id])).length ? '#061426' : '' }}
                >                         
                  <DatePicker
                    className="bg-dark" 
                    ref={datePickerRef} 
                    value={datePickerValue} 
                    onChange={setDatePickerValue} 
                    range
                    render={(value, openCalendar) => (
                      <div
                        onClick={openCalendar}
                      >
                        {filters.filter((item) => !filtredItems.includes(item[searchParams.id])).length ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="#6E7F95"
                            style={{ marginRight: '5px' }}
                          />
                        ) : null}
                        Custom
                        <Icon style={{ height: '20px' }} />
                      </div>
                    )}
                  >
                    <div>
                      <button type="button" onClick={handleClearAll} className="btn btn-danger btn-sm m-2">
                        Clear
                      </button>
                      <button type="button" onClick={() => handleSetDateRange(datePickerValue)} className="btn btn-success btn-sm me-1 p-1">
                        Apply
                      </button>
                    </div>
                  </DatePicker>
                </li>
              )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
