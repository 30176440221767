import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './CreateSmtpPreset.module.css';
import { getCRMUsers } from '../../../redux/crmUser/crmUserActions';
import { createSmtpPreset } from '../../../redux/smtpPresets/smtpPresetsActions';
import { selectSmtpProviders } from '../../../redux/smtpProviders/smtpProvidersSelectors';
import { fetchSmtpProviders } from '../../../redux/smtpProviders/smtpProvidersActions';

export function CreateSmtpPreset({ hideModal }) {
  const dispatch = useDispatch();

  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const crmUserInfo = useSelector((state) => state.crmUser.crmUserInfo);
  const smtpProviders = useSelector(selectSmtpProviders);

  const [name, setName] = useState('');
  const [agent, setAgent] = useState(crmUsers[0]?._id);
  const [providerId, setProviderId] = useState(smtpProviders[0]?._id);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (crmUsers && crmUsers.length === 0) {
      dispatch(getCRMUsers());
    } else {
      setAgent(crmUsers[0]._id);
    }
  }, [crmUsers]);

  useEffect(() => {
    if (smtpProviders.length === 0) {
      dispatch(fetchSmtpProviders);
    }
  }, [smtpProviders]);

  const handleSave = () => {
    dispatch(createSmtpPreset(crmUserInfo._id, {
      agent, providerId, username, password, description, name,
    }, hideModal));
  };

  return (
    <div>
      <div className={style.wrapper}>
        <div className={style.col1}>
          <div className={style.item}>
            <span>Name</span>
            <input
              className={`form-control ${style.passwordInput}`}
              onChange={({ target }) => setName(target.value)}
              value={name}
            />
          </div>
          <div className={style.item}>
            <span>Agent</span>
            <select
              name="agent"
              className={style.agentSelector}
              onChange={({ target }) => {
                setAgent(target.value);
              }}
            >
              {crmUsers.map((user, key) => (
                <option key={user._id} selected={key === 0} value={user._id}>
                  {`${user.firstName} ${user.lastName}`}
                </option>
              ))}
            </select>
          </div>
          <div className={style.item}>
            <span>Username</span>
            <input
              className={`form-control ${style.emailInput}`}
              onChange={({ target }) => setUsername(target.value)}
              value={username}
            />
          </div>
        </div>
        <div className={style.col2}>
          <div className={style.item}>
            <span>Description</span>
            <input
              className={`form-control ${style.descriptionInput}`}
              onChange={({ target }) => setDescription(target.value)}
              value={description}
            />
          </div>
          <div className={style.item}>
            <span>Provider</span>
            <select
              className={style.providerSelector}
              name="provider"
              onChange={({ target }) => {
                setProviderId(target.value);
              }}
            >
              {smtpProviders
                  && smtpProviders.map((provider, key) => (
                    <option
                      key={provider._id}
                      selected={key === 0}
                      value={provider._id}
                    >
                      {provider.name}
                    </option>
                  ))}
            </select>
          </div>
          <div className={style.item}>
            <span>Password</span>
            <input
              className={`form-control ${style.passwordInput}`}
              onChange={({ target }) => setPassword(target.value)}
              value={password}
            />
          </div>
        </div>
      </div>
      <div className={style.btnWrapper}>
        <button
          className="btn btn-primary btn-lg me-1 p-1"
          type="button"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
}
