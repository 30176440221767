import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PreviewComponent
  from '../../../pages/Users/UserDetailComponents/SendEmailModal/PreviewComponent/PreviewComponent';
import './LogModal.css';

function LogModal({ log, onHide }) {
  const containsHtml = log && log.includes('<html>') && log.includes('</html>');
  let beforeHtml;
  let htmlContent;
  if (containsHtml) {
    const htmlPattern = /<html[^>]*>[\s\S]*<\/html>/i;
    htmlContent = log.match(htmlPattern);
    beforeHtml = htmlContent ? log.substring(0, htmlContent.index) : '';
  }

  return (
    <Modal show onHide={onHide} className="log-modal" size="lg">
      <Modal.Header closeButton>
        <h4>Full log</h4>
      </Modal.Header>
      <Modal.Body>
        {containsHtml ? (
          <>
            <p>{beforeHtml}</p>
            <div className="form-group me-2">
              <label className="control-label mb-2">Preview</label>
              <div className="input-wrapper">
                <PreviewComponent HTMLWithProperClass={htmlContent} />
              </div>
            </div>
          </>
        ) : (
          <p>{log}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogModal;
