import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { omitColumn } from './helper';
import { useDebounce } from '../hooks/useDebounce';
import { ModalFilters } from '../components';
import { ModalContext } from '../context';
import {
  EditUsersCommissionsModalBody,
} from '../components/UsersCommissionsDataTable/EditUsersCommissionsModal';
import { deleteUserCommissionById } from '../redux/UsersCommissions/usersCommissionsActions';

export const UsersCommissionsSchema = ({
  columnsConfig,
  filter,
  setFilter,
  isPaginationDT,
  fullNameFilter,
  setFullNameFilter,
  pairFilter,
  setPairFilter,
}) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && filter[key] !== value && isPaginationDT) {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const { pairs } = useSelector((state) => state);

  useDebounce(fullNameFilter, 500, () => debounceCallback({ value: fullNameFilter, key: 'fullName' }));

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteUserCommissionById(id));
      }
    });
  };

  const userColumnOmitHandler = () => {
    if (!columnsConfig.some((column) => column.name === 'User')) return true;
    return omitColumn(columnsConfig, 'User');
  };

  return [
    {
      name: (
        <div
          className="d-flex flex-column search-input-des p-4 pl-0"
          style={{ alignItems: 'start' }}
        >
          Full Name
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={fullNameFilter}
            minLength="3"
            onChange={({ target }) => setFullNameFilter(target.value)}
          />
          {
            (fullNameFilter.length >= 1 && fullNameFilter.length < 3) && (
              <span className="datatable-input__error-text clr-red">
                Please enter at least 3 character
              </span>
            )
          }
        </div>
      ),
      cell: (row) => (
        <Link to={`/user-detail/${row.user._id}`}>
          {`${row.user.firstName} ${row.user.lastName}`}
        </Link>
      ),
      omit: userColumnOmitHandler(),
    },
    {
      name: (
        <ModalFilters
          data={pairs}
          filters={pairFilter}
          setFilters={setPairFilter}
          tabName="Pair"
          searchParams={{ id: '_id', params: ['name', 'alias'], optionsName: ['name'] }}
          usersFilters={filter}
          setUserFilters={setFilter}
        />
      ),
      cell: (row) => (row.pair),
      omit: omitColumn(columnsConfig, 'Pair'),
    },
    {
      name: 'Commission',
      selector: (row) => (`${row.commission} %`),
      sortable: true,
      omit: omitColumn(columnsConfig, 'Commission'),
    },
    {
      name: "Action's",
      cell: (row) => (
        <>
          <button
            className="btn btn-warning btn-sm me-1 p-1"
            type="button"
            onClick={() => showModal(
              {
                headerContent: <h3>Edit commission</h3>,
                bodyContent: <EditUsersCommissionsModalBody row={row} />,
              },
            )}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm me-1 p-1"
            type="button"
            onClick={() => handleDelete(row._id)}
          >
            Delete
          </button>
        </>
      ),
      omit: omitColumn(columnsConfig, "Action's"),
    },
  ];
};
