import { ENV } from '../config/config';

const columns = [
  {
    name: 'UID',
    field: '_id',
    selected: true,
  },
  {
    name: 'Full Name',
    field: 'firstName,lastName',
    selected: true,
  },
  {
    name: 'Email',
    field: 'email',
    selected: true,
  },
  {
    name: 'Phone Number',
    field: 'phone',
    selected: true,
  },
  {
    name: 'Country',
    field: 'country.nicename',
    selected: true,
  },
  {
    name: 'Created',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Affiliate Name',
    field: 'affiliator.firstName,affiliator.lastName',
    selected: true,
    permissionName: 'affiliate_name',
  },
  {
    name: 'Funnel Name',
    field: 'source',
    selected: true,
  },
  {
    name: 'Assigned To',
    field: '',
    selected: true,
  },
  {
    name: 'Status',
    field: 'salesStatus.name',
    selected: true,
  },
  {
    name: 'Balance',
    field: 'account.previousTotalAmount',
    selected: true,
  },
  {
    name: 'Available Credit',
    field: 'account.availableCreditAmount',
    selected: true,
  },
  {
    name: 'Total Credit',
    field: 'account.previousTotalCredit',
    selected: true,
  },
  {
    name: 'Online',
    field: 'lastLoginAt,isLogin',
    selected: true,
  },
  {
    name: 'Last Login Date',
    field: 'lastLoginAt',
    selected: true,
  },
  {
    name: 'Last Comment',
    field: 'adminComments[0].createdAt',
    selected: true,
  },
  {
    name: 'Local Time',
    field: 'countryCode',
    selected: true,
  },
  {
    name: 'Additional info',
    field: 'additionalInfo',
    selected: true,
  },
  {
    name: 'Delete User',
    field: '',
    selected: true,
  },
  {
    name: 'Brand',
    field: 'brand.name',
    selected: true,
  },
];

export const leadsColumns = (permissions) => columns.filter((column) => !column.permissionName || permissions.includes(column.permissionName));
