export const columnsDefaultConfig = [
  {
    name: 'Pair',
    selected: true,
  },
  {
    name: 'Commission',
    selected: true,
  },
  {
    name: "Action's",
    selected: true,
  },
];
