/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updateCryptoPair } from '../../redux/cryptoPair/cryptoPairActions';
import { SystemSettingsTypes } from '../../types/systemSettings';

export default function AdditionalEditModal({ pair = {}, hideModal }) {
  const dispatch = useDispatch();

  const isLeverageChangeEnabled = useSelector((state) => state.systemSettingsReducer.systemSettings.find((setting) => setting.name === SystemSettingsTypes.IS_LEVERAGE_CHANGE_ENABLED)?.value);

  const [data, setData] = useState({
    leverage: pair.leverage ?? 5,
    commission: pair.commission ?? 0,
    swapLong: pair.swapLong ?? 0,
    swapShort: pair.swapShort ?? 0,
    minVolume: pair.minVolume ?? 0,
    maxVolume: pair.maxVolume ?? 0,
  });

  const validator = {
    leverage: [2, 100],
    commission: [0, 100],
    swapLong: [0, 100],
    swapShort: [0, 100],
    minVolume: [0, Infinity],
    maxVolume: [0, Infinity],
  };

  const submit = () => {
    if (!pair._id) return;

    for (const [key, value] of Object.entries(data)) {
      const [minValue, maxValue] = validator[key];

      if (key === 'leverage' && !isLeverageChangeEnabled) {
        continue;
      }

      if (value < minValue || value > maxValue) {
        toast.warning(`${key} cannot be less than ${minValue} and more than ${maxValue}`);
        return;
      }
    }

    dispatch(updateCryptoPair(pair._id, data));
    hideModal();
  };

  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Pair</label>
            <div className="input-wrapper">
              <input type="text" disabled className="mb-3 form-control" value={pair.name} />
            </div>
          </div>
        </Col>
      </Row>
      {isLeverageChangeEnabled && (
        <Row className="mt-10">
          <Col md={12}>
            <div className="form-group me-2">
              <label className="control-label mb-2">Leverage</label>
              <div className="input-wrapper">
                <input 
                  type="number" 
                  className="mb-3 form-control" 
                  value={data.leverage}
                  onChange={({ target: { value } }) => setData((prevState) => ({ ...prevState, leverage: parseInt(value, 10) }))}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Commission %</label>
            <div className="input-wrapper">
              <input 
                type="number" 
                className="mb-3 form-control" 
                value={data.commission}
                onChange={({ target: { value } }) => setData((prevState) => ({ ...prevState, commission: value }))}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Swap Long %</label>
            <div className="input-wrapper">
              <input 
                type="number" 
                className="mb-3 form-control" 
                value={data.swapLong}
                onChange={({ target: { value } }) => setData((prevState) => ({ ...prevState, swapLong: value }))}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Swap Short %</label>
            <div className="input-wrapper">
              <input 
                type="number" 
                className="mb-3 form-control" 
                value={data.swapShort}
                onChange={({ target: { value } }) => setData((prevState) => ({ ...prevState, swapShort: value }))}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Min Volume</label>
            <div className="input-wrapper">
              <input 
                type="number" 
                className="mb-3 form-control" 
                value={data.minVolume}
                onChange={({ target: { value } }) => setData((prevState) => ({ ...prevState, minVolume: value }))}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Max Volume</label>
            <div className="input-wrapper">
              <input 
                type="number" 
                className="mb-3 form-control" 
                value={data.maxVolume}
                onChange={({ target: { value } }) => setData((prevState) => ({ ...prevState, maxVolume: value }))}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={hideModal}>Cancel</Button>
          <Button variant="primary" className="ms-2" onClick={submit}>Save</Button>
        </Col>
      </Row>
    </div>
  );
}
