export const futuresOrderHistoryColumns = [
  {
    name: 'User',
    field: 'user.username',
    selected: true,
  },
  {
    name: 'Type',
    field: 'marginType',
    selected: true,
  },
  {
    name: 'Trading Pair',
    field: 'fromCurrency.symbol,toCurrency.symbol',
    selected: true,
  },
  {
    name: 'Trading Type',
    field: 'tradeType',
    selected: true,
  },
  {
    name: 'Trading Volume',
    field: 'userInvestedAmount,leverage',
    selected: true,
  },
  {
    name: 'Profit/Loss',
    field: 'tradeProfitOrLoss',
    selected: true,
  },
  {
    name: 'Start Time',
    field: 'initiatedAt',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'endCommission',
    selected: true,
  },
  {
    name: 'Swap',
    field: 'endSwap',
    selected: true,
  },
  {
    name: 'End Time',
    field: 'closedAt',
    selected: true,
  },
  {
    name: 'Action',
    selected: true,
  },
];
