import { toast } from 'react-toastify';
import * as actionTypes from './smtpProvidersTypes';
import { apiHelper } from '../apiHelper';


export const fetchSmtpProviders = async (dispatch) => {
  try {
    const response = await apiHelper('get', '/api/smtp-providers');

    if (response?.data?.providers?.length) {
      dispatch({
        type: actionTypes.SET_SMTP_PROVIDERS,
        payload: response.data.providers,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
