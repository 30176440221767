import { toFixed } from './helper';
import { TradeType } from '../types/order';

export const calculateInitialMargin = (row) => {
  const val = (parseFloat(row.qty) * parseFloat(row.tradeStartPrice)) / parseFloat(row.leverage);
  return val && !Number.isNaN(val) ? val : 0;
};

export const calculatePositionMargin = (row) => {
  const maintenanceMarginPercent = parseFloat(row.maintenanceMargin);
  const maintenanceMargin = maintenanceMarginPercent / 100;
  const initialMargin = calculateInitialMargin(row);
  const maintenanceAmount = parseFloat(row?.maintenanceAmount ?? 0);
  const calculatedMaintenanceMargin = initialMargin * maintenanceMargin;

  const maintenance = maintenanceAmount >= calculatedMaintenanceMargin ? maintenanceAmount : calculatedMaintenanceMargin;

  const val = toFixed(initialMargin - maintenance, 2);

  return `${val && !Number.isNaN(val) ? val : 0} ${row?.fromCurrency?.symbol}`;
};

export const calculateUnrealizedPnL = (row, marketPrice) => {
  const pnLResult = {
    value: 0,
    percentage: 0,
    roe: 0,
  };

  if (!row) return pnLResult;
  if (!marketPrice) return pnLResult;

  const qty = parseFloat(row.qty);
  const entryPrice = parseFloat(row.tradeStartPrice);
  const leverage = parseFloat(row.leverage);
  const investedAmount = parseFloat(row.userInvestedAmount);
  const maintenanceMarginPercent = parseFloat(row.maintenanceMargin);

  const commissionPercent = parseFloat(row.commission);
  const commission = investedAmount * (commissionPercent / 100);

  const initialMargin = investedAmount;
  const maintenanceMargin = maintenanceMarginPercent / 100;

  const marginFee = initialMargin * maintenanceMargin;

  let pnl = row.tradeType === TradeType.LONG
    ? qty * (marketPrice - entryPrice)
    : qty * (entryPrice - marketPrice);

  pnl -= commission + marginFee;

  if (!Number.isNaN(pnl)) {
    const positionValue = investedAmount * leverage;

    pnLResult.value = pnl;
    pnLResult.percentage = (pnl / positionValue) * 100;
    pnLResult.roe = (pnl / initialMargin) * 100;
  }

  return pnLResult;
};
