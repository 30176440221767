import {
  GET_USERS_COMMISSIONS,
  ADD_USERS_COMMISSIONS,
  UPDATE_USERS_COMMISSIONS,
  DELETE_USERS_COMMISSIONS,
  SET_IS_DATA_LOADING,
} from './usersCommissionsTypes';

const initialState = {
  data: [],
  isLoading: false,
  totalCount: 10,
};

const userCommissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DATA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case GET_USERS_COMMISSIONS:
      return {
        ...state,
        data: action.payload.commissions,
        totalCount: action.payload.totalCount,
      };

    case ADD_USERS_COMMISSIONS:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case UPDATE_USERS_COMMISSIONS:
      return {
        ...state,
        data: state.data.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };

    case DELETE_USERS_COMMISSIONS:
      return {
        ...state,
        data: state.data.filter((item) => item._id !== action.payload),
      };

    default:
      return state;
  }
};

export default userCommissionsReducer;
