import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UsersCommissionsDataTable } from '../../../components/UsersCommissionsDataTable/UsersCommissionsDataTable';
import { getUsersCommissionsById } from '../../../redux/UsersCommissions/usersCommissionsActions';
import { columnsDefaultConfig } from '../../../columnsDefaultConfig/userCommissions';

export function UserCommissions() {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  const filtersStorageName = 'DataTable_user/commissions/filter';
  const paginationStorageName = 'DataTable_user/commissions/pagination';
  const filterIdStorageName = 'DataTable_user/commissions/filters_id';
  const columnsStorageName = 'DataTable_user/commissions/columns';

  const getDataCallBack = ({
    dispatch, page, limit, filter,
  }) => {
    dispatch(getUsersCommissionsById(id, { page, limit, query: filter }));
  };

  return (
    <UsersCommissionsDataTable
      filtersStorageName={filtersStorageName}
      paginationStorageName={paginationStorageName}
      filterIdStorageName={filterIdStorageName}
      columnsStorageName={columnsStorageName}
      setLoader={setLoader}
      loader={loader}
      getDataCallBack={getDataCallBack}
      columnsDefaultConfig={columnsDefaultConfig}
      userId={id}
    />
  );
}
