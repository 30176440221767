import React, { useState } from 'react';
import OrdersHistory from './OrdersHistory';
import ComposedOrdersHistory from './ComposedOrdersHistory';
import SpotOrders from './SpotOrders';

const tabs = [
  { key: 'futures', name: 'Features', component: () => <OrdersHistory /> },
  { key: 'spot', name: 'Spot Orders', component: () => <SpotOrders /> },
  { key: 'composed', name: 'All Orders', component: () => <ComposedOrdersHistory /> },
];

function OrdersHistoryNavigation() {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h4>Trade History</h4>
        <br />
        <div className="user-details-tabs" style={{ color: '#ffffff' }}>
          {tabs.map((tab) => (
            <div
              key={tab.key}
              className={activeTab === tab.key ? 'tab-active' : 'tab'}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <br />
        {tabs.find((tab) => tab.key === activeTab)?.component()}
      </div>
    </div>
  );
}

export default OrdersHistoryNavigation;
