import {
  GET_PAIRS,
} from './pairTypes';

const initialState = [];

const pairReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAIRS:
      return action.payload;
    default:
      return state;
  }
};

export default pairReducer;
