import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Row, Col, Button } from 'react-bootstrap';
import { colourStyles } from '../../../../helpers/styles';
import { getPSPOptions, createUserPsp } from '../../../../redux/usersPSPs/usersPSPsActions';

function CreatePSPModal({ closeModal, userId }) {
  const dispatch = useDispatch();
  const { options } = useSelector((state) => state.usersPsps);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [data, setData] = useState({
    isEnabled: true,
    pspId: null,
    type: null,
    userId,
  });

  const isEnabledOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  useEffect(() => {
    dispatch(getPSPOptions());
  }, []);

  useEffect(() => {
    setIsBtnDisabled(!data.pspId && !data.type);
  }, [data]);

  const handleInputChange = (fieldName, value) => {
    setData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const submit = async () => {
    setIsBtnDisabled(true);
    await dispatch(createUserPsp(data));
    setIsBtnDisabled(false);
    closeModal();
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">PSP</label>
            <Select
              styles={colourStyles}
              options={options}
              isSearchable
              onChange={({ value, type }) => {
                handleInputChange('pspId', value);
                handleInputChange('type', type);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Is Enabled</label>
            <Select
              styles={colourStyles}
              options={isEnabledOptions}
              defaultValue={{ label: 'Yes', value: true }}
              onChange={({ value }) => handleInputChange('isEnabled', value)}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '25px' }}>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>Add</Button>
        </Col>
      </Row>
    </>

  );
}

export default CreatePSPModal;
