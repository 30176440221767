import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import ReactFlagsSelect from 'react-flags-select';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { getSalesStatuses } from '../../redux/salesStatus/salesStatusActions';
import { displayClientRoles } from '../../redux/roles/roleActions';
import { RegisterUser, updateAuthState } from '../../redux/auth/authActions';
import { getSubCRMUsers } from '../../redux/crmUser/crmUserActions';
import { getBrands } from '../../redux/brand/brandActions';

const userTypeOptions = [{ label: 'Lead', value: 1 }, { label: 'Client', value: 2 }];

const colourStyles = {
  control: (styles, { isSelected }) => ({
    ...styles,
    background: '#374057',
    color: '#fff',
    border: '1px solid #374057',
    boxShadow: isSelected ? 'none' : 'none',
    borderColor: isSelected ? '#374057' : '#374057',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  menuList: (styles) => ({
    ...styles,
    background: '#374057',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? '#16202e'
      : isSelected
        ? '#16202e'
        : undefined,
    color: '#fff',
    cursor: 'pointer',
    zIndex: 1,
    '&:hover': {
      background: '#16202e',
    },
  }),
};

function AddUser() {
  const dispatch = useDispatch();
  const registered = useSelector((state) => state.auth?.registered);
  const error = useSelector((state) => state.auth?.error);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const brands = useSelector((state) => state.brands);
  const [selectedUserType, setSelectedUserType] = useState({ label: 'Lead', value: 1 });
  const [selectedFlagCountry, setSelectedFlagCountry] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [brandId, setBrandId] = useState('');
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);
  const [salesStatusType, setSalesStatusType] = useState({ value: '', color: '#fff' });

  const [defaultManager, setDefaultManager] = useState(null);
  const [managers, setManagers] = useState([]);

  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    const intiEvent = async () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const id = data?.roleId;
      const uid = data?._id;

      await dispatch(getSalesStatuses());
      await dispatch(getSubCRMUsers(id, uid));
      await dispatch(displayClientRoles());
      dispatch(getBrands());
    };
    intiEvent();
  }, []);

  useEffect(() => {
    if (salesStatuses?.length > 0) {
      setSalesStatusType({ value: salesStatuses[0]?._id, color: salesStatuses[0]?.color });
    }
    if (brands?.length > 0) {
      setBrandId(brands[0]?._id);
    }
  }, [salesStatuses, brands]);

  useEffect(() => {
    const initManagetData = async () => {
      if (crmUsers?.length > 0) {
        const allManagers = await crmUsers?.map((manage) => ({ label: `${manage.firstName} ${manage.lastName}`, value: manage._id }));
        setManagers(allManagers);
      }
    };
    initManagetData();
  }, [crmUsers]);

  const addUser = {
    firstname: {
      required: 'First name is required',
    },
    lastname: {
      required: 'Last name is required',
    },
    username: {
      required: 'Username is required',
    },
    email: {
      required: 'Email is required',
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
      },
    },
    phone: {
      required: 'Phone number is required',
      pattern: {
        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        message: 'Please enter a valid contact number',
      },
    },
    password: {
      required: 'Password is required',
      minLength: {
        value: 6,
        message: 'Password must have at least 6 characters',
      },
    },
    dateOfBirth: {
      required: 'Date of birth is required',
    },
  };

  const handleAddUser = (formData) => {
    setLoader(true);
    const data = {
      firstName: formData.firstname,
      lastName: formData.lastname,
      username: formData.username,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      dateOfBirth: formData.dateOfBirth,
      additionalInfo,
      // roleId: selectedRole.value,
      clientType: selectedUserType.value,
      // clientStatus: selectedUserStatus.value,
      countryCode: selectedFlagCountry,
      salesStatusId: salesStatusType?.value ? salesStatusType?.value : null,
      isVerified: true,
      status: true,
      assignedTo: defaultManager?.value,
      brandId: brandId || null,
    };
    dispatch(RegisterUser(data));
  };

  useEffect(() => {
    if (registered) {
      setLoader(false);
      history.goBack();
    }
    dispatch(updateAuthState());
  }, [registered]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateAuthState());
    }
  }, [error]);

  const handleUserTypeChange = (selectedCurrUserType) => {
    setSelectedUserType(selectedCurrUserType);
  };

  const handleUserStatusChange2 = (e) => {
    setSalesStatusType({ value: e.target.value, color: e.target[e.target.selectedIndex].getAttribute('color') });
  };

  const handleDefaultManagerChange = (selectedDefaultManager) => {
    setDefaultManager(selectedDefaultManager);
  };

  return (loader ? (<FullPageTransparentLoader />) : (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
        <h3>Add User</h3>
        <div className="tab-pane fade show active">
          <form onSubmit={handleSubmit(handleAddUser)}>
            <div className="form-group col-md-12">
              <label className="control-label">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter First name"
                {...register('firstname', addUser.firstname)}
                name="firstname"
              />
              {errors?.firstname && <span className="errMsg">{errors.firstname.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Last name"
                {...register('lastname', addUser.lastname)}
                name="lastname"
              />
              {errors?.lastname && <span className="errMsg">{errors.lastname.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">User Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Username"
                {...register('username', addUser.username)}
                name="username"
              />
              {errors?.username && <span className="errMsg">{errors.username.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2 ">
              <label className="control-label">Select User Type</label>
              <Select
                value={selectedUserType}
                onChange={handleUserTypeChange}
                options={userTypeOptions}
                styles={colourStyles}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Select User Status</label>
              <select className="form-control user-status-select" name="type" value={salesStatusType?.value} onChange={handleUserStatusChange2} style={{ color: salesStatusType?.color ? salesStatusType?.color : '#fff' }}>
                <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
                {salesStatuses?.length > 0 && salesStatuses?.map((currentStatus) => (
                  <option value={currentStatus?._id} key={currentStatus?._id} style={{ color: currentStatus?.color }} color={currentStatus?.color}>
                    {currentStatus?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Select Brand</label>
              <select 
                className="form-control user-status-select"
                name="brand"
                value={brandId}
                onChange={({ target: { value } }) => setBrandId(value)}
              >
                {brands?.length && brands.map((brand) => (
                  <option value={brand._id} key={brand._id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="input-wrapper d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between"> */}
            <div className="form-group col-md-12 pt-2">
              <label htmlFor="exampleFormControlInput1" className="form-label">Default Managers</label>
              <Select
                className="form-control"
                value={defaultManager}
                onChange={handleDefaultManagerChange}
                options={managers}
                styles={colourStyles}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                {...register('email', addUser.email)}
                name="email"
              />
              {errors?.email && <span className="errMsg">{errors.email.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Country</label>
              <ReactFlagsSelect
                selected={selectedFlagCountry}
                onSelect={(code) => setSelectedFlagCountry(code)}
                searchable
                searchPlaceholder="Search for a country"
                className="admin-country-react-flags-select"
              />
            </div>
            <div className="form-group col-md-12 pt-2  dob">
              <label className="control-label">Select Date Of Birth</label>
              <input
                type="date"
                className="form-control"
                placeholder="Type date of birth..."
                name="dateOfBirth"
                {...register('dateOfBirth', addUser.dateOfBirth)}
              />
              {errors?.dateOfBirth && <span className="errMsg">{errors.dateOfBirth.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Phone Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Phone number"
                name="phone"
                {...register('phone', addUser.phone)}
              />
              {errors?.phone && <span className="errMsg">{errors.phone.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                {...register('password', addUser.password)}
                name="password"
              />
              {errors?.password && <span className="errMsg">{errors.password.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Additional Info</label>
              <textarea placeholder="Enter additional info if any..." className="form-control" name="additionalInfo" value={additionalInfo} rows="3" onChange={(event) => setAdditionalInfo(event.target.value)} />
            </div>
            <div>
              <button className="btn btn-default" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
  );
}

export default AddUser;
