import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { DatePickerComponent } from '../DatePicker/DatePickerComponent';
import './PeriodSelection.css';
import {
  getDateRange, parseISOString, periodOptions, Periods,
} from '../DatePicker/DatePicker.helper';

const colourStyles = {
  container: (provided) => ({
    ...provided,
    maxWidth: '100%',
    minWidth: '310px',
  }),
  control: (styles, { isSelected }) => ({
    ...styles,
    background: '#374057',
    color: '#fff',
    border: '1px solid #374057',
    boxShadow: isSelected ? 'none' : 'none',
    borderColor: isSelected ? '#374057' : '#374057',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  menuList: (styles) => ({
    ...styles,
    background: '#374057',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? '#16202e'
      : isSelected
        ? '#16202e'
        : undefined,
    color: '#fff',
    cursor: 'pointer',
    zIndex: 1,
    '&:hover': {
      background: '#16202e',
    },
  }),
};

function PeriodSelection({ onApplyFilter }) {
  const [selectedPeriod, setSelectedPeriod] = useState(Periods.ALL);
  const [fromDate, setFromDate] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toDate, setToDate] = useState('');
  const [toTime, setToTime] = useState('');
  const [updatedPeriodOptions, setUpdatedPeriodOptions] = useState([]);
  const [isDisablePeriodSelection, setIsDisablePeriodSelection] = useState(true);

  const handleApplyFilter = () => {
    let updatedFromDate = fromDate;
    let updatedToDate = toDate;
    let updatedFromTime = fromTime;
    let updatedToTime = toTime;

    if (selectedPeriod === Periods.CUSTOM) {
      updatedFromDate = fromDate || new Date().toISOString().split('T')[0];
      updatedToDate = toDate || new Date().toISOString().split('T')[0];
      updatedFromTime = fromTime || '00:00';
      updatedToTime = toTime || new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });

      setFromDate(updatedFromDate);
      setToDate(updatedToDate);
      setFromTime(updatedFromTime);
      setToTime(updatedToTime);
    }

    const formatDate = (dateString) => {
      const formattedDate = new Date(dateString);
      formattedDate.setMinutes(formattedDate.getMinutes() + new Date().getTimezoneOffset());
      return formattedDate;
    };

    const fromDateFormatted = formatDate(`${updatedFromDate}T${updatedFromTime}:00Z`);

    const toDateFormatted = formatDate(`${updatedToDate}T${updatedToTime}:00Z`);

    const period = selectedPeriod === Periods.ALL ? ['', ''] : [fromDateFormatted, toDateFormatted];

    onApplyFilter({ period });
  };

  const changePeriodHandler = (period) => {
    if (period.label === Periods.ALL) {
      setIsDisablePeriodSelection(true);
      setFromDate('');
      setFromTime('');
      setToDate('');
      setToTime('');
    }
    if (period.label === Periods.CUSTOM) {
      setIsDisablePeriodSelection(false);
      setFromDate('');
      setFromTime('');
      setToDate('');
      setToTime('');
    }
    if (period.label !== Periods.ALL && period.label !== Periods.CUSTOM) {
      setIsDisablePeriodSelection(true);
      const [fromDateParsed, fromTimeParsed] = parseISOString(period.dateRange[0]);
      const [toDateParsed, toTimeParsed] = parseISOString(period.dateRange[1]);
      setFromDate(fromDateParsed);
      setFromTime(fromTimeParsed);
      setToDate(toDateParsed);
      setToTime(toTimeParsed);
    }

    setSelectedPeriod(period.label);
  };

  useEffect(() => {
    const newPeriodOptions = periodOptions.map((option) => {
      if (option.label === Periods.ALL) {
        return {
          ...option,
          dateRange: ['', ''],
        };
      }
      if (option.label !== Periods.CUSTOM) {
        const { startDate, endDate } = getDateRange(option.label);
        return {
          ...option,
          dateRange: [(startDate ? startDate.toISOString() : ''), (endDate ? endDate.toISOString() : '')],
        };
      }
      return option;
    });
    setUpdatedPeriodOptions(newPeriodOptions);
  }, []);

  return (
    <div className="d-flex gap-2 period-section-container mb-2">
      <Select
        value={updatedPeriodOptions.find((option) => option.label === selectedPeriod)}
        onChange={changePeriodHandler}
        options={updatedPeriodOptions}
        styles={colourStyles}
      />
      <DatePickerComponent
        valueDatePicker={fromDate}
        labelTitle="From"
        datePickerId="dateFrom"
        timePickerId="timeFrom"
        valueTimePicker={fromTime}
        setDate={setFromDate}
        setTime={setFromTime}
        disabled={isDisablePeriodSelection}
      />
      <DatePickerComponent
        valueDatePicker={toDate}
        labelTitle="To"
        datePickerId="dateTo"
        timePickerId="timeTo"
        valueTimePicker={toTime}
        setDate={setToDate}
        setTime={setToTime}
        disabled={isDisablePeriodSelection}
      />
      <button type="button" className="btn btn-primary" onClick={handleApplyFilter}>
        Apply Filter
      </button>
    </div>
  );
}

export default PeriodSelection;
