/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  GET_ORDER,
  CLEAR_ORDER,
  GET_USER_ORDER,
  ORDER_ERROR,
  STOP_ORDER,
  ADD_ORDER,
  REVERT_ORDER,
  EDIT_HISTORY_ORDER,
  START_ORDER,
  ALL_ACTIVE_ORDERS,
  EXPORT_FILTERED_DATA_TO_FILE,
  TOGGLE_ORDER_STATE,
  UPDATE_SOCKET_ORDER,
  UPDATE_ACTIVE_ORDER,
  GET_OPEN_ORDERS_BY_QUERY,
  GET_OPEN_ORDERS_PAIR_NAMES,
  GET_ALL_ORDERS_BY_QUERY,
} from './leverageOrderTypes';
import { apiHelper } from '../apiHelper';

export const getLeverageOrders = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/leverageOrder/', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_ORDER,
        payload: data.orders,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getAllOrdersByQuery = ({
  page, limit, query = {},
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}`;

    Object.entries(query).forEach(([key, value]) => {
      if (key === 'trading pair') key = 'tradingPair';

      if (key === 'trading type') key = 'tradingType';

      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/leverageOrder/all-orders-history${url}`);

    if (res.status === 200) {
      await dispatch({
        type: GET_ALL_ORDERS_BY_QUERY,
        payload: res.data.ordersHistory,
      });
    }
  } catch (error) {
    toast.error(error.response?.message);
  }
};

export const getUserLeverageOrders = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/leverageOrder/${id}`, '');
    if (res?.data?.userOrders) {
      dispatch({
        type: GET_USER_ORDER,
        payload: res.data.userOrders,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateActiveOrder = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/leverageOrder/update', data);

    if (res && res.data && res.data.leverageOrder_) {
      toast.success(res.data.message, {
        autoClose: 1000,
      });

      dispatch({
        type: UPDATE_ACTIVE_ORDER,
        payload: res.data.leverageOrder_,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error?.response?.message);
  }
};

export const updateLeverageOrder = (dataBody, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/leverageOrder/update', dataBody);
    console.log('updateLeverageOrder res', res);
    if (res?.data) {
      toast.success(res.data.message, {
        autoClose: 1000,
      });

      dispatch({
        type: ADD_ORDER,
        payload: res.data,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response?.message);
  }
};

export const clearLeverageOrder = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ORDER,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const stopLeverageOrder = (id, rate, auto) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/leverageOrder/stop/${id}`, { stopRate: rate, autoStop: auto });
    if (res && res.data && res.data.success) {
      const { data } = res;
      data._id = id;

      toast.success(res.data.message, {
        autoClose: 1000,
      });

      dispatch({
        type: STOP_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error?.response?.message);
  }
};

export const revertLeverageOrder = (id, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/leverageOrder/revert-order/${id}`, '');
    if (res?.data) {
      const { data } = res;
      await dispatch(getUserLeverageOrders(userId));
      toast.success(res.data.message);
      dispatch({
        type: REVERT_ORDER,
        payload: data?.leverageOrder,
      });
    }
  } catch (error) {
    toast.error(error?.response?.message);
  }
};

export const editLeverageHistoryOrder = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/leverageOrder/edit-history-order/${id}`, data);
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: EDIT_HISTORY_ORDER,
        payload: data.leverageOrder,
      });
    }
  } catch (error) {
    toast.error(error?.response?.message);
  }
};

export const startLeverageOrder = (order) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/leverageOrder/start', order);
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: START_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getOpenOrdersByQuery = ({
  page, limit, query = {},
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/leverageOrder/open-orders-table-data${url}`);

    if (res.status === 200) {
      await dispatch({
        type: GET_OPEN_ORDERS_BY_QUERY,
        payload: res.data.openOrders,
      });
    }
  } catch (error) {
    toast.error(error.response?.message);
  }
};

export const getOpenOrdersPairNames = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/leverageOrder/pair-names');

    if (res.status === 200) {
      await dispatch({
        type: GET_OPEN_ORDERS_PAIR_NAMES,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response?.message);
  }
};

export const allActiveOrders = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/leverageOrder/all-orders', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: ALL_ACTIVE_ORDERS,
        payload: data,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const exportFilteredDataToFile = (filteredItems, columns, fileType) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/leverageOrder/export', {
      filteredItems, columns, fileType,
    });
    if (res.data && res.data.fileUrl) {
      window.open(res.data.fileUrl);
    } else {
      toast.error('File was not created. Please, contact our support team.');
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch({
      type: EXPORT_FILTERED_DATA_TO_FILE,
      payload: true,
    });
  }
};

export const updateOrderState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_ORDER_STATE,
    });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const updateSocketOrder = (order) => (dispatch) => {
  if (order.prevExists) {
    dispatch({ type: START_ORDER });
  } else {
    dispatch({ type: UPDATE_SOCKET_ORDER, payload: order });
  }
};
