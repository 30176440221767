import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { ModalContext } from '../context';
import {
  deleteSmtpPreset,
  setSmtpAgentFilterCreator,
  setSmtpDescriptionFilterCreator,
  setSmtpUsernameFilterCreator,
  setSmtpPasswordFilterCreator,
  setSmtpProviderFilterCreator, setSmtpNameFilterCreator,
} from '../redux/smtpPresets/smtpPresetsActions';
import { EditSmtpPreset } from '../pages/SmtpPresets/EditSmtpPreset/EditSmtpPreset';
import {
  selectSmtpAgentSearchFilter,
  selectSmtpDescriptionSearchFilter, selectSmtpNameSearchFilter,
  selectSmtpPasswordSearchFilter,
  selectSmtpProviderSearchFilter,
  selectSmtpUsernameSearchFilter,
} from '../redux/smtpPresets/smtpPresetsSelectors';
import { getCRMUsers } from '../redux/crmUser/crmUserActions';
import { selectSmtpProviders } from '../redux/smtpProviders/smtpProvidersSelectors';
import { fetchSmtpProviders } from '../redux/smtpProviders/smtpProvidersActions';
import styles from './SmptPresetsColumns.module.css';

export const SmtpPresetsColumns = () => {
  const dispatch = useDispatch();

  const { showModal, hideModal } = useContext(ModalContext);

  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const providers = useSelector(selectSmtpProviders);
  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  // TODO PERMISSION CHECK permissions.includes('edit_smtp_presets');
  const isUserHasEditTemplatePermission = true || (permissions && permissions.length > 0 && permissions.includes('edit_smtp_presets'));

  // filters variables
  const nameSearchFilter = useSelector(selectSmtpNameSearchFilter);
  const agentSearchFilter = useSelector(selectSmtpAgentSearchFilter);
  const providerSearchFilter = useSelector(selectSmtpProviderSearchFilter);
  const usernameSearchFilter = useSelector(selectSmtpUsernameSearchFilter);
  const passwordSearchFilter = useSelector(selectSmtpPasswordSearchFilter);
  const descriptionSearchFilter = useSelector(selectSmtpDescriptionSearchFilter);

  useEffect(() => {
    if (crmUsers && crmUsers.length === 0) {
      dispatch(getCRMUsers());
    }
  }, [crmUsers]);

  useEffect(() => {
    if (providers.length === 0) {
      dispatch(fetchSmtpProviders);
    }
  }, [providers]);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSmtpPreset(id));
      }
    });
  };

  const editAction = (row) => {
    showModal({
      headerContent: <h3>Edit Email Template</h3>,
      bodyContent: <EditSmtpPreset preset={row} hideModal={hideModal} />,
    });
  };

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-3 pb-3" style={{ alignItems: 'start' }}>
          Agent
          <select
            name="agent"
            value={agentSearchFilter}
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setSmtpAgentFilterCreator(value))}
          >
            {[{ _id: '', firstName: 'none', lastName: '' }, ...crmUsers].map((user) => (<option value={user._id}>{`${user.lastName} ${user.firstName} `}</option>))}
          </select>
        </div>
      ),
      minWidth: '150px',
      cell: ({ agentName }) => (<span>{agentName}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-3 pb-3" style={{ alignItems: 'start' }}>
          Name
          <input
            type="text"
            name="email"
            autoComplete="off"
            value={nameSearchFilter}
            placeholder="Filter by email"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpNameFilterCreator(e.target.value))}
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ name }) => `${name}`,
      cell: ({ name }) => (<span>{name}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-3 pb-3" style={{ alignItems: 'start' }}>
          Provider
          <select
            name="provider"
            value={providerSearchFilter}
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setSmtpProviderFilterCreator(value))}
          >
            {[{ _id: '', name: 'none' }, ...providers].map((provider) => (<option value={provider._id}>{`${provider.name}`}</option>))}
          </select>
        </div>
      ),
      minWidth: '130px',
      selector: ({ providerName }) => (<span>{providerName}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-3 pb-3" style={{ alignItems: 'start' }}>
          Username
          <input
            type="text"
            name="email"
            autoComplete="off"
            value={usernameSearchFilter}
            placeholder="Filter by email"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpUsernameFilterCreator(e.target.value))}
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ username }) => `${username}`,
      cell: ({ username }) => (<span>{username}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-3 pb-3" style={{ alignItems: 'start' }}>
          Password
          <input
            type="text"
            name="password"
            autoComplete="off"
            value={passwordSearchFilter}
            placeholder="Filter by password"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpPasswordFilterCreator(e.target.value))}
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ password }) => `${password}`,
      cell: ({ password }) => (<span>{password}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-3 pb-3" style={{ alignItems: 'start' }}>
          Description
          <input
            type="text"
            name="description"
            autoComplete="off"
            value={descriptionSearchFilter}
            placeholder="Filter by description"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpDescriptionFilterCreator(e.target.value))}
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ description }) => `${description}`,
      cell: ({ description }) => (<span>{description}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-3 pb-3" style={{ alignItems: 'end', width: '100%' }}>
          <div className="newTablePositionHeaderSMTP">
            Actions
          </div>
        </div>
      ),
      minWidth: '160px',
      cell: (row) => (
        <div className={styles.newTableActionsBtn}>
          {isUserHasEditTemplatePermission ? (
            <button
              className="btn btn-warning btn-sm me-1 p-1"
              type="button"
              onClick={() => editAction(row)}
            >
              Edit
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteAction(row._id)}
          >
            <BsTrashFill size={18} />
          </button>
        </div>
      ),
    },
  ];

  return columns;
};
