import {
  APPLY_FILTERS,
  CHANGE_ITEMS_PER_PAGE,
  CHANGE_PAGE,
  FETCH_ORDERS_HISTORY_SUCCESS,
  FETCH_PAIR_NAMES_SUCCESS,
  SET_ORDER_TYPE,
} from './ordersHistoryTypes';

const initialState = {
  ordersHistory: [],
  totalCount: 0,
  orderType: 'all',
  filters: {},
  page: 1,
  itemsPerPage: 25,
  pairNames: [],
};

const ordersHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_HISTORY_SUCCESS:
      return {
        ...state,
        ordersHistory: action.payload.ordersHistory,
        totalCount: action.payload.totalCount,
      };
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload,
        page: 1,
      };
    case APPLY_FILTERS:
      return {
        ...state,
        filters: action.payload,
        page: 1,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case CHANGE_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload,
        page: 1,
      };
    case FETCH_PAIR_NAMES_SUCCESS:
      return {
        ...state,
        pairNames: action.payload,
      };
    default:
      return state;
  }
};

export default ordersHistoryReducer;
