import React, {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faColumns, faPlus, faRefresh, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UsersCommissionsSchema } from '../../DataTableSchemas/UsersCommissionsSchema';
import { DatatableFilters } from '..';
import styles from './styles.module.css';
import { getPairs } from '../../redux/pairs/pairActions';
import FullPageTransparentLoader from '../../pages/FullPageTransparentLoader/fullPageTransparentLoader';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { ModalContext } from '../../context';
import { AddUsersCommissionsModal } from './AddUsersCommissionsModal';

export function UsersCommissionsDataTable({
  filtersStorageName,
  paginationStorageName,
  filterIdStorageName,
  columnsStorageName,
  setLoader,
  loader,
  getDataCallBack,
  columnsDefaultConfig,
  userId,
}) {
  const { showModal } = useContext(ModalContext);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { data, totalCount, isLoading } = useSelector((state) => state.usersCommissions);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [columnsConfig, setColumnsConfig] = useState(columnsDefaultConfig);
  const [fullNameFilter, setFullNameFilter] = useState('');
  const [pairFilter, setPairFilter] = useState([]);

  const datatableFiltersRef = useRef(null);

  const columnsJSON = localStorage.getItem(columnsStorageName);
  const storageFiltersJSON = localStorage.getItem(filtersStorageName);
  const paginationJSON = localStorage.getItem(paginationStorageName);

  const setStoredPagination = () => {
    if (paginationJSON) {
      const rowsJSON = paginationJSON;
      const filterRows = rowsJSON ? JSON.parse(rowsJSON) : {};
      setLimit(filterRows.limit ?? 25);
      setPage(filterRows.page ?? 1);
    } else {
      localStorage.setItem(paginationStorageName, JSON.stringify({ page, limit }));
    }
    setIsPaginationDT(true);
  };

  const setStoredFilterData = () => {
    const storageData = storageFiltersJSON;
    if (storageData) {
      const filters = JSON.parse(storageData);

      setFullNameFilter(filters.fullName || '');
      setPairFilter(filters.pair || []);
      setFilter(filters);
    } else {
      localStorage.setItem(filtersStorageName, JSON.stringify(filter));
    }
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnsConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnsConfig));
    }
  };

  const getAllStoredData = () => {
    setStoredFilterData();
    setStoredColumnsData();
    setStoredPagination();
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setLimit(currentRowsPerPage);
  };

  const handlePageChange = async (page) => {
    setPage(page);
  };

  const toggleColumn = (name) => {
    const updatedColumns = columnsConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnsConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const handleClear = () => {
    setFullNameFilter('');
    setPairFilter([]);

    setFilter({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const handleRefresh = () => {
    getDataCallBack({
      dispatch,
      page,
      limit,
      filter,
    });
  };

  const setCRMFilters = (filter) => {
    const { fullName, currency } = filter;

    setFilter({
      fullName,
      pair: currency,
    });

    setFullNameFilter(fullName);
    setPairFilter(currency);

    localStorage.setItem(filtersStorageName, JSON.stringify(filter));
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.fullName || '',
      currency: filters.pair || [],
    };
    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toast.error('Select at least one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        fullName: filters.fullName || '',
        currency: filters.pair || [],
      };

      await dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select at least one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  useEffect(async () => {
    setLoader(true);
    getAllStoredData();
    await dispatch(getPairs());
  }, []);

  useEffect(() => {
    if (!isPaginationDT) return;
    localStorage.setItem(filtersStorageName, JSON.stringify(filter));
    getDataCallBack({
      dispatch,
      page,
      limit,
      filter,
    });
    setLoader(false);
  }, [isPaginationDT, filter, page, limit]);

  const columns = UsersCommissionsSchema({
    columnsConfig,
    filter,
    setFilter,
    isPaginationDT,
    fullNameFilter,
    setFullNameFilter,
    pairFilter,
    setPairFilter,
  });

  return loader ? <FullPageTransparentLoader /> : (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        {pathname === '/users-commissions' && (
          <h3>Users Commissions</h3>
        )}

        <div className="action__btn-row">
          {pathname !== '/users-commissions' && (
            <button
              type="button"
              className="btn btn-default icon-btn btn-bg-green"
              onClick={() => showModal({
                headerContent: <h3>Add Commission</h3>,
                bodyContent: <AddUsersCommissionsModal userId={userId} />,
              })}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add
            </button>
          )}

          {crmFilters && (
            <DatatableFilters
              ref={datatableFiltersRef}
              filters={crmFilters}
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={pathname}
            />
          )}
          <Dropdown className="leads-columns-dropdown ms-1">
            <Dropdown.Toggle
              variant=""
              className="btn-default"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon
                icon={faColumns}
                size="xs"
              />
              Columns
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ul className="leads-columns-list">
                {columnsConfig.map((column) => (
                  <li
                    className="leads-columns-option"
                    key={column.name}
                    onClick={() => toggleColumn(column.name)}
                  >
                    {column.selected && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="#6E7F95"
                      />
                    )}
                    <span className="leads-columns-option__name">{column.name}</span>
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          <button
            type="button"
            className={`btn btn-default ms-1 ${styles.mainButton}`}
            onClick={handleClear}
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>

          <button
            type="button"
            className={`btn btn-default ms-1 ${styles.mainButton}`}
            onClick={handleRefresh}
          >
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>
        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {isPaginationDT && (
            <DataTable
              columns={columns}
              data={data}
              pagination
              highlightOnHover
              paginationServer
              paginationTotalRows={totalCount}
              paginationPerPage={limit}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              progressPending={isLoading}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onChangePage={handlePageChange}
              persistTableHead
              paginationDefaultPage={page}
              theme="solarizedd"
              progressComponent={<div className="datatable-loader__background" />}
            />
          )}
        </div>
      </div>
    </div>
  );
}
