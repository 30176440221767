import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRefresh, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { WalletAddressesModal } from './WalletAddressesModal';
import { AdminAddressSchema } from '../../DataTableSchemas/AdminAddressSchema';
import { showAllNetworks } from '../../redux/network/networkActions';
import {
  exportAdminWalletsToFile,
  getAdminAddresses,
  importAdminWalletsFile,
} from '../../redux/adminAddress/adminAddressActions';
import { DownloadFile } from '../../components/DownloadFile';
import UploadFile from '../../components/UploadFile';

function AdminWallets() {
  const dispatch = useDispatch();
  
  const adminAddressesCount = useSelector((state) => state.adminAddress?.adminAddressesCount);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState('');

  const getFilter = () => {
    const savedFilter = localStorage.getItem('DataTable_psp/admin_wallets_columns');
    return savedFilter ? JSON.parse(savedFilter) : { network: '', currency: '' };
  };

  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );
  const adminAddresses = useSelector(
    (state) => state?.adminAddress?.adminAddresses,
  );

  async function refetchWallets(currentFilter = getFilter(), currentPage = page, currentLimit = rowsPerPage) {
    await dispatch(getAdminAddresses({
      network: currentFilter.network, currency: currentFilter.currency, page: currentPage, limit: currentLimit,
    }));
  }

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
    await refetchWallets(undefined, page, currentRowsPerPage);
  };

  const handlePageChange = async (pageValue) => {
    setPage(pageValue);
    await refetchWallets(undefined, pageValue, undefined);
  };

  useEffect(async () => {
    if (!adminAddresses) {
      setLoader(true);
      refetchWallets().then(() => setLoader(false));
      await dispatch(showAllNetworks());
    }
  }, [adminAddresses]);


  const canAddWallet = permissions && permissions.includes('add_admin_address');

  const editWith = (id) => {
    setSelectedAddressId(id);
    setShowEditModal(true);
  };
  
  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleExportAdminWallets = async (fileType) => {
    if (adminAddresses.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await exportAdminWalletsToFile(fileType, getFilter());
    } else {
      toastError('There is nothing to download.');
    }
  };

  const handleCSVUpload = async (formData) => {
    await importAdminWalletsFile(formData);
    await refetchWallets();
  };

  const columns = AdminAddressSchema(editWith, refetchWallets);

  return loader ? (
    <FullPageTransparentLoader />
  ) : (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Admin Wallets</h3>
        <div className="d-flex flex-row gap-2">
          {canAddWallet && (
          <Link to="/add-admin-address" className="btn btn-default">
            <FontAwesomeIcon
              icon={faPlus}
              size="sm"
            />
            Add Wallet
          </Link>
          )}
          <UploadFile afterUpload={handleCSVUpload} />
          <DownloadFile handleExport={handleExportAdminWallets} />
          <button type="button" className="btn btn-default" onClick={() => refetchWallets()}>
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          <DataTable
            columns={columns}
            data={adminAddresses}
            fixedHeader
            persistTableHead
            defaultSortFieldId={1}
            theme="solarizedd"
            pagination
            paginationServer
            highlightOnHover
            paginationResetDefaultPage={false}
            paginationTotalRows={adminAddressesCount}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[5, 25, 50, 200, 500]}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
          />
          <WalletAddressesModal
            isShown={showEditModal}
            hideModal={() => setShowEditModal(false)}
            adminAddressId={selectedAddressId}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminWallets;
