import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone,
  faCircleInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import { RangeModalFilter } from '../../pages/Transactions/modalFilters';
import { ModalFilters } from '../../components';
import { dateOptions, omitColumn, setTransactionActionColumn } from '../helper';
import { getRateInUsdt } from '../../helpers/getRateInUsdt';

import styles from './styles.module.css';

export const PendingWithdrawsSchema = (
  amountRange,
  userIdFilter,
  setUserIdFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  setAmountRange,
  currencyFilter,
  setCurrencyFilter,
  currencies,
  crmUsers,
  assignedToFilter,
  setAssignedToFilter,
  setTransactionFilter,
  transactionFilter,
  statusFilter,
  setStatusFilter,
  handleInfoModalToggle,
  activeInfo,
  setActiveInfo,
  permissionName,
  resolveCurrentWithDrawTransaction,
  handleRevertTransaction,
  resolveCurrentBankWithDrawTransaction,
  resolveCurrentFiatWithDrawTransaction,
  updateIsReal,
  columnConfig,
  isRealFilter,
  setIsRealFilter,
  queryFilter,
  setQueryFilter,
  setTimeInitiatedFilter,
  handleDeleteTransaction,
  isDeleted,
  timeInitiatedFilter,
  setDebounceAmountRange,
  inputFieldError, 
  setInputFieldError,
) => {
  const dispatch = useDispatch();
  const currencyRates = useSelector((state) => state.currencyRates);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    if (nameField === 'transactionIdIsNotValid') setTransactionIdFilter(value);
    if (nameField === 'userIdIsNotValid') setUserIdFilter(value);
  };

  const isUserCanSeeClientCard = permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName.includes('leads');

  const columns = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Transaction ID
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Transaction ID"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            value={transactionIdFilter ?? ''}
            onChange={(e) => handleValidationInputs(e, 'transactionIdIsNotValid')}
          />
          <span className={styles.errorWrapper}>
            {inputFieldError.transactionIdIsNotValid && (
              <p className={styles.errorInput}>
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      cell: ({ _id, customId }) => (
        <CopyToClipboard text={customId ?? _id}>
          <span>
            {customId ?? `${_id?.slice(0, 4)}...${_id?.slice((_id.length || 0) - 4, _id.length)}`}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      omit: omitColumn(columnConfig, 'Transaction ID'),
      width: '200px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Client ID
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client ID"
            style={inputFieldError.userIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            value={userIdFilter ?? ''}
            onChange={(e) => handleValidationInputs(e, 'userIdIsNotValid')}
          />
          <span className={styles.errorWrapper}>
            {inputFieldError.userIdIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>),
      cell: ({ user, userId }) => (
        <CopyToClipboard text={user.customId ?? userId}>
          <span>
            {user.customId ?? `${userId?.slice(0, 4)}...${userId?.slice((userId.length || 0) - 4, userId.length)}`}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      omit: omitColumn(columnConfig, 'Client ID'),
      width: '200px',
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Amount" 
          value={amountRange ?? [0, 1000000]} 
          setValue={setAmountRange}
          filters={{}}
          setFilters={setDebounceAmountRange}
          filedName="amountRange"
          debounceAmountRange
        />
      ),
      minWidth: '160px',
      selector: ({ amount }) => amount,
      omit: omitColumn(columnConfig, 'Amount'),
    },
    {
      name: (
        <ModalFilters
          data={currencies}
          filters={currencyFilter}
          setFilters={setCurrencyFilter}
          tabName="Currency" 
          searchParams={{ id: 'symbol', params: ['symbol'], optionsName: ['symbol'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '200px',
      selector: ({ currency }) => currency,
      omit: omitColumn(columnConfig, 'Currency'),
    },
    {
      name: 'Client',
      selector: ({ user, userId }) => (
        (user.clientType === 1 && isUserCanSeeLeadsCard) || (user.clientType === 2 && isUserCanSeeClientCard)
          ? (
            <Link to={`/user-detail/${userId}`}>
              <span className="full-name-text">
                {`${user.firstName} ${user.lastName}`}
              </span>
            </Link>
          ) : (
            <span className="full-name-text">
              {`${user.firstName} ${user.lastName}`}
            </span>
          )
      ),
      omit: omitColumn(columnConfig, 'Client'),
    },
    {
      name: (
        <ModalFilters
          data={crmUsers}
          filters={assignedToFilter}
          setFilters={setAssignedToFilter}
          tabName="Manager"
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '160px',
      selector: ({ user }) => {
        if (
          Object.hasOwnProperty.call(user, 'assignedTo')
              && Object.keys(user?.assignedTo)?.length > 0
        ) {
          return (
            <Link
              to={`/edit-admin/${user?.assignedTo?._id}`}
              className="text-decoration-none"
            >
              {`${user?.assignedTo?.firstName} ${user?.assignedTo?.lastName}`}
            </Link>
          );
        }
        return '-';
      },
      omit: omitColumn(columnConfig, 'Manager'),
    },
    {
      name: 'In USD',
      cell: ({ currency, amount }) => { 
        const usdtValue = getRateInUsdt(currency, amount, currencyRates);
        return usdtValue ? usdtValue?.toFixed(2) : '-';
      },
      omit: omitColumn(columnConfig, 'In USD'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Crypto', _id: 'Crypto' }, { name: 'Card', _id: 'Card' }, { name: 'Bank', _id: 'Bank' }, { name: 'Admin Transaction', _id: 'Admin Transaction' }]}
          filters={transactionFilter}
          setFilters={setTransactionFilter}
          tabName="Transaction Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '230px',
      cell: ({ type }) => {
        if (type === 'Crypto') return 'Crypto';
        if (type === 'Card') return 'Card';
        if (type === 'Bank') return 'Bank';
        return 'Admin Withdrawal';
      },
      omit: omitColumn(columnConfig, 'Transaction Type'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
          filters={statusFilter}
          setFilters={setStatusFilter}
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      cell: ({
        _id, isResolved, toAddress, toCard, toIban, type, toAccountNumber, toSwiftCode, 
      }) => (
        <>
          {isResolved === 0 ? (
            <span className="badge rounded-pill bg-warning">Pending</span>
          ) : isResolved === 1 ? (
            <span className="badge rounded-pill bg-success">Completed</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Declined</span>
          )}
          <span>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleInfoModalToggle(_id);
              }}
            />

            {activeInfo === _id ? (
              <div className="withdrawsInfoModal">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  style={{
                    cursor: 'pointer', position: 'absolute', right: '10px', top: '10px',
                  }}
                  onClick={() => {
                    setActiveInfo(null);
                  }}
                />

                {toAddress ? (
                  <>
                    <h6>To Address</h6>
                    <p>{toAddress}</p>
                  </>
                ) : (
                  null
                )}
                {toCard ? (
                  <>
                    <h6>To Card</h6>
                    <p>{toCard}</p>
                  </>
                ) : (
                  null
                )}
                {toIban ? (
                  <>
                    <h6>To Iban</h6>
                    <p>{toIban}</p>

                    <h6>Account Number</h6>
                    <p>{toAccountNumber}</p>

                    <h6>Swift Code</h6>
                    <p>{toSwiftCode}</p>
                  </>
                ) : (
                  null
                )}
                {
                  type === 'Admin Transaction' ? (
                    <h6>Admin Transaction</h6>
                  ) : null
                }
              </div>
            ) : (
              null
            )}
          </span>
        </>
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: (
        <ModalFilters 
          data={dateOptions} 
          filters={timeInitiatedFilter} 
          setFilters={setTimeInitiatedFilter} 
          tabName="Time Initiated" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
          isDateOptions
        />
      ),
      selector: ({ createdAt }) => (createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss') : ''),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      minWidth: '230px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Real', _id: 'true' }, { name: 'Fake', _id: 'false' }]}
          filters={isRealFilter}
          setFilters={setIsRealFilter}
          tabName="Real/Fake" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Real/Fake'),
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),      
    },
    {
      name: 'Action(s)',
      minWidth: '300px',
      omit: omitColumn(columnConfig, 'Action'),
      cell: (row) => {
        let resolveMethod = resolveCurrentWithDrawTransaction;

        if (Object.hasOwnProperty.call(row, 'toIban')) {
          resolveMethod = resolveCurrentBankWithDrawTransaction;
        } else if (Object.hasOwnProperty.call(row, 'toCard')) {
          resolveMethod = resolveCurrentFiatWithDrawTransaction;
        }

        return setTransactionActionColumn(
          row, 
          permissionName,
          resolveMethod,
          updateIsReal,
          handleRevertTransaction,
          handleDeleteTransaction,
          isDeleted,
          dispatch,
        );
      }, 
    },
  ];

  return columns;
};

