export const ordersByUserHistoryColumns = [
  {
    name: 'Trading Type',
    field: 'tradingType',
    selected: true,
  },
  {
    name: 'Opened By',
    field: 'openedBy',
    selected: true,
  },
  {
    name: 'Trading Pair',
    field: 'tradingPair',
    selected: true,
  },
  {
    name: 'Direction',
    field: 'direction',
    selected: true,
  },
  {
    name: 'Swap',
    field: 'swap',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
  },
  {
    name: 'Leverage',
    field: 'leverage',
    selected: true,
  },
  {
    name: 'Order Qty',
    field: 'orderQty',
    selected: true,
  },
  {
    name: 'Open Price',
    field: 'openPrice',
    selected: true,
  },
  {
    name: 'Order Volume',
    field: 'orderVolume',
    selected: true,
  },
  {
    name: 'Close Price',
    field: 'closePrice',
    selected: true,
  },
  {
    name: 'Profit/Loss',
    field: 'profitLoss',
    selected: true,
  },
  {
    name: 'Start Time',
    field: 'startTime',
    selected: true,
  },
  {
    name: 'End Time',
    field: 'endTime',
    selected: true,
  },
  {
    name: 'Status',
    field: 'status',
    selected: true,
  },
  {
    name: 'Actions',
    selected: true,
  },
];
