import { toast } from 'react-toastify';
import { GET_BRANDS } from './brandTypes';
import { apiHelper } from '../apiHelper';

export const getBrands = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/brand/', '');

    if (res && res.data && res.data.brands) {
      dispatch({
        type: GET_BRANDS,
        payload: res.data.brands,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
